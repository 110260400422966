import { CustomErrorType, ErrorPageError } from '~/models/error'
import { computed, Ref, useRoute } from '~/utils/nuxt3-migration'
import { NUXT_DEFAULT_ERROR_MESSAGE } from '~/constants/error'
import { HttpStatus } from '~/constants/http'
import { useI18n } from '~/compositions/i18n'
import { Component } from 'vue'
import ClassifiedViewGoneError from '~/components/shared/errors/ClassifiedViewGoneError.vue'
import AnalyticsNoDataError from '~/components/shared/errors/AnalyticsNoDataError.vue'
import DefaultCarError from '~/components/car/errors/DefaultError.vue'
import AdvertisingError from '~/components/shared/errors/AdvertisingError.vue'

export function useErrorPage(layoutError: Ref<ErrorPageError>) {
  const { t } = useI18n()
  const route = useRoute()

  function normalizeErrorMessage(
    message: string,
    statusCode: HttpStatus
  ): string {
    if (message === NUXT_DEFAULT_ERROR_MESSAGE) {
      return t('page not found').toString()
    }
    const defaultErrorMessage = `${t('something went wrong')}. ${t(
      'please try again later'
    )}.`
    // hack to bypass the normal BAD nuxt behavior of defaulting to stringified status object as error message
    if (message.includes('\n') || !message) {
      switch (statusCode) {
        case HttpStatus.NOT_FOUND: {
          return t('page not found').toString()
        }
        case HttpStatus.FORBIDDEN: {
          return t(
            'you do not have the permission to view this page'
          ).toString()
        }
        case HttpStatus.TOO_MANY_REQUESTS: {
          return `${t(
            'you performed too many requests in a given amount of time'
          )}. ${t('please try again later')}`
        }
        case HttpStatus.UNAUTHORIZED: {
          return t(
            'not authorized, you can not access this functionality'
          ).toString()
        }
        default: {
          return defaultErrorMessage
        }
      }
    }
    return message
  }
  const processedError = computed(() => {
    const { message, statusCode } = layoutError.value
    return {
      ...layoutError.value,
      message: normalizeErrorMessage(message, statusCode)
    }
  })

  const errorComponent: Ref<Component> = computed(() => {
    if (route.value.name?.startsWith('__advertising')) {
      return AdvertisingError
    }
    const { type } = processedError.value
    switch (type) {
      case CustomErrorType.CLASSIFIED_VIEW_GONE: {
        return ClassifiedViewGoneError
      }
      case CustomErrorType.ANALYTICS_NO_DATA_ERROR: {
        return AnalyticsNoDataError
      }
      default: {
        return DefaultCarError
      }
    }
  })

  return { processedError, errorComponent }
}
