import { containerScoped } from '~/decorators/dependency-container'
import { inject } from 'tsyringe'
import { Location } from 'vue-router'
import { VueRouter } from '~/utils/nuxt3-migration'
import { vueRouterToken } from '~/constants/dependency-injection/tokens'

type QueryParams = Location['query']

@containerScoped()
export default class RouterService {
  constructor(@inject(vueRouterToken) private router: VueRouter) {}
  public updateQueryParams(queryParams: QueryParams): Promise<any> {
    const newRoute = this.getNewRoute(queryParams)
    if (this.newRouteAndCurrentRouteDiffer(newRoute)) {
      return this.router.push(newRoute.location)
    }
    return Promise.resolve()
  }

  replaceQueryParams(queryParams: QueryParams): Promise<any> {
    const newRoute = this.getNewRoute(queryParams)
    if (this.newRouteAndCurrentRouteDiffer(newRoute)) {
      return this.router.replace(newRoute.location)
    }
    return Promise.resolve()
  }

  private getNewRoute(queryParams: QueryParams): any {
    return this.router.resolve({
      query: this.mergeParamsWithRouteQuery(queryParams)
    })
  }

  private newRouteAndCurrentRouteDiffer(newRoute: any): boolean {
    return newRoute.href !== this.router.currentRoute.fullPath
  }

  private mergeParamsWithRouteQuery(
    queryParams: QueryParams
  ): Location['query'] {
    const mergedParams = {
      ...this.router.currentRoute.query,
      ...queryParams
    }
    return this.cleanQueryParams(mergedParams)
  }

  // removes null or false params
  private cleanQueryParams(queryParams: Record<string, any>): QueryParams {
    if (!queryParams) {
      return
    }
    for (const [name, value] of Object.entries(queryParams)) {
      if (value === null || value === false || value === '') {
        delete queryParams[name]
      }
    }
    return queryParams
  }
}
