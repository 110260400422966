export const FIELD_FOURTH_WIDTH_COLUMNS = {
  xl: 3,
  lg: 3,
  md: 3,
  sm: 12,
  cols: 12
}
export const FIELD_FIVECOL_WIDTH_COLUMNS = {
  xl: 5,
  lg: 5,
  md: 5,
  sm: 12,
  cols: 12
}

export const FIELD_THIRD_WIDTH_COLUMNS = {
  xl: 4,
  lg: 4,
  md: 4,
  sm: 12,
  cols: 12
}

export const FIELD_THIRD_DESKTOP_FULL_TABLET = {
  xl: 4,
  lg: 4,
  md: 12,
  sm: 12,
  cols: 12
}

export const FIELD_THIRD_DESKTOP_HALF_TABLET_MOBILE = {
  xl: 4,
  lg: 4,
  md: 6,
  sm: 6,
  cols: 6
}
export const FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE = {
  xl: 3,
  lg: 3,
  md: 6,
  sm: 12,
  cols: 12
}
export const FIELD_FOURTH_DESKTOP_HALF_TABLET = {
  xl: 3,
  lg: 3,
  md: 6,
  sm: 6,
  cols: 6
}
export const FIELD_FOURTH_DESKTOP_FULL_TABLET = {
  xl: 3,
  lg: 3,
  md: 6,
  sm: 6,
  cols: 6
}
export const FIELD_THIRD_DESKTOP_HALF_TABLET_FULL_MOBILE = {
  xl: 4,
  lg: 4,
  md: 6,
  sm: 12,
  cols: 12
}
export const FIELD_TWO_THIRDS_WIDTH_COLUMNS = {
  xl: 8,
  lg: 8,
  md: 8,
  sm: 12,
  cols: 12
}

export const FIELD_HALF_WIDTH_COLUMNS = {
  xl: 6,
  lg: 6,
  md: 6,
  sm: 12,
  cols: 12
}
export const FIELD_COLUMNS_FULL_DESKTOP_HALF_MOBILE = {
  xl: 12,
  lg: 12,
  md: 12,
  sm: 6,
  cols: 6
}
export const FIELD_COLUMNS_FULL_DESKTOP_HALF_TABLET = {
  xl: 12,
  lg: 12,
  md: 6,
  sm: 6,
  cols: 6
}

export const FIELD_COLUMNS_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE = {
  xl: 3,
  lg: 3,
  md: 3,
  sm: 6,
  cols: 12
}
export const FIELD_COLUMNS_FOURTH_DESKTOP_FULL_TABLET = {
  xl: 3,
  lg: 3,
  md: 12,
  sm: 12,
  cols: 12
}

export const FIELD_THREE_FOURTH_DESKTOP_FULL_TABLET = {
  xl: 9,
  lg: 9,
  md: 12,
  sm: 12,
  cols: 12
}
export const FIELD_TWO_THIRDS_DESKTOP_FULL_TABLET = {
  xl: 8,
  lg: 8,
  md: 12,
  sm: 12,
  cols: 12
}
export const FIELD_COLUMNS_HALF_DESKTOP_FULL_TABLET = {
  xl: 6,
  lg: 6,
  md: 12,
  sm: 12,
  cols: 12
}
export const FIELD_COLUMNS_THIRD_DESKTOP_HALF_MOBILE = {
  xl: 4,
  lg: 4,
  md: 4,
  sm: 6,
  cols: 6
}
export const FIELD_FULL_WIDTH_COLUMNS = {
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  cols: 12
}
