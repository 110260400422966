













import { addButton, loadLeaflet } from './leaflet'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { Geolocation } from '~/models/common/types'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  props: {
    buttons: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    zoom: {
      type: [Number, String],
      required: false,
      default: 6
    },
    scrollWheelZoomEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    zoomControls: {
      type: Boolean,
      default: true
    },
    tap: {
      type: Boolean,
      required: false,
      default: false
    },
    draggingEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    height: {
      type: Number,
      required: false,
      default: 500
    },
    radius: {
      type: Number,
      required: false,
      default: 500
    },
    marker: {
      type: Object as PropType<Geolocation>,
      required: true
    },
    markerIsRadius: {
      type: Boolean,
      default: false
    },
    loadWhenVisible: {
      type: Boolean,
      default: false
    },
    identifier: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      mapId: null,
      layerGroup: null,
      L: null,
      shouldLoad: !this.loadWhenVisible as boolean
    }
  },
  watch: {
    marker(val) {
      if (this.map) {
        this.map.setView([val.lat, val.lon], this.zoom)
        this.placeMarker()
      }
    },
    zoom(val) {
      if (this.map) {
        this.map.setView([this.marker.lat, this.marker.lon], val)
      }
    },
    radius() {
      if (this.map) {
        this.placeMarker()
      }
    }
  },
  async mounted() {
    this.generateRandomId()

    if (this.loadWhenVisible) {
      return
    }

    await this.initialize()
  },
  beforeDestroy() {
    this.map && this.map.remove()
  },
  methods: {
    generateRandomId() {
      this.mapId = `mapContainer-${uuidv4()}`
    },
    async initialize() {
      this.shouldLoad = true
      await this.$nextTick()
      this.L = await loadLeaflet()
      this.initializeMap()
      this.placeMarker()
      this.placeButtons()
    },
    async handleContainerVisible(visible: boolean) {
      if (this.shouldLoad || !visible) {
        return
      }

      await this.initialize()
    },
    invalidateSize() {
      this.map && this.map.invalidateSize()
    },
    placeButtons() {
      this.buttons.forEach(button => {
        addButton({ L: this.L, map: this.map }, button)
      })
    },
    initializeMap() {
      this.L.Control.include({
        _refocusOnMap: this.L.Util.falseFn
      })
      const tiles = this.L.tileLayer(
        'https://map.pkw.gr/osm_tiles/{z}/{x}/{y}.png',
        {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          minZoom: 5
        }
      )
      const map = this.L.map(this.mapId, {
        preferCanvas: true,
        attributionControl: false,
        layers: [tiles],
        scrollWheelZoom: this.scrollWheelZoomEnabled,
        dragging: this.draggingEnabled,
        tap: this.tap,
        minZoom: 5,
        zoomControl: this.zoomControls
      })
      const layerGroup = this.L.layerGroup()
      layerGroup.addTo(map)
      map.setView([this.marker.lat, this.marker.lon], this.zoom)
      map.whenReady(() => {
        this.map = map
        this.$nextTick(() => {
          this.invalidateSize()
        })
        this.layerGroup = layerGroup
      })
    },
    placeMarker() {
      const { marker, markerIsRadius } = this
      const { lat, lon } = marker

      this.layerGroup.clearLayers()

      if (markerIsRadius) {
        this.L.circle([marker.lat, marker.lon], {
          radius: this.radius
        }).addTo(this.layerGroup)
      } else {
        this.L.marker([lat, lon])
          .addTo(this.layerGroup)
          .on('click', () => {
            this.$emit('marker-clicked')
          })
      }
    }
  }
})
