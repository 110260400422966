import { containerScoped } from '~/decorators/dependency-container'
import { inject } from 'tsyringe'
import { StoreWithRootState } from '~/store/types'
import { FLASH_NS } from '~/store/modules/shared/flash/state'
import {
  SET_BACKEND_FLASH_MESSAGE,
  SET_CLIENT_FLASH_MESSAGE
} from '~/store/modules/shared/flash/mutation-types'
import CookiesService from '~/services/CookiesService'
import {
  BACKEND_FLASH_MESSAGE_COOKIE_NAME,
  CLIENT_FLASH_MESSAGE_COOKIE_NAME
} from '~/constants/shared'
import {
  redirectToken,
  storeToken
} from '~/constants/dependency-injection/tokens'
import { Redirect } from '~/models/global-dependencies'
import {
  ClientFlashMessageData,
  ClientFlashMessageType
} from '~/models/flash/types'
import LoggerService from '~/services/LoggerService'

@containerScoped()
export default class FlashService {
  constructor(
    @inject(storeToken) private store: StoreWithRootState,
    @inject(CookiesService) private cookies: CookiesService,
    @inject(redirectToken) private redirect: Redirect,
    @inject(LoggerService) private logger: LoggerService
  ) {}

  private setCarBackendFlash(cookieValue: string): void {
    const [message, variant] = cookieValue.split('||')
    this.store.commit(`${FLASH_NS}/${SET_BACKEND_FLASH_MESSAGE}`, {
      message: decodeURIComponent(message),
      variant
    })
  }

  private setPlotBackendFlash(cookieValue: string): void {
    try {
      const parsedCookie = JSON.parse(decodeURIComponent(cookieValue))
      const hasFlashes =
        parsedCookie && parsedCookie.flashes && parsedCookie.flashes.length > 0
      if (!hasFlashes) {
        return
      }
      // Currently, only the first plot flash cookie is parsed
      const [firstFlash] = parsedCookie.flashes
      const { message, type: variant } = firstFlash
      this.store.commit(`${FLASH_NS}/${SET_BACKEND_FLASH_MESSAGE}`, {
        message,
        variant
      })
    } catch (err) {
      this.cookies.delete(BACKEND_FLASH_MESSAGE_COOKIE_NAME)
    }
  }

  setBackendFlashFromCookie(): void {
    const backendFlashCookie = this.cookies.get(
      BACKEND_FLASH_MESSAGE_COOKIE_NAME
    )

    if (!backendFlashCookie) {
      return
    }

    this.setCarBackendFlash(backendFlashCookie)
  }

  setClientFlashFromCookie(): void {
    const clientFlashCookieValue = this.cookies.get(
      CLIENT_FLASH_MESSAGE_COOKIE_NAME
    )

    if (!clientFlashCookieValue) {
      return
    }

    if (typeof clientFlashCookieValue === 'object') {
      // TODO: remove this after all client flash cookies are updated
      this.cookies.delete(CLIENT_FLASH_MESSAGE_COOKIE_NAME)
      return
    }

    try {
      const { type, data } = JSON.parse(
        decodeURIComponent(clientFlashCookieValue)
      )

      this.store.commit(`${FLASH_NS}/${SET_CLIENT_FLASH_MESSAGE}`, {
        type,
        data
      })
    } catch (err) {
      err.message = `Failed to parse client flash cookie ${clientFlashCookieValue} - ${err.message}`
      this.logger.captureError(err)
      this.cookies.delete(CLIENT_FLASH_MESSAGE_COOKIE_NAME)
    }
  }

  redirectWithFlash(
    url: string,
    flashType: ClientFlashMessageType,
    flashData?: ClientFlashMessageData,
    forceReplace: boolean = true
  ) {
    this.setClientFlashCookie(flashType, flashData, forceReplace)
    this.redirect(url)
    setTimeout(() => this.setClientFlashFromCookie(), 300)
  }

  setClientFlashCookie(
    type: ClientFlashMessageType,
    data?: ClientFlashMessageData,
    forceReplace: boolean = true
  ) {
    const payload = JSON.stringify({ type, data })
    const cookieValue = process.client ? encodeURIComponent(payload) : payload

    this.cookies.set(
      CLIENT_FLASH_MESSAGE_COOKIE_NAME,
      cookieValue,
      undefined,
      forceReplace
    )
  }

  clearBackendCookie(): void {
    this.cookies.delete(BACKEND_FLASH_MESSAGE_COOKIE_NAME)
  }
}
