import { inject } from 'tsyringe'
import { VueI18n } from '~/utils/nuxt3-migration'
import { containerScoped } from '~/decorators/dependency-container'
import { TreeLevelNode } from '~/models/category/types'
import {
  CategoryMapping,
  InternalCategoryIdentifier,
  NaReasonNodeId,
  NaReason
} from '~/models/dealer/integration/category/mapping'
import { NamespacedStore } from '~/models/store/types'
import { DEALER_INTEGRATION_CATEGORIES_NS } from '~/store/modules/shared/dealers/integration/categories'
import { DealerIntegrationCategoriesState } from '~/store/modules/shared/dealers/integration/categories/state'
import { getNamespacedStore } from '~/utils/store'
import {
  storeToken,
  vueI18nToken
} from '~/constants/dependency-injection/tokens'
import { StoreWithRootState } from '~/store/types'

@containerScoped()
export class DealerCategoryMapViewService {
  private dealerIntegrationCategoriesStore: NamespacedStore<
    DealerIntegrationCategoriesState
  >

  constructor(
    @inject(storeToken) store: StoreWithRootState,
    @inject(vueI18nToken) private i18n: VueI18n
  ) {
    this.dealerIntegrationCategoriesStore = getNamespacedStore(
      store,
      DEALER_INTEGRATION_CATEGORIES_NS
    )
  }

  filterCategoryMappings(
    mappings: Map<string, CategoryMapping>,
    text: string
  ): Map<string, CategoryMapping> {
    if (!text) {
      return new Map(mappings)
    }

    const results = new Map<string, CategoryMapping>()
    const text_ = text.trim().toLowerCase()

    for (const mapping of mappings.values()) {
      if (this.getSearchTargets(mapping).some(t => t.includes(text_))) {
        results.set(mapping.id, mapping)
      }
    }
    return results
  }

  private getSearchTargets(mapping: CategoryMapping): string[] {
    const { internalIdentifiers, externalName, externalId } = mapping
    return [
      ...internalIdentifiers.map(i => i.name.toLowerCase()),
      ...internalIdentifiers.map(i => i.id.toString().toLowerCase()),
      ...((externalName && [externalName.toLowerCase()]) || []),
      ...((externalId && [externalId.toLowerCase()]) || [])
    ]
  }

  getInternalCategorySelectId(mappingId: string) {
    return `internal-category-select-${mappingId}`
  }

  getInternalCategoryLimit() {
    return 2
  }

  mappingReachedInternalCategoryLimit(mappingId: string): boolean {
    const mapping = this.getMapping(mappingId)
    if (!mapping) {
      return false
    }
    if (mapping.naReason === NaReason.CANNOT_BE_MAPPED) {
      return true
    }

    const transitInternalCategories =
      this.dealerIntegrationCategoriesStore.state.transitInternalCategories.get(
        mappingId
      ) || new Set()
    return (
      mapping.internalIdentifiers.length + transitInternalCategories.size >=
      this.getInternalCategoryLimit()
    )
  }

  getPresetInternalCategory(
    mappingId: string
  ): InternalCategoryIdentifier | undefined {
    const { state } = this.dealerIntegrationCategoriesStore
    const mapping = this.getMapping(mappingId)
    if (!mapping) {
      return undefined
    }
    const { internalIdentifiers } = mapping
    const [, lastExpandedCategory] = state.lastPickerSelectedCategory
    return internalIdentifiers.length
      ? internalIdentifiers[internalIdentifiers.length - 1]
      : {
          id: lastExpandedCategory?.id || 50,
          name:
            lastExpandedCategory?.name ||
            (this.i18n.t('small classifieds') as string),
          options: { fillAutocomplete: false }
        }
  }

  getCategoryIdentifierFromTreeNode(
    node: TreeLevelNode
  ): InternalCategoryIdentifier {
    return {
      id: node.id,
      name:
        node.id === NaReasonNodeId.CANNOT_BE_MAPPED
          ? (this.i18n.t('cannot be mapped::mapping') as string)
          : node.humanPath
    }
  }

  private getMapping(mappingId: string): CategoryMapping | undefined {
    return this.dealerIntegrationCategoriesStore
      .getters('mappings')
      .get(mappingId)
  }
}
