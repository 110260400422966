import { interopDefault } from '~/router/utils'

export default [
  // {
  //   name: '__user_rogue_classifieds',
  //   path: '/user/rogue-classifieds/',
  //   component: () =>
  //     interopDefault(
  //       import(
  //         '~/pages/shared/account/rogue-classifieds.vue' /* webpackChunkName: "pages-users-rogue-classifieds.vue" */
  //       )
  //     )
  // },
  {
    name: '__user_avatar_iframe',
    path: '/user/user-avatar-change/',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/car/users/user-avatar.vue' /* webpackChunkName: "pages-users-user-avatar.vue" */
        )
      )
  },
  {
    name: '__user_verify',
    path: '/user/verify/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/users/verify.vue' /* webpackChunkName: "pages-users-verify.vue" */
        )
      )
  },
  {
    name: '__user_verify_faq',
    path: '/user/verify/faq',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/information/faq/verify-user-faq.vue' /* webpackChunkName: "pages-information-faq-verify-user-faq.vue" */
        )
      )
  },
  {
    name: '__user_menu',
    path: '/user-menu/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/users/user-menu.vue' /* webpackChunkName: "pages-user-menu.vue" */
        )
      )
  }
]
