import { ActionTree } from 'vuex'
import { EditState } from './state'

import {
  SET_CATEGORY,
  SET_INFO,
  SET_OWNER,
  SET_REGISTER_REFERENCE_CODE
} from './mutation-types'
import { CLASSIFIED_FORM_NS } from '../form/state'
import {
  SET_ALL_CATEGORIES,
  SET_CATEGORY_ID,
  SET_CLASSIFIED_ID,
  SET_CONFIG,
  SET_EDIT_MODE,
  SET_FIELD_VALUES_DATA,
  SET_PAGE,
  SET_ROOT_CATEGORY_ID
} from '../form/mutation-types'
import { CategoryId } from '~/models/category/types'
import ClassifiedEditService from '~/services/classified/edit/ClassifiedEditService'
import { getFormConfig } from '~/utils/form'

export default {
  async loadEditInfo({ dispatch, commit }, classifiedId) {
    const { data, page } = await this.$dep(ClassifiedEditService).getEditInfo(
      classifiedId
    )
    const { category, classified, registerReferenceCode, allCategories } = data
    const { fieldValues, owner, info } = classified
    // TODO: maybe find a better way to get the root category id than do this, could be unstable
    const categories = category.categoryIds.filter(
      c => c !== CategoryId.CLASSIFIEDS
    )
    const rootCategoryId = categories[0]

    commit(`${CLASSIFIED_FORM_NS}/${SET_PAGE}`, page, { root: true })
    commit(`${CLASSIFIED_FORM_NS}/${SET_FIELD_VALUES_DATA}`, fieldValues, {
      root: true
    })
    commit(`${CLASSIFIED_FORM_NS}/${SET_ALL_CATEGORIES}`, allCategories, {
      root: true
    })

    commit(`${CLASSIFIED_FORM_NS}/${SET_EDIT_MODE}`, true, { root: true })
    commit(
      `${CLASSIFIED_FORM_NS}/${SET_CATEGORY_ID}`,
      category && category.id,
      { root: true }
    )
    commit(`${CLASSIFIED_FORM_NS}/${SET_CLASSIFIED_ID}`, classifiedId, {
      root: true
    })
    commit(`${CLASSIFIED_FORM_NS}/${SET_ROOT_CATEGORY_ID}`, rootCategoryId, {
      root: true
    })
    commit(
      `${CLASSIFIED_FORM_NS}/${SET_CONFIG}`,
      getFormConfig(rootCategoryId, categories),
      {
        root: true
      }
    )

    commit(SET_INFO, info)
    commit(SET_OWNER, owner)
    commit(SET_CATEGORY, category)
    commit(SET_REGISTER_REFERENCE_CODE, registerReferenceCode)

    await dispatch(`${CLASSIFIED_FORM_NS}/loadSchema`, null, { root: true })
  }
} as ActionTree<EditState, any>
