export const ONE_MINUTE_MS = 60 * 1000
export const FIVE_MINUTES_MS = 5 * 60 * 1000
export const FIVE_MINUTES_S = 5 * 60
export const TEN_MINUTES_MS = 10 * 60 * 1000
export const ONE_HOUR_MS = 60 * 60 * 1000
export const ONE_SECOND_MS = 1000
export const TEN_SECONDS_MS = 10 * 1000
export const FIVE_SECONDS_MS = 5 * 1000
export const THREE_SECONDS_MS = 3 * 1000
export const ONE_DAY = 24 * 60 * 60
export const THREE_DAYS = 3 * ONE_DAY
export const FIVE_DAYS = 5 * ONE_DAY
export const TEN_DAYS = 10 * ONE_DAY
export const THREE_MONTHS = 90 * ONE_DAY
export const ONE_YEAR = 365 * ONE_DAY
export const INMOBI_COOKIE_DURATION = ONE_DAY * 390
