import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__about_us',
    path: '/about-us/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/about-us.vue' /* webpackChunkName: "pages-about-us.vue" */
        )
      )
  }
]
