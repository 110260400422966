import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import {
  httpToken,
  vueI18nToken
} from '~/constants/dependency-injection/tokens'
import { VueI18n } from '~/utils/nuxt3-migration'
import qs from 'qs'
import { containerScoped } from '~/decorators/dependency-container'
import { invalidBodyError } from '~/services/errors'
import { toCamelCase } from '~/utils/object'
import LoggerService from '~/services/LoggerService'
import { Pagination } from '~/models/search/types'
import SnackbarService from '~/services/snackbar/SnackbarService'
import {
  NotificationTypeEnum,
  NotificationEvent,
  NotificationType
} from '~/models/notification-center/types'
import { defaultQsStringifyOptions } from '~/constants/http'
import { ActionResult } from '~/models/shared/result'
import RequestBuilder from '~/builders/http/RequestBuilder'

@containerScoped()
export default class NotificationCenterService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(SnackbarService) private snackbar: SnackbarService,
    @inject(vueI18nToken) private i18n: VueI18n,
    @inject(LoggerService) public logger: LoggerService,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  async fetchNotification(
    page: number,
    types: Array<NotificationTypeEnum>
  ): Promise<{
    notifications: { pagination: Pagination; rows: Array<NotificationEvent> }
    notificationTypes: Array<NotificationType>
  }> {
    const queryParams = qs.stringify(
      { pg: page, type: types, 'read-notifications': 1 },
      {
        addQueryPrefix: true,
        ...defaultQsStringifyOptions
      }
    )
    const response: AxiosResponse = await this.http.get(
      `/api/user/notifications/${queryParams}`
    )

    const body = toCamelCase(response.data)
    if (!body?.data?.notifications || !body?.data?.types) {
      throw invalidBodyError(body)
    }
    return {
      notifications: body.data.notifications,
      notificationTypes: body.data.types
    }
  }

  readNotifications(
    ids: Array<NotificationEvent['id']> | NotificationEvent['id']
  ): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', '/api/user/notifications/read/')
      .data({ notification_ids: ids })
      .validate(body => body)
      .send()
  }

  readAllNotifications(): Promise<ActionResult> {
    return this.requestBuilder
      .request('post', '/api/user/notifications/read/')
      .data({ all: true })
      .validate(body => body)
      .send()
  }

  handleError(error: AxiosError) {
    const response = error.response
    if (response) {
      switch (response.status) {
        case 400: {
          this.snackbar.error(response.data.error)
          break
        }
        default: {
          this.snackbar.error(response.data.error)
          this.logger.captureError(error)
          break
        }
      }
    } else {
      this.snackbar.error(
        this.i18n.t('an error occurred please try again later')
      )
      this.logger.captureError(error)
    }
  }
}
