


































































































import {
  defineComponent,
  toRefs,
  watch,
  PropType,
  ref,
  computed,
  vue3Model
} from '~/utils/nuxt3-migration'
import { useId } from '~/compositions/id'
import { useFormComponent } from '~/compositions/form-component'
import { SelectSize } from '~/models/app/select'

import { ciChevronDown } from '~/icons/source/regular/chevron-down'

export default defineComponent({
  model: vue3Model,
  props: {
    options: {
      type: Array,
      required: false,
      default: () => null
    },
    modelValue: {
      type: [String, Number, Array, Object, Boolean],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<SelectSize>,
      default: 'md'
    },
    inGroup: {
      type: Boolean,
      default: false
    },
    textField: {
      type: String,
      default: 'text'
    },
    valueField: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    labelClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    helperText: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasClearOption: {
      type: Boolean,
      default: false
    },
    clearOptionText: {
      type: String,
      default: '-'
    },
    forceEnable: {
      type: Boolean,
      default: false
    },
    roundnessClass: {
      type: String,
      required: false
    },
    selectClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    name: {
      type: String,
      required: false,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    },
    tabindex: {
      type: [String, Number],
      default: undefined,
      required: false
    }
  },
  setup(props: any, { emit, listeners }: any) {
    const {
      modelValue,
      options,
      multiple,
      size,
      valueField,
      id,
      labelClass,
      hasError,
      roundnessClass,
      success,
      warning,
      inGroup
    } = toRefs(props)

    const internalValue = ref(
      multiple.value ? modelValue.value || [] : modelValue.value
    )
    const selectElement = ref<HTMLSelectElement | null>(null)
    const { createRandomId } = useId()
    const {
      labelClasses,
      helperTextClasses,
      helperTextIcon,
      controlClasses
    } = useFormComponent(labelClass, hasError, success, warning, 'select')

    const internalId = id.value || createRandomId()

    watch(modelValue, () => (internalValue.value = modelValue.value))

    if (options?.value?.length && !modelValue.value) {
      const hasSelected = options.value.some(option => option.selected)
      if (hasSelected) {
        options.value.forEach(option => {
          if (option.selected) {
            internalValue.value = option[valueField.value]
          }
        })
      }
    }

    function getSelectedValue(el: HTMLSelectElement) {
      const selectedValue = Array.from(el.options || [])
        .filter(o => o.selected)
        .map(o => ('_value' in o ? (o as any)._value : o.value))
      return multiple.value ? selectedValue : selectedValue[0]
    }

    function handleChange(e: Event) {
      internalValue.value = getSelectedValue(e.target as HTMLSelectElement)
      emit('change', internalValue.value)
    }

    function handleInput(e: Event) {
      internalValue.value = getSelectedValue(e.target as HTMLSelectElement)
      emit('input', internalValue.value)
      emit('update:modelValue', internalValue.value)
    }

    const nothingSelected = computed(
      () =>
        internalValue.value === null ||
        internalValue.value === undefined ||
        (Array.isArray(internalValue.value) && internalValue.value.length === 0)
    )

    const textColorClass = computed(() =>
      nothingSelected.value ? 'tw-text-grey-600' : 'tw-text-grey-900'
    )

    const selectClasses = computed(() => {
      const classes = []

      if (multiple.value) {
        classes.push('tw-bg-none')
      }

      let roundnessToUse
      if (size.value === 'sm') {
        classes.push(
          `${
            inGroup.value
              ? 'tw-h-[30px] tw-min-h-[30px]'
              : 'tw-h-[32px] tw-min-h-[32px]'
          } tw-text-base`
        )
        roundnessToUse = 'tw-rounded-md'
      } else if (size.value === 'lg') {
        classes.push(
          `${
            inGroup.value
              ? 'tw-h-[46px] tw-min-h-[46px]'
              : 'tw-h-[48px] tw-min-h-[48px]'
          } tw-text-lg`
        )
        roundnessToUse = 'tw-rounded-xl'
      } else {
        classes.push(
          `${
            inGroup.value
              ? 'tw-h-[38px] tw-min-h-[38px]'
              : 'tw-h-[40px] tw-min-h-[40px]'
          } tw-text-base`
        )
        roundnessToUse = 'tw-rounded-lg'
      }

      if (roundnessClass.value) {
        roundnessToUse = roundnessClass.value
      }

      return [
        ...classes,
        roundnessToUse,
        textColorClass.value,
        controlClasses.value
      ]
    })

    const internalListeners = computed(() => {
      return {
        ...listeners,
        input: handleInput,
        change: handleChange
      }
    })

    const showPlaceholder = computed(() => {
      if (!props.placeholder && !props.hasClearOption) {
        return false
      }

      return multiple.value
        ? !internalValue.value?.length
        : internalValue.value === null || internalValue.value === undefined
    })

    return {
      handleChange,
      handleInput,
      internalValue,
      internalId,
      selectElement,
      labelClasses,
      helperTextClasses,
      selectClasses,
      internalListeners,
      showPlaceholder,
      nothingSelected,
      textColorClass,
      helperTextIcon,
      ciChevronDown
    }
  }
})
