









import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    discount: {
      type: Object,
      required: true
    }
  }
})
