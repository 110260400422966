










import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { TableRecordVariant } from '~/models/app/table'

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<TableRecordVariant>,
      default: null
    },
    wrapText: {
      type: Boolean,
      default: false
    },
    cols: { type: Number }
  }
})
