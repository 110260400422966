import { Context } from '@nuxt/types'
import qs from 'qs'
import { inject } from 'tsyringe'
import { Route } from 'vue-router/types/router'
import { containerScoped } from '~/decorators/dependency-container'
import { DealerSiteRouteName } from '~/constants/dealer-site-route-names'
import { defaultQsStringifyOptions } from '~/constants/http'
import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import { MetaItem } from '~/models/head/types'
import { CompactResult } from '~/models/shared/result'
import { ViewType } from '~/models/shared/types'
import ClassifiedSearchPageUrlService from '~/services/classified-search-page-url/ClassifiedSearchPageUrlService'
import DealerSiteClassifiedSearchPageUrlService from '~/services/classified-search-page-url/DealerSiteClassifiedSearchPageUrlService'
import { formatCompactPage } from '~/services/formatters'
import HeadService from '~/services/HeadService'
import SearchConfigService from '~/services/search/SearchConfigService'
import SearchFormatter from '~/services/search/SearchFormatter'
import {
  Facet,
  FacetsResult,
  ResultsResult,
  SellersResult
} from '~/models/search/types'
import SeoUrlService from '~/services/seo/url/SeoUrlService'
import {
  SET_CONFIG,
  SET_MAP_SEARCH_FLAG,
  SET_PARAMS,
  SET_VIEW_TYPE,
  SET_SEARCH_TYPE
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { preprocessRoute } from '~/store/modules/shared/classifieds/search/mutations'
import {
  CLASSIFIED_SEARCH_NS,
  SearchRoute
} from '~/store/modules/shared/classifieds/search/state'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { StoreWithRootState } from '~/store/types'
import { toCamelCase } from '~/utils/object'
import { executeSyncOnServerAsyncOnClient } from '~/utils/function'
import LoggerService from '~/services/LoggerService'
import { VueRouter } from '~/utils/nuxt3-migration'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { SearchConfig, SearchSettings } from '~/models/search/config'
import { directParamsMappings } from '~/constants/classified/search/param'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  storeToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class SearchService {
  constructor(
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(SearchConfigService)
    private searchConfigService: SearchConfigService,
    @inject(HeadService)
    private headService: HeadService,
    @inject(SeoUrlService)
    private seoUrlService: SeoUrlService,
    @inject(storeToken)
    private store: StoreWithRootState,
    @inject(ClassifiedSearchPageUrlService)
    private classifiedSearchPageUrlService: ClassifiedSearchPageUrlService,
    @inject(DealerSiteClassifiedSearchPageUrlService)
    private dealerSiteClassifiedSearchPageUrlService: DealerSiteClassifiedSearchPageUrlService,
    @inject(SearchFormatter) private searchFormatter: SearchFormatter,
    @inject(LoggerService) private logger: LoggerService,
    @inject(vueRouterToken) private router: VueRouter,
    @inject(DealerSiteService) private dealerSiteService: DealerSiteService
  ) {}

  public async createSearch(
    {
      route,
      mapSearch = false,
      errorFn = () => {},
      isQuickSearch = false,
      ignoreRouteCheck = false
    }: {
      route: Route | null
      errorFn?: Context['error']
      mapSearch?: boolean
      isQuickSearch?: boolean
      ignoreRouteCheck?: boolean
    } = {
      route: null,
      errorFn: () => {},
      mapSearch: false,
      ignoreRouteCheck: false
    }
  ) {
    if (isQuickSearch) {
      if (!route) {
        throw new Error('The route param is required.')
      }
      const { query } = route

      const config: SearchConfig = this.searchConfigService.getConfig(route)
      this.setDefaultSettings(config.settings)

      this.setViewType(config, query)
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_CONFIG}`, config)

      try {
        const params = route.query
        this.store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_PARAMS}`, params)
        this.store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_SEARCH_TYPE}`, true)
      } catch (error) {
        error.message = `Failed to get search parameters when creating a search page: ${error}`
        this.logger.captureError(error)
        errorFn({ statusCode: error.response?.data?.status })
      }
      const execute = config.settings.forceAwait
        ? (fn: Function) => fn()
        : executeSyncOnServerAsyncOnClient
      return execute(() => {
        const extraParams: Record<string, string> = {}
        const s = config.settings
        if (s && s.sellerWebsiteId) {
          extraParams.dsiteid = s.sellerWebsiteId
        }

        return this.store.dispatch(`${CLASSIFIED_SEARCH_NS}/search`, {
          extraParams,
          updateUrl: false,
          fromCreateSearch: true
        })
      })
    } else {
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_SEARCH_TYPE}`, false)

      if (!route) {
        throw new Error('The route param is required.')
      }
      const { fullPath, query, path } = route
      if (
        this.isComingFromClassifiedView(query, path) &&
        !mapSearch &&
        !ignoreRouteCheck
      ) {
        return
      }
      const config: SearchConfig = this.searchConfigService.getConfig(route)
      this.setDefaultSettings(config.settings)
      if (mapSearch) {
        this.store.commit(
          `${CLASSIFIED_SEARCH_NS}/${SET_MAP_SEARCH_FLAG}`,
          true
        )
      }
      this.setViewType(config, query)
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_CONFIG}`, config)
      try {
        const params = await this.getParams(fullPath, route)
        this.store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_PARAMS}`, params)
      } catch (error) {
        error.message = `Failed to get search parameters when creating a search page: ${error}`
        this.logger.captureError(error)
        errorFn({ statusCode: error.response?.data?.status })
      }
      const execute = config.settings.forceAwait
        ? (fn: Function) => fn()
        : executeSyncOnServerAsyncOnClient
      return execute(() => {
        const extraParams: Record<string, string> = {}
        const s = config.settings
        if (s && s.sellerWebsiteId) {
          extraParams.dsiteid = s.sellerWebsiteId
        }
        return this.store.dispatch(`${CLASSIFIED_SEARCH_NS}/search`, {
          extraParams,
          updateUrl: false,
          fromCreateSearch: true
        })
      })
    }
  }

  createRowsJsonld(rows: any[]) {
    // TODO: unused for now
    return {
      '@context': 'https://schema.org',
      '@type': 'SearchResultsPage',
      mainEntity: {
        '@type': 'ItemList',
        itemListElement: rows.map((row, index) => {
          const { seo_json_ld: seoJsonld } = row
          return {
            '@type': 'ListItem',
            position: index + 1,
            item: seoJsonld
          }
        })
      }
    }
  }

  async getParams(seoUrl: string, route?: Route) {
    const seoUrl_: string = this.normalizeSeoUrl(seoUrl, route)
    const { seo } = this.store.state.classifieds.search.config.settings
    if (!seo) {
      // NOTE: data does not include category key.
      if (seoUrl_.includes('?')) {
        return qs.parse(seoUrl_.slice(seoUrl_.lastIndexOf('?') + 1))
      }
      return {}
    }

    if (
      !seoUrl_ ||
      seoUrl_ === '/' ||
      // TODO: Get pattern from dsite path: ~/router/routes/dealers/site/path.ts
      /^\/dsite\/[a-zA-Z0-9-]+\/?$/.test(seoUrl_)
    ) {
      return {}
    }

    const { args }: any = await this.seoUrlService.parseUrl(seoUrl_)

    Object.keys(args).forEach(key => {
      const mapping = directParamsMappings[key]
      if (mapping) {
        delete Object.assign(args, {
          [mapping]: args[key]
        })[key]
      }
    })
    delete args.sp
    return args
  }

  private normalizeSeoUrl(seoUrl: string, route?: Route): string {
    if (route?.name === DealerSiteRouteName.PAGE && route.params.pagePath) {
      const getPageSnippetByPath = this.store.getters[
        `${DEALER_SITE_NS}/getPageSnippetByPath`
      ]
      const page: PageSnippet | undefined = getPageSnippetByPath(
        route.params.pagePath
      )
      if (page?.type === 'search_custom_page') {
        return '/?' + qs.stringify(page.searchArgs, defaultQsStringifyOptions)
      }
    }

    return seoUrl
  }

  private setDefaultSettings(settings: SearchSettings) {
    const s = settings

    const isNullish = (value?: any) =>
      typeof value === 'undefined' || value === null

    if (isNullish(s.seo)) {
      s.seo = true
    }

    if (isNullish(s.showAddress)) {
      s.showAddress = true
    }

    if (isNullish(s.showModificationDate)) {
      s.showModificationDate = true
    }

    if (!s.searchName) {
      s.searchName = ''
    }

    if (!s.modifier) {
      s.modifier = 'mobile-desktop'
    }
  }

  private setViewType(config: SearchConfig, query: any) {
    const store = this.store

    // if there is a vt arg in the url, set that viewType instead
    const viewTypeArg = query && (query.vt as ViewType)
    if (viewTypeArg) {
      if ([ViewType.GALLERY, ViewType.LIST].includes(viewTypeArg)) {
        store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_VIEW_TYPE}`, viewTypeArg)
        return
      }
    }
    const searchName = config && config.settings && config.settings.searchName
    const modifier =
      (config && config.settings && config.settings.modifier) ||
      'mobile-desktop'
    const getItem = store.getters[`${STORAGE_NS}/getItem`]
    const storageViewType = getItem(
      `search.${searchName}.${modifier}.view_type`
    )
    const storageViewTypeIsValid =
      storageViewType === ViewType.GALLERY || storageViewType === ViewType.LIST

    if (storageViewType && storageViewTypeIsValid) {
      store.commit(`${CLASSIFIED_SEARCH_NS}/${SET_VIEW_TYPE}`, storageViewType)
    } else {
      const configViewType = (config &&
        config.settings &&
        config.settings.viewType) || {
        defaultViewTypeDesktop: ViewType.GALLERY,
        defaultViewTypeMobile: ViewType.LIST
      }
      // @ts-ignore
      const defaultConfigViewType = this.store.state.userAgent.isPc
        ? configViewType.defaultViewTypeDesktop
        : configViewType.defaultViewTypeMobile
      store.commit(
        `${CLASSIFIED_SEARCH_NS}/${SET_VIEW_TYPE}`,
        defaultConfigViewType || ViewType.LIST
      )
    }
  }

  public createSearchHead(): any {
    const { store } = this
    const page = store.state.classifieds.search.page
    const currentRouteFullPath =
      store.getters[`${CLASSIFIED_SEARCH_NS}/getCurrentRouteFullPath`]
    const consent: any = store.state.page.consent
    const { title, metadata }: any = page
    // @ts-ignore TODO raw backend metadata compatible - page metadata type compatibility
    const meta: MetaItem[] = this.headService.formatBackendMeta(metadata)
    // Do not add og:url for dealer sites for now. TODO: Add later maybe.
    if (!this.dealerSiteService.routeIsOfDealerSite(this.router.currentRoute)) {
      meta.push(this.headService.getRouteOgUrlMetaTag(currentRouteFullPath))
    }
    return this.headService.createHead({
      title,
      meta,
      consent: consent && consent.name
    })
  }

  private isComingFromClassifiedView(query: any, path: string) {
    const currentVueRouteSearchRoute: SearchRoute = preprocessRoute({
      query,
      path
    })
    return (
      JSON.stringify(currentVueRouteSearchRoute) ===
      JSON.stringify(this.store.state.classifieds.search.currentRoute)
    )
  }

  getFacets(
    params: object,
    isQuickSearch: boolean = false
  ): Promise<CompactResult<FacetsResult>> {
    return this.requestBuilder
      .request('get', '/api/clsfds/search/')
      .params({
        ...params,
        ...{ 'only-facets': true, 'quick-search': isQuickSearch }
      })
      .validate(body => body?.data?.facets)
      .map(body => {
        return {
          data: this.searchFormatter.formatFacetsResponse(body.data),
          page: formatCompactPage(body._page, body?.data?.change_search_url)
        }
      })
      .previousRequestCancelable()
      .send()
  }

  getResults(params: object): Promise<ResultsResult> {
    // This request can not be cancelable, since it uses the same url as the facets
    return this.requestBuilder
      .request('get', '/api/clsfds/search/')
      .params({
        ...params,
        ...{ 'only-results': true }
      })
      .validate(body => body?.data?.results)
      .map(body => this.searchFormatter.formatResultsResponse(body.data))
      .send()
  }

  getSellers(params: object): Promise<SellersResult> {
    return this.requestBuilder
      .request('get', '/api/clsfds/search/')
      .params({
        ...params,
        ...{ 'only-sellers': true }
      })
      .validate(body => body?.data?.sellers)
      .map(body => this.searchFormatter.formatSellersResponse(body.data))
      .send()
  }

  getMap(params: object): Promise<SellersResult> {
    return this.requestBuilder
      .request('get', '/api/clsfds/search/')
      .params({
        ...params,
        ...{ 'only-map': true }
      })
      .validate(body => body?.data?.map)
      .map(body => toCamelCase(body.data))
      .send()
  }

  public getFacetComponentBindings(handler: Facet) {
    return {
      humanName: handler.humanName,
      isImportant: handler.isImportant,
      isOpen: handler.isOpen,
      isSelected: handler.isSelected,
      searchables: handler.searchables,
      humanParams: handler.values.humanParams,
      params: handler.values.params,
      selected: handler.values.selected,
      value: handler.values.value,
      strictValues: handler.strictValues,
      urlArgNames: handler.urlArgNames,
      name: handler.name,
      type: handler.type,
      isMultiselect: handler.multicheck,
      variant: handler.variant,
      allValues: handler.values,
      defaultCategory: handler.defaultCategory,
      searchPlaceholder: handler.searchPlaceholder,
      handler
    }
  }

  public getSearchPageUrlService() {
    const isDealerSiteSearchSetting = this.store.getters[
      `${CLASSIFIED_SEARCH_NS}/getSetting`
    ]('isDealerSiteSearch')
    if (isDealerSiteSearchSetting) {
      return this.dealerSiteClassifiedSearchPageUrlService
    }
    return this.classifiedSearchPageUrlService
  }
}
