





import { computed, defineComponent, PropType } from '~/utils/nuxt3-migration'
import { TableRecordVariant } from '~/models/app/table'

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<TableRecordVariant>,
      default: null
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const rowClasses = computed(() => {
      function variantClasses() {
        if (!props.variant) {
          return []
        }
        return [props.variant]
      }
      return [...variantClasses()]
    })

    const handleClick = () => {
      if (!props.clickable) return
      emit('click')
    }

    return { rowClasses, handleClick }
  }
})
