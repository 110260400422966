








































import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons'
import CJsonld from '~/components/shared/configurable/seo/CJsonld.vue'
import { Breadcrumb } from '~/models/common/types'
import {
  computed,
  defineComponent,
  PropType,
  toRefs,
  useRoute
} from '~/utils/nuxt3-migration'
import { getRouteMetaItem } from '~/utils/router'
import { useAnalytics } from '~/compositions/analytics'
import { useNamespacedStore } from '~/compositions/store'
import {
  USER_AGENT_NS,
  UserAgentState
} from '~/store/modules/shared/userAgent/state'

export default defineComponent({
  components: { CJsonld },
  props: {
    breadcrumbs: {
      type: Array as PropType<Breadcrumb[]>,
      default() {
        return []
      }
    },
    nuxtLinks: {
      type: Boolean,
      default: false
    },
    withoutHome: { type: Boolean, default: false }
  },
  setup(props) {
    const { breadcrumbs } = toRefs(props)

    const route = useRoute()
    const analytics = useAnalytics()
    const showBreadcrumbs = computed(
      () => !getRouteMetaItem(route.value, 'noBreadcrumbs')
    )

    const { getters: userAgentGetters } = useNamespacedStore<UserAgentState>(
      USER_AGENT_NS
    )
    const isMobile = computed(() => userAgentGetters('isMobile'))

    const jsonld = computed(() => ({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.value.map(
        (breadcrumb: Breadcrumb, index: number) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            name: breadcrumb.text,
            item: `https://car.gr${breadcrumb.url}`
          }
        }
      )
    }))

    const recordAnalyticsEvent = (path: string, text: string = '') => {
      if (!isMobile.value) {
        return
      }
      analytics.recordEvent({
        namespace: 'n_breadcrumb_click_mobile',
        action: 'click',
        label: text ? `${path} - ${text}` : path
      })
    }

    return {
      showBreadcrumbs,
      faChevronRight,
      faHome,
      jsonld,
      recordAnalyticsEvent
    }
  }
})
