import { ClientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { inject } from 'tsyringe'
import { StoreWithRootState } from '~/store/types'
import { noop } from '~/utils/function'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { getUnixTime } from 'date-fns'
import CookiesService from '~/services/CookiesService'
import { v4 as uuidV4 } from 'uuid'
import { storeToken } from '~/constants/dependency-injection/tokens'

@ClientOnly
@containerScoped()
export class FacebookPixelService {
  constructor(
    @inject(storeToken) private store: StoreWithRootState,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(CookiesService) private cookies: CookiesService
  ) {}

  initialize() {
    const ownFbPixelId = this.getOwnFbPixelIdFromEnvVar()
    if (!ownFbPixelId) {
      return
    }

    ;(function(f, b, e, v, n, t, s) {
      if (f.fbq) return
      // @ts-ignore
      n = f.fbq = function() {
        // @ts-ignore
        n.callMethod
          ? // @ts-ignore
            n.callMethod.apply(n, arguments)
          : // @ts-ignore
            n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      // @ts-ignore
      n.push = n
      // @ts-ignore
      n.loaded = !0
      // @ts-ignore
      n.version = '2.0'
      // @ts-ignore
      n.queue = []
      // @ts-ignore
      t = b.createElement(e)
      // @ts-ignore
      t.async = !0
      // @ts-ignore
      t.src = v
      // @ts-ignore
      s = b.getElementsByTagName(e)[0]
      // @ts-ignore
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )

    const fbq = this.getFbPixel()
    fbq('set', 'autoConfig', 'false', ownFbPixelId)

    const firstName = this.store.state.user.firstName
    const lastName = this.store.state.user.lastName
    const email = this.store.state.user.email
    const phone = this.store.state.user.telephone?.telephone

    fbq('init', ownFbPixelId, {
      em: email,
      fn: firstName,
      ln: lastName,
      ph: phone
    })
  }

  sendEvent(name: string, customData: Record<string, any> = {}) {
    const fbq = this.getFbPixel()

    const eventId = uuidV4()
    const eventTime = getUnixTime(new Date())
    const fbp = this.cookies.get('_fbp')
    const fbc = this.cookies.get('_fbc')

    fbq('track', name, customData, { eventID: eventId })

    this.requestBuilder
      .request('POST', `/api/meta/pixel/event/`)
      .data({
        events: [
          {
            event_id: eventId,
            event_name: name,
            event_time: eventTime,
            user_data: {
              fbp,
              fbc
            },
            custom_data: customData,
            event_source_url: window.location.href
          }
        ]
      })
      .send()
      .catch(noop)
  }

  private getFbPixel(): Function {
    return window.fbq || noop
  }

  private getOwnFbPixelIdFromEnvVar(): string | undefined {
    return process.env.FACEBOOK_PIXEL_ID
  }
}
