





import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {},
  setup() {
    return {}
  }
})
