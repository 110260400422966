













import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '~/utils/nuxt3-migration'
import { SpinnerVariant } from '~/models/app/spinner'

export enum CSpinnerType {
  GROW = 'grow',
  BORDER = 'border'
}

export default defineComponent({
  props: {
    type: {
      type: String as PropType<CSpinnerType>,
      default: CSpinnerType.BORDER
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'>,
      default: 'md'
    },
    sizeClass: {
      type: String,
      required: false
    },
    borderClass: {
      type: String,
      required: false
    },
    variant: {
      type: String as PropType<SpinnerVariant>,
      default: 'primary'
    }
  },
  setup(props) {
    const { variant, type, size, sizeClass, borderClass } = toRefs(props)

    const variantColor = computed(() => {
      switch (variant.value) {
        case 'primary':
        default: {
          return 'tw-text-primary'
        }
        case 'secondary': {
          return 'tw-text-blue-500'
        }
        case 'success': {
          return 'tw-text-green'
        }
        case 'danger': {
          return 'tw-text-red-400'
        }
        case 'warning': {
          return 'tw-text-amber'
        }
        case 'neutral': {
          return 'tw-text-grey-600'
        }
        case 'offer': {
          return 'tw-text-offer-green-500'
        }
        case 'parking': {
          return 'tw-text-blue-300'
        }
        case 'viber': {
          return 'tw-text-viber'
        }
        case 'whatsapp': {
          return 'tw-text-whatsapp'
        }
        case 'turbo': {
          return 'tw-text-turbo'
        }
        case 'super': {
          return 'tw-text-super'
        }
        case 'top': {
          return 'tw-text-super'
        }
        case 'facebook': {
          return 'tw-text-facebook'
        }
        case 'x': {
          return 'tw-text-x'
        }
        case 'tbi': {
          return 'tw-text-tbi'
        }
        case 'white': {
          return 'tw-text-white'
        }
        case 'light': {
          return 'tw-text-grey'
        }
      }
    })
    const internalSizeClass = computed(() => {
      if (sizeClass.value) {
        return sizeClass.value
      }

      switch (size.value) {
        case 'xs': {
          return 'tw-w-4 tw-h-4'
        }
        case 'sm': {
          return 'tw-w-6 tw-h-6'
        }
        case 'md': {
          return 'tw-w-8 tw-h-8'
        }
        case 'lg': {
          return 'tw-w-12 tw-h-12'
        }
        case 'xl': {
          return 'tw-w-14 tw-h-14'
        }
        case 'xxl': {
          return 'tw-w-20 tw-h-20'
        }
        default: {
          return 'tw-w-8 tw-h-8'
        }
      }
    })

    const bgColor = computed(() => {
      if (type.value === CSpinnerType.BORDER) {
        return 'tw-bg-transparent'
      }
      switch (variant.value) {
        case 'secondary': {
          return 'tw-bg-blue-500'
        }
        case 'success': {
          return 'tw-bg-green'
        }
        case 'warning': {
          return 'tw-bg-amber'
        }
        case 'danger': {
          return 'tw-bg-red-400'
        }
        case 'white': {
          return 'tw-bg-white'
        }
        case 'light': {
          return 'tw-bg-grey'
        }
        case 'primary':
        default: {
          return 'tw-bg-primary'
        }
      }
    })

    const typeClass = computed(() => {
      if (type.value === CSpinnerType.GROW) {
        return 'grow-spinner'
      }
      return 'border-spinner'
    })

    const internalBorderClass = computed(() => {
      if (borderClass.value) {
        return borderClass.value
      }
      if (type.value === CSpinnerType.BORDER) {
        return ['tw-border-4']
      }
      return []
    })

    return {
      variantColor,
      typeClass,
      bgColor,
      internalSizeClass,
      internalBorderClass
    }
  }
})
