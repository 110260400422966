










import { ciPauseCircle } from '~/icons/source/regular/pause-circle'
import { ciPlayCircle } from '~/icons/source/regular/play-circle'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    icons() {
      return {
        play: ciPlayCircle,
        pause: ciPauseCircle
      }
    }
  }
})
