import { interopDefault } from '~/router/utils'
import { getParkingRoutes } from '~/router/routes/shared/account/account-parking'

export default [
  {
    path: '/parking',
    name: '__parking',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/parking/index.vue' /* webpackChunkName: "pages-shared-account-classifieds-index.vue" */
        )
      ),
    meta: {
      ignoreScrollBehavior: true,
      showOneTap: true
    },
    redirect: { name: '__parking_classifieds_favorite' },
    children: getParkingRoutes()
  },
  {
    path: '/searches',
    name: '__searches',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/searches/index.vue' /* webpackChunkName: "pages-shared-account-searces-index.vue" */
        )
      ),
    meta: {
      ignoreScrollBehavior: true
    },
    redirect: { name: '__searches_favorite' },
    children: [
      {
        path: 'favorite/',
        name: `__searches_favorite`,
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/searches/favorite.vue' /* webpackChunkName: "pages-shared-account-searches-favorite.vue" */
            )
          ),
        meta: {
          ignoreScrollBehavior: true
        }
      },
      {
        path: 'recent-classifieds/',
        name: `__searches_recent_classifieds`,
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/searches/recent-classifieds.vue' /* webpackChunkName: "pages-shared-account-searches-recent-classifieds.vue" */
            )
          ),
        meta: {
          ignoreScrollBehavior: true
        }
      },

      {
        path: 'recent/',
        name: `__searches_recent`,
        component: () =>
          interopDefault(
            import(
              '~/pages/shared/account/searches/recent.vue' /* webpackChunkName: "pages-shared-account-searches-recent.vue" */
            )
          ),
        meta: {
          ignoreScrollBehavior: true
        }
      }
    ]
  },
  {
    name: '__parking_modal',
    path: '/parking-modal',
    meta: { isIframe: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/account/parking/modal-list.vue' /* webpackChunkName: "pages-shared-account-parking-modal-list.vue" */
        )
      )
  }
]
