import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken, storeToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { Pagination } from '~/models/search/types'
import { StoreWithRootState } from '~/store/types'
import { ClassifiedList } from '~/models/classified-list/types'
import { Classified } from '~/models/classified/types'
import { invalidBodyError } from './errors'
import { toCamelCase } from '~/utils/object'
import SearchFormatter from '~/services/search/SearchFormatter'

@containerScoped()
export default class ClassifiedListsService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(storeToken) private store: StoreWithRootState,
    @inject(SearchFormatter) private searchFormatter: SearchFormatter
  ) {}

  async addClassifiedToList(
    listId: number | string,
    classifiedId: number
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.put(
      `/api/classifieds/lists/${listId}/add/${classifiedId}/`
    )
    const body = response.data
    if (!body || !body.status || !body.message) {
      throw invalidBodyError(body)
    }
    return body
  }

  async fetchListClassifiedsById(
    listId: string | number,
    page?: number | string
  ): Promise<{
    classifieds: { pagination: Pagination; rows: Classified[] }
    list: ClassifiedList
  }> {
    const response: AxiosResponse = await this.http.get(
      `/api/classifieds/lists/${listId}/classifieds/`,
      { params: { pg: page } }
    )
    const body = response.data
    if (!body?.data?.classifieds || !body?.data?.list) {
      throw invalidBodyError(body)
    }
    body.data.list = toCamelCase(body.data.list)
    body.data.classifieds.pagination = toCamelCase(
      body.data.classifieds.pagination
    )
    let rows = body.data.classifieds.rows
    const { id } = this.store.state.user

    rows = body.data.classifieds.rows.map((r: Classified) => {
      if (
        (r.states.is_hidden || r.states.is_non_public || r.states.is_removed) &&
        r.user_id !== id
      ) {
        return {
          ...r,
          states: {
            is_non_public: false,
            is_hidden: false,
            is_removed: false,
            is_deleted: true
          }
        }
      }
      return r
    })
    body.data.classifieds.rows = this.searchFormatter.formatRows(rows)
    return body.data
  }

  async createList(
    listName: string,
    classifiedIds: Array<number> = []
  ): Promise<ClassifiedList> {
    const response: AxiosResponse = await this.http.post(
      '/api/classifieds/lists/',
      {
        name: listName,
        classifieds: classifiedIds
      }
    )
    const body = toCamelCase(response.data)
    if (!body?.data?.values) {
      throw invalidBodyError(body)
    }
    return body.data.values
  }

  async fetchAllLists(
    classifiedId: number | string = ''
  ): Promise<ClassifiedList[]> {
    const params = classifiedId ? { classified: classifiedId } : {}

    const response: AxiosResponse = await this.http.get(
      '/api/classifieds/lists/',
      { params }
    )
    const body = toCamelCase(response.data)
    if (!body?.data?.lists) {
      throw invalidBodyError(body)
    }
    return body.data.lists as ClassifiedList[]
  }

  async removeClassifiedFromList(
    listId: number | string,
    classifiedId: string
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/lists/${listId}/remove/${classifiedId}/`
    )
    const body = toCamelCase(response.data)
    if (!body?.message || !body?.status) {
      throw invalidBodyError(body)
    }
    return body
  }

  async editList(
    listId: number | string,
    name: string
  ): Promise<ClassifiedList> {
    const response: AxiosResponse = await this.http.put(
      `/api/classifieds/lists/${listId}/`,
      { name }
    )
    const body = toCamelCase(response.data)

    if (!body?.data?.values) {
      throw invalidBodyError(body)
    }
    return body.data.values
  }

  async deleteList(
    listId: number | string
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/lists/${listId}/`
    )
    const body = toCamelCase(response.data)
    if (!body?.status) {
      throw invalidBodyError(body)
    }
    return body
  }

  async copyList(
    listId: number | string,
    listName: string
  ): Promise<ClassifiedList> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/${listId}/copy/`,
      {
        name: listName
      }
    )
    const body = toCamelCase(response.data)
    if (!body?.data?.values) {
      throw invalidBodyError(body)
    }

    return body.data.values
  }

  async addToLists(
    listId: string | number
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.post(
      `/api/classifieds/lists/favorites/${listId}/`
    )
    const data = toCamelCase(response.data)
    if (!data || !data.message || !data.status) {
      throw invalidBodyError(data)
    }

    return data
  }

  async removeListFromFavorites(
    listId: string | number
  ): Promise<{ status: number; message: string }> {
    const response: AxiosResponse = await this.http.delete(
      `/api/classifieds/lists/favorites/${listId}/`
    )
    const data = toCamelCase(response.data)
    if (!data || !data.message || !data?.status) {
      throw invalidBodyError(data)
    }

    return data
  }
}
