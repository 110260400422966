import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import { getNamespacedStore } from '~/utils/store'
import BasePageSnippet from '../BasePageSnippet'

/**
 * Model for both search_page and search_custom_page since their behaviour is
 * the same.
 */
export default class SearchPageSnippet extends BasePageSnippet {
  async onPageLoad(store: any): Promise<void> {
    const dsStore = getNamespacedStore(store, DEALER_SITE_NS)
    await dsStore.dispatch('loadSearch')
  }
}
