import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import { ActionResult } from '~/models/shared/result'
import { StoreWithRootState } from '~/store/types'
import { PARKING_NS } from '~/store/modules/shared/parking/state'
import { removeKeysWithNoValues } from '~/utils/object'
import { ParkingClassifieds } from '~/models/classified/parking/types'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { storeToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
export class ClassifiedsFavoritesService {
  constructor(
    @inject(storeToken) private store: StoreWithRootState,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  clearNotificationForClassifieds(
    ids: number[],
    isParking: boolean = false
  ): Promise<ActionResult> {
    const params = removeKeysWithNoValues({
      parking: isParking ? 1 : null
    })
    return this.requestBuilder
      .request('post', '/api/user/notifications/read/')
      .params(params as { [key: string]: any })
      .data({ classified_id: ids })
      .action()
      .send()
  }

  getClassifiedFromId(id: number) {
    const parkedClassifieds = this.store.getters[
      `${PARKING_NS}/getClassifiedsList`
    ]
    return parkedClassifieds.find((clsfd: any) => clsfd.id === id)
  }

  getFavoriteClassifieds(
    category: number,
    sort: string
  ): Promise<ParkingClassifieds> {
    return this.requestBuilder
      .request('get', '/api/classifieds/favorites/')
      .params({ category, sort, per_page: 1000 })
      .validate(body => body.data?.classifieds)
      .map(body => ({ ...body.data, sortOptions: body.data.sort_options }))
      .send()
  }

  addClassifiedToFavorites(classified: number): Promise<ParkingClassifieds> {
    return this.requestBuilder
      .request('put', '/api/classifieds/favorites/')
      .data({ classified })
      .validate(body => body.data?.classifieds)
      .send()
  }

  removeClassifiedFromFavorites(
    classified: number
  ): Promise<{ classifieds: number[] }> {
    return this.requestBuilder
      .request('delete', '/api/classifieds/favorites/')
      .data({ classified })
      .validate(body => body.data?.classifieds)
      .send()
  }
}
