import { interopDefault } from '~/router/utils'
import { CategoryId } from '~/models/category/types'

const NewForm = () =>
  interopDefault(
    import(
      '~/pages/shared/classifieds/form/new.vue' /* webpackChunkName: "pages-classifieds-form-new" */
    )
  )

export default [
  {
    name: '__classifieds_jobs_new_form',
    path: '/jobs/new/:categoryId',
    component: NewForm,
    meta: { rootCategoryId: CategoryId.JOBS, showOneTapIfNoAnonModal: true }
  },
  {
    name: '__classifieds_jobs_new',
    path: '/jobs/new',
    redirect: {
      name: '__classifieds_jobs_new_form',
      params: { categoryId: CategoryId.JOBS }
    }
  },
  {
    name: '__classifieds_rentals_new_form',
    path: '/rentals/new/:categoryId',
    component: NewForm,
    meta: { rootCategoryId: CategoryId.RENTALS, showOneTapIfNoAnonModal: true }
  },
  {
    name: '__classifieds_rentals_new',
    path: '/rentals/new',
    redirect: {
      name: '__classifieds_rentals_new_form',
      params: { categoryId: CategoryId.RENTALS }
    }
  },
  {
    name: '__classifieds_plot_new_form',
    path: '/plot/new/:categoryId',
    component: NewForm,
    meta: { rootCategoryId: CategoryId.PLOT, showOneTapIfNoAnonModal: true }
  },
  {
    name: '__classifieds_plot_new',
    path: '/plot/new',
    redirect: {
      name: '__classifieds_plot_new_form',
      params: { categoryId: CategoryId.PLOT }
    }
  },
  {
    name: '__classifieds_new',
    path: '/classifieds/new',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/new/index.vue' /* webpackChunkName: "pages-classifieds-new" */
        )
      ),
    meta: {
      showOneTap: true
    }
  },
  {
    name: '__classifieds_new_partial',
    path: `/classifieds/:vtype(${['commercial', 'camper', 'hobby'].join(
      '|'
    )})/new`,
    component: () =>
      interopDefault(
        import(
          '~/pages/car/classifieds/new/index.vue' /* webpackChunkName: "pages-classifieds-new" */
        )
      )
  },
  {
    name: '__parts_new',
    path: '/parts/new',
    redirect: {
      name: '__classifieds_parts_new_form',
      params: { categoryId: CategoryId.PARTS }
    }
  },
  {
    name: '__classifieds_parts_new_form',
    path: '/parts/new/:categoryId',
    component: NewForm,
    meta: { rootCategoryId: CategoryId.PARTS, showOneTapIfNoAnonModal: true }
  },
  {
    name: '__xyma_new',
    path: '/xyma/new',
    redirect: {
      name: '__classifieds_xyma_new_form',
      params: { categoryId: CategoryId.XYMA }
    }
  },
  {
    name: '__classifieds_xyma_new_form',
    path: '/xyma/new/:categoryId',
    component: NewForm,
    meta: { rootCategoryId: CategoryId.XYMA, showOneTapIfNoAnonModal: true }
  },
  // Vehicles
  {
    name: '__classifieds_cars_new_form',
    path: '/classifieds/cars/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.CARS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_cars_new',
    path: '/classifieds/cars/new',
    redirect: {
      name: '__classifieds_cars_new_form',
      params: { categoryId: CategoryId.CARS }
    }
  },
  {
    name: '__classifieds_bikes_new_form',
    path: '/classifieds/bikes/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.BIKES,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_bikes_new',
    path: '/classifieds/bikes/new',
    redirect: {
      name: '__classifieds_bikes_new_form',
      params: { categoryId: CategoryId.BIKES }
    }
  },
  {
    name: '__classifieds_boats_new_form',
    path: '/classifieds/boats/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.BOATS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_boats_new',
    path: '/classifieds/boats/new',
    redirect: {
      name: '__classifieds_boats_new_form',
      params: {
        categoryId: CategoryId.BOATS,
        vehicleCategoryId: CategoryId.BOATS
      }
    }
  },
  {
    name: '__classifieds_bicycles_new_form',
    path: '/classifieds/bicycles/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.BICYCLES,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_bicycles_new',
    path: '/classifieds/bicycles/new',
    redirect: {
      name: '__classifieds_bicycles_new_form',
      params: { categoryId: CategoryId.BICYCLES }
    }
  },
  {
    name: '__classifieds_vans_new_form',
    path: '/classifieds/vans/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.VANS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_vans_new',
    path: '/classifieds/vans/new',
    redirect: {
      name: '__classifieds_vans_new_form',
      params: { categoryId: CategoryId.VANS }
    }
  },
  {
    name: '__classifieds_trucks_new_form',
    path: '/classifieds/trucks/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.TRUCKS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_trucks_new',
    path: '/classifieds/trucks/new',
    redirect: {
      name: '__classifieds_trucks_new_form',
      params: { categoryId: CategoryId.TRUCKS }
    }
  },
  {
    name: '__classifieds_buses_new_form',
    path: '/classifieds/buses/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.BUSES,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_buses_new',
    path: '/classifieds/buses/new',
    redirect: {
      name: '__classifieds_buses_new_form',
      params: { categoryId: CategoryId.BUSES }
    }
  },
  {
    name: '__classifieds_builders_new_form',
    path: '/classifieds/builders/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.BUILDERS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_builders_new',
    path: '/classifieds/builders/new',
    redirect: {
      name: '__classifieds_builders_new_form',
      params: { categoryId: CategoryId.BUILDERS }
    }
  },
  {
    name: '__classifieds_semitrucks_new_form',
    path: '/classifieds/semitrucks/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.SEMITRUCKS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_semitrucks_new',
    path: '/classifieds/semitrucks/new',
    redirect: {
      name: '__classifieds_semitrucks_new_form',
      params: { categoryId: CategoryId.SEMITRUCKS }
    }
  },
  {
    name: '__classifieds_semitrailers_new_form',
    path: '/classifieds/semitrailers/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.SEMITRAILERS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_semitrailers_new',
    path: '/classifieds/semitrailers/new',
    redirect: {
      name: '__classifieds_semitrailers_new_form',
      params: { categoryId: CategoryId.SEMITRAILERS }
    }
  },
  {
    name: '__classifieds_tractors_new_form',
    path: '/classifieds/tractors/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.TRACTORS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_tractors_new',
    path: '/classifieds/tractors/new',
    redirect: {
      name: '__classifieds_tractors_new_form',
      params: { categoryId: CategoryId.TRACTORS }
    }
  },
  {
    name: '__classifieds_forklifts_new_form',
    path: '/classifieds/forklifts/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.FORKLIFTS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_forklifts_new',
    path: '/classifieds/forklifts/new',
    redirect: {
      name: '__classifieds_forklifts_new_form',
      params: { categoryId: CategoryId.FORKLIFTS }
    }
  },
  {
    name: '__classifieds_trailers_new_form',
    path: '/classifieds/trailers/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.TRAILERS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_trailers_new',
    path: '/classifieds/trailers/new',
    redirect: {
      name: '__classifieds_trailers_new_form',
      params: { categoryId: CategoryId.TRAILERS }
    }
  },
  {
    name: '__classifieds_taxis_new_form',
    path: '/classifieds/taxis/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.TAXIS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_taxis_new',
    path: '/classifieds/taxis/new',
    redirect: {
      name: '__classifieds_taxis_new_form',
      params: { categoryId: CategoryId.TAXIS }
    }
  },
  {
    name: '__classifieds_caravans_new_form',
    path: '/classifieds/caravans/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.CARAVANS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_caravans_new',
    path: '/classifieds/caravans/new',
    redirect: {
      name: '__classifieds_caravans_new_form',
      params: { categoryId: CategoryId.CARAVANS }
    }
  },
  {
    name: '__classifieds_motorhomes_new_form',
    path: '/classifieds/motorhomes/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.MOTORHOMES,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_motorhomes_new',
    path: '/classifieds/motorhomes/new',
    redirect: {
      name: '__classifieds_motorhomes_new_form',
      params: { categoryId: CategoryId.MOTORHOMES }
    }
  },
  {
    name: '__classifieds_radiocontrols_new_form',
    path: '/classifieds/radiocontrols/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.RADIOCONTROLS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_radiocontrols_new',
    path: '/classifieds/radiocontrols/new',
    redirect: {
      name: '__classifieds_radiocontrols_new_form',
      params: { categoryId: CategoryId.RADIOCONTROLS }
    }
  },
  {
    name: '__classifieds_gokarts_new_form',
    path: '/classifieds/gokarts/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.GOKARTS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_gokarts_new',
    path: '/classifieds/gokarts/new',
    redirect: {
      name: '__classifieds_gokarts_new_form',
      params: { categoryId: CategoryId.GOKARTS }
    }
  },
  {
    name: '__classifieds_watersports_new_form',
    path: '/classifieds/watersports/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.WATERSPORTS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_watersports_new',
    path: '/classifieds/watersports/new',
    redirect: {
      name: '__classifieds_watersports_new_form',
      params: { categoryId: CategoryId.WATERSPORTS }
    }
  },
  {
    name: '__classifieds_snowsports_new_form',
    path: '/classifieds/snowsports/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.SNOWSPORTS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_snowsports_new',
    path: '/classifieds/snowsports/new',
    redirect: {
      name: '__classifieds_snowsports_new_form',
      params: { categoryId: CategoryId.SNOWSPORTS }
    }
  },
  {
    name: '__classifieds_airsports_new_form',
    path: '/classifieds/airsports/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.AIRSPORTS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_airsports_new',
    path: '/classifieds/airsports/new',
    redirect: {
      name: '__classifieds_airsports_new_form',
      params: { categoryId: CategoryId.AIRSPORTS }
    }
  },
  {
    name: '__classifieds_campers_new_form',
    path: '/classifieds/campers/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.CAMPERS,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_campers_new',
    path: '/classifieds/campers/new',
    redirect: {
      name: '__classifieds_campers_new_form',
      params: { categoryId: CategoryId.CAMPERS }
    }
  },
  {
    name: '__classifieds_hobby_new_form',
    path: '/classifieds/hobby/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.HOBBY,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_commercials_new_form',
    path: '/classifieds/commercials/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.VEHICLES,
      vehicleCategoryId: CategoryId.COMMERCIAL,
      showOneTapIfNoAnonModal: true
    }
  },
  {
    name: '__classifieds_commercials_new',
    path: '/classifieds/commercials/new',
    redirect: {
      name: '__classifieds_commercials_new_form',
      params: { categoryId: CategoryId.COMMERCIAL }
    }
  },
  {
    name: '__classifieds_wanted_parts_new_form',
    path: '/wanted-parts/new/:categoryId',
    component: NewForm,
    meta: {
      rootCategoryId: CategoryId.WANTED_PARTS,
      showOneTapIfNoAnonModal: true
    }
  }
]
