import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__short_url',
    path: '/s/:shortId/',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/short-url.vue' /* webpackChunkName: "pages-car-short-url" */
        )
      )
  }
]
