import { I18nLocale } from '~/models/shared/types'

export const allLocales = [
  I18nLocale.EL,
  I18nLocale.EN,
  I18nLocale.DE,
  I18nLocale.BG
]

export const carPublicLocales = [I18nLocale.EL, I18nLocale.EN]
