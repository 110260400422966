import {
  AlternativeSearch,
  HumanParam,
  Searchable,
  SellerClassifieds
} from '~/models/search/types'
import { Option } from '~/models/shared/types'
import { KeyValue } from '~/models/common/types'

interface Suggestion {
  id: string
  displayText: string
  displaySubtext?: string
}

export function mapBackendSearchableToSearchable(
  backendSearchable: any
): Searchable {
  return {
    selected: backendSearchable.s,
    value: backendSearchable.v,
    count: backendSearchable.c,
    name: backendSearchable.n,
    children: backendSearchable.k,
    isTop: backendSearchable.is_top
  }
}

export function mapSearchableToOption(searchable: Searchable): Option {
  return {
    text: searchable.name,
    value: searchable.value,
    count: searchable.count,
    isTop: searchable.isTop
  }
}
export function mapBackendSearchableToOption(backendSearchable: any): Option {
  return {
    text: backendSearchable.n,
    value: backendSearchable.v,
    count: backendSearchable.c
  }
}

export function mapBackendSuggestionToSuggestion(
  backendSuggestion: any
): Suggestion {
  return {
    id: backendSuggestion.value,
    displayText: backendSuggestion.name
  }
}
export function formatAdTargetingToKeyValue(adTargeting: any): KeyValue {
  return {
    key: adTargeting.name,
    value: adTargeting.value
  }
}
export function formatHumanParam(p: any): HumanParam {
  return {
    isUnselectable: p.is_unselectable,
    name: p.name,
    seoName: p.seo_name,
    urlArg: p.urlarg,
    value: p.value,
    selectedUrlArgValuePairs: p.selected_urlarg_value_pairs
  }
}
export function formatAlternativeSearch(a: any): AlternativeSearch {
  return {
    tags: a.tags,
    seoUrl: a.seo_url,
    label: a.label
  }
}
export function formatSellersClassifieds(c: any): SellerClassifieds {
  return {
    total: c.total,
    privateSellers: c.private_sellers && {
      count: c.private_sellers.count,
      seoUrl: c.private_sellers.seo_url
    }
  }
}
