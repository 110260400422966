import qs from 'qs'
import { FIVE_SECONDS_MS } from '~/constants/duration'
import { Param } from '~/models/common/types'
import { ViewType } from '~/models/shared/types'
import { Facet, MassActionsTypes } from '~/models/search/types'
import StatsService from '~/services/stats/StatsService'
import {
  SET_MASS_ACTIONS_RESET_TIMESTAMP,
  SET_VIEW_TYPE
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { SET_CONSENT } from '~/store/modules/shared/page/mutation-types'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import {
  ADD_SUBSCRIBABLE_SEARCH_ID,
  SET_COUNTS
} from '~/store/modules/shared/parking/searchSubscriptions/mutation-types'
import { SEARCH_SUBSCRIPTION_NS } from '~/store/modules/shared/parking/searchSubscriptions/state'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { ActionTreeWithRootState } from '~/store/types'
import { debounce } from '~/utils/function'
import {
  paramsValuesAsArrays,
  parseHttpError,
  preprocessParams,
  parseError,
  isCancelError
} from '~/utils/http'
import { facetChanged, mapChanged, viewTypeChanged } from './action-types'
import {
  CLEAR_ALL,
  SET_FACETS_DATA,
  SET_IS_FAVORITE,
  SET_LOADING_FACETS,
  SET_LOADING_RESULTS,
  SET_PAGE,
  SET_PAGE_DATA,
  SET_PARAM,
  SET_PARAMS,
  SET_RESULTS_DATA,
  SET_SELLERS_DATA,
  SET_SHOW_MOBILE_FACETS,
  SET_SHOW_SELLERS,
  SET_CURRENT_ROUTE,
  SET_PREVIOUS_ROUTE,
  SET_MAP_DATA,
  SET_LOADING_MAP,
  SET_CLUSTER_RESULTS_DATA,
  SET_LOADING_CLUSTER_RESULTS,
  SET_PAID_SUMMARY,
  SET_MASS_ACTIONS_REMAINING_ACTIONS,
  SET_MASS_ACTIONS_TOTAL_ACTIONS
} from './mutation-types'
import { NO_LOADING_FACETS, SearchState, SHOW_SELLERS_PARAM } from './state'
import { BACKGROUND_JOBS_NS } from '~/store/modules/shared/backgroundJobs/state'
import { ADD_BACKGROUND_JOB } from '~/store/modules/shared/backgroundJobs/mutation-types'
import { BackgroundJobActionTypes } from '~/models/background-jobs/types'
import SearchBucketService from '~/services/search-bucket/SearchBucketService'
import MassActionsService from '~/services/search/MassActionsService'
import PageViewRecorder from '~/services/telemetry/PageViewRecorder'
import SeoUrlService from '~/services/seo/url/SeoUrlService'
import PaidClassifiedService from '~/services/classified/paid/PaidClassifiedService'
import SearchService from '~/services/search/SearchService'
import AdmanAdsService from '~/services/ads/AdmanAdsService'
import ImagePreloadRegistrar from '~/services/preload/ImagePreloadRegistrar'
import {
  compatibilityParamsMappings,
  deprecatedLocationParams
} from '~/constants/classified/search/param'
import { Vue } from '~/utils/nuxt3-migration'
import { CategoryId } from '~/models/category/types'
import GoogleAdsService from '~/services/ads/GoogleAdsService'

const debouncedStatsRecord = debounce(function({
  allRowsIds,
  statsService
}: {
  allRowsIds: number[]
  statsService: StatsService
}) {
  statsService.record('events.clsfds.list_views', allRowsIds)
},
FIVE_SECONDS_MS)

export default {
  clearAll({ dispatch, state, commit }, clearFacets: boolean = false) {
    this.$cache.reset()
    commit(CLEAR_ALL, clearFacets)
    dispatch('search', { updateUrl: !state.isQuickSearch })
  },
  performPostFavoritesActions(
    { commit },
    { isFavorite }: { isFavorite: boolean }
  ) {
    this.$cache.reset()
    const event = document.createEvent('Event')
    event.initEvent('reload_recent', true, true)
    document.dispatchEvent(event)
    commit(SET_IS_FAVORITE, isFavorite)
  },
  async removeFromFavorites({ dispatch, state: { searchId }, rootGetters }) {
    if (!searchId) {
      this.$logger.captureError(new Error('No search id loaded'))
      return
    }

    const subscriptionExists =
      rootGetters[`${SEARCH_SUBSCRIPTION_NS}/subscriptionExists`]

    const message = await this.$dep(
      SearchBucketService
    ).removeSearchFromFavorites(searchId)
    message && this.$snackbar && this.$snackbar.success(message)
    subscriptionExists(searchId) &&
      (await dispatch(
        `${SEARCH_SUBSCRIPTION_NS}/deleteStateSubscription`,
        searchId,
        { root: true }
      ))
    dispatch('performPostFavoritesActions', { isFavorite: false })
  },
  async addToFavorites({ dispatch, state }, replaceSearch: boolean = false) {
    if (!state.searchId) {
      this.$logger.captureError(new Error('No search id loaded'))
      return
    }
    const previousSearchId = replaceSearch
      ? state?.previousSearch?.search
      : undefined

    const { message } = await this.$dep(
      SearchBucketService
    ).addSearchToFavorites(state.searchId, previousSearchId)
    message && this.$snackbar.success(message)
    dispatch('performPostFavoritesActions', { isFavorite: true })
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clearFacet(_context, { name }) {
    // proxy action, used only for subscriptions to facet clear
  },
  async [facetChanged]({ state, dispatch, commit }, { params, geolocation }) {
    params.forEach((param: Param) => {
      const mappedName = compatibilityParamsMappings[param.name]
      commit(SET_PARAM, {
        name: mappedName || param.name,
        value: param.value
      })
    })
    const shouldModifyLoading = !params.some(
      (param: Param) => param.value && NO_LOADING_FACETS.includes(param.name)
    )
    const { settings } = state.config
    const searchParams: {
      modifyLoading: boolean
      updateUrl: boolean
      extraParams?: object
    } = {
      modifyLoading: shouldModifyLoading,
      updateUrl: settings.updateUrl === false ? settings.updateUrl : true
    }
    if (geolocation) {
      searchParams.extraParams = { geolocation }
    }
    commit(SET_SHOW_SELLERS, false)
    if (!state.isQuickSearch) {
      await dispatch('changePage', { page: 1, scroll: false })
    }
    await dispatch('search', searchParams)
    const pageViewRecorderService = this.$dep(PageViewRecorder)
    pageViewRecorderService.recordPageViewForFacetChange(state.page!.seoUrl)
  },
  changePage({ commit }, { page, scroll = true }) {
    if (process.client && scroll) {
      window.scroll(0, 0)
    }
    commit(SET_PAGE, page)
  },
  recordListViewsStats(
    { state, getters },
    { debounced = true }: { debounced: boolean } = { debounced: true }
  ) {
    if (
      state.config.settings &&
      state.config.settings.stats &&
      state.config.settings.stats.record
    ) {
      const allRowsIds = getters.getAllRowsIds
      const statsService = this.$dep(StatsService)

      if (debounced) {
        debouncedStatsRecord({ allRowsIds, statsService })
      } else {
        statsService.record('events.clsfds.list_views', allRowsIds)
      }
    }
  },
  async searchForFacets(
    { state, commit, dispatch, rootGetters },
    {
      extraParams = {},
      modifyLoading = true,
      updateUrl = true,
      fromCreateSearch = false
    }: {
      extraParams: object
      updateUrl: boolean
      modifyLoading: boolean
      fromCreateSearch: boolean
    } = {
      extraParams: {},
      updateUrl: true,
      modifyLoading: true,
      fromCreateSearch: false
    }
  ) {
    try {
      // sets the search loading based on the loading param
      modifyLoading && commit(SET_LOADING_FACETS, true)
      // merges and cleans the parameters that are required for searching
      const facetsParams = {
        ...state.config.rootParams,
        ...state.params,
        ...extraParams,
        context: state.flags.isMapSearch
          ? 'map-search'
          : state.config?.rootParams?.context
      }
      if (!fromCreateSearch) {
        Object.entries(facetsParams).forEach(([key]) => {
          if (deprecatedLocationParams.includes(key)) {
            // @ts-ignore
            delete facetsParams[key]
          }
        })
      }
      const params = paramsValuesAsArrays(preprocessParams(facetsParams))
      const isMobile = rootGetters['userAgent/isMobile']
      const searchService = this.$dep(SearchService)
      const { data, page } = await searchService.getFacets(
        params,
        state.isQuickSearch
      )
      let facetData: Facet[] = data.facets
      // Hide facet location from map search on mobiles
      if (isMobile && state.flags.isMapSearch) {
        facetData = data.facets.map(f => {
          if (
            f.type === 'MapLocationSearchHandler' ||
            f.name === 'pickup_location'
          ) {
            return { ...f, visible: false }
          }

          return f
        })
        data.facets = facetData
      }
      commit(SET_FACETS_DATA, data)
      data.searchSubscription.subscribable &&
        commit(
          `${SEARCH_SUBSCRIPTION_NS}/${ADD_SUBSCRIBABLE_SEARCH_ID}`,
          data.searchId,
          { root: true }
        )
      data.searchSubscription.subscriptions &&
        commit(
          `${SEARCH_SUBSCRIPTION_NS}/${SET_COUNTS}`,
          data.searchSubscription.subscriptions,
          { root: true }
        )

      // load background jobs if mass actions are enabled
      data.massActions?.showMassActions &&
        !data.massActions?.locked &&
        (await dispatch(
          `${BACKGROUND_JOBS_NS}/fetchAllBackgroundJobs`,
          {},
          {
            root: true
          }
        ))

      commit(SET_PAGE_DATA, page)
      if (process.client) {
        const url = state.flags.isMapSearch ? state.mapSearchUrl : page?.seoUrl
        const searchService = this.$dep(SearchService)
        const searchPageUrlService = searchService.getSearchPageUrlService()

        const partsOrVehiclesInRootParams =
          !state.params?.root ||
          [CategoryId.VEHICLES, CategoryId.PARTS].find(c =>
            state.params?.root.find((r: any) => r?.toString() === c.toString())
          )

        if (state.isQuickSearch && partsOrVehiclesInRootParams) {
          await dispatch('replaceUrl', searchPageUrlService.getUrl(url || ''))
        }
        if (updateUrl) {
          await dispatch('updateUrl', searchPageUrlService.getUrl(url || ''))
        }

        dispatch('updateRoutes')
      } else if (process.server) {
        commit(SET_CURRENT_ROUTE, this.$router.currentRoute)
      }

      modifyLoading && commit(SET_LOADING_FACETS, false)
    } catch (error) {
      dispatch('handleError', { error })
    }
  },
  async loadSubscriptionOfSearchPage({
    state: { searchId, isFavorite },
    rootState: {
      parking: {
        searchSubscriptions: { subscribableSearchIds }
      }
    },
    dispatch
  }) {
    if (!searchId) {
      this.$logger.captureError(new TypeError('Empty search id'))
      return
    }

    if (!isFavorite || !subscribableSearchIds.has(searchId)) {
      return
    }
    const rootConfig = { root: true }
    const actions = [
      dispatch(
        `${SEARCH_SUBSCRIPTION_NS}/loadSubscription`,
        searchId,
        rootConfig
      )
    ]
    await Promise.all(actions)
  },
  async searchForClusterResults(
    { state, commit, dispatch },
    { geolocation, pg }
  ) {
    try {
      commit(SET_LOADING_CLUSTER_RESULTS, true)
      const resultsParams = Object.assign(
        {},
        {
          ...state.config.rootParams,
          ...state.params,
          geolocation,
          pg,

          context: 'map-search'
        }
      )

      const params = paramsValuesAsArrays(preprocessParams(resultsParams))
      const searchService = this.$dep(SearchService)
      const data = await searchService.getResults(params)
      commit(SET_LOADING_CLUSTER_RESULTS, false)

      commit(SET_CLUSTER_RESULTS_DATA, data)
    } catch (error) {
      dispatch('handleError', { error })
    }
  },

  async searchForResults(
    { state, commit, dispatch },
    {
      extraParams = {},
      modifyLoading = true
    }: { extraParams: object; modifyLoading: boolean } = {
      extraParams: {},
      modifyLoading: true
    }
  ) {
    try {
      modifyLoading && commit(SET_LOADING_RESULTS, true)
      const resultsParams = Object.assign(
        {},
        {
          ...state.config.rootParams,
          ...state.params,
          ...extraParams,
          ...{
            'per-page': state.perPage,
            context: state.flags.isMapSearch ? 'map-search' : null
          }
        }
      )

      const params = paramsValuesAsArrays(preprocessParams(resultsParams))
      const searchService = this.$dep(SearchService)
      const imagePreloadRegistrarService = this.$dep(ImagePreloadRegistrar)
      const firstTimeLoad = state.rows === null
      const data = await searchService.getResults(params)
      commit(`${PAGE_NS}/${SET_CONSENT}`, data.results.consent, { root: true })
      commit(SET_RESULTS_DATA, data)
      imagePreloadRegistrarService.registerSearchResultThumbnails(
        data.results.rows
      )

      modifyLoading && commit(SET_LOADING_RESULTS, false)
      if (!firstTimeLoad && process.client) {
        dispatch('recordListViewsStats')
        await Vue.nextTick()
        dispatch('refreshAds')
      }
    } catch (error) {
      dispatch('handleError', { error })
    }
  },
  async searchForSellers({ state, commit, dispatch }) {
    try {
      commit(SET_LOADING_RESULTS, true)
      const params = paramsValuesAsArrays(
        preprocessParams({
          ...state.config.rootParams,
          ...state.params
        })
      )
      const searchService = this.$dep(SearchService)
      const data = await searchService.getSellers(params)
      commit(SET_SELLERS_DATA, data)
      commit(SET_LOADING_RESULTS, false)
      if (process.client) {
        await Vue.nextTick()
        dispatch('refreshAds')
      }
    } catch (error) {
      dispatch('handleError', { error })
    }
  },
  refreshAds({ state }) {
    if (state.showMobileFacets) {
      return
    }
    const [googleAdsService, admanAdsService] = this.$deps(
      GoogleAdsService,
      AdmanAdsService
    )
    googleAdsService.refreshAllSlots()
    admanAdsService.refreshAllSlots()
  },
  async searchForMap(
    { state, commit, dispatch, rootGetters },
    { extraParams = {} }: { extraParams: object } = {
      extraParams: {}
    }
  ) {
    const isMobile = rootGetters['userAgent/isMobile']
    const clustersDensity = 1.05
    try {
      const params = paramsValuesAsArrays(
        preprocessParams({
          ...state.config.rootParams,
          ...state.params,
          ...extraParams,
          clusters_density: isMobile ? clustersDensity : null,
          context: 'map-search'
        })
      )
      const searchService = this.$dep(SearchService)
      commit(SET_LOADING_MAP, true)
      const data = await searchService.getMap(params)
      commit(SET_MAP_DATA, data)
      commit(SET_LOADING_MAP, false)
    } catch (error) {
      dispatch('handleError', { error })
    }
  },
  handleError(_, { error }) {
    if (!error || isCancelError(error)) {
      return
    }

    const { isAxiosError } = error

    const pageError = isAxiosError
      ? parseHttpError({ error })
      : parseError({ error })

    if (!isAxiosError) {
      this.$logger.captureError(error)
    }

    this.$error(pageError)
  },
  search(
    { state, dispatch, commit },
    {
      extraParams = {},
      modifyLoading = true,
      updateUrl = true,
      fromCreateSearch = false
    }: {
      extraParams: object
      modifyLoading: boolean
      updateUrl: boolean
      fromCreateSearch: boolean
    } = {
      extraParams: {},
      modifyLoading: true,
      updateUrl: true,
      fromCreateSearch: false
    }
  ) {
    const isFiltersPage = state.config?.settings?.isFiltersPage
    const isQuickSearch = state.isQuickSearch
    // Determines which action will fetch the results
    // TODO: this might not be very flexible, consider changing in the future
    const rowsAction =
      state.params[SHOW_SELLERS_PARAM] &&
      !state.config?.settings.disableShowSellers
        ? 'searchForSellers'
        : 'searchForResults'
    const actions = [
      dispatch('searchForFacets', {
        extraParams,
        modifyLoading,
        updateUrl,
        fromCreateSearch
      })
    ]
    if (!isQuickSearch && !isFiltersPage) {
      actions.push(
        dispatch(rowsAction, {
          extraParams,
          modifyLoading
        })
      )
    }

    if (state.flags.isMapSearch) {
      actions.push(
        dispatch('searchForMap', {
          extraParams
        })
      )
    }
    return Promise.all(actions).then(
      () => isFiltersPage && commit(SET_LOADING_RESULTS, false)
    )
  },
  updateUrl({ state }, url: string) {
    process.client &&
      window.history.pushState(
        { isInSearchPage: true, isQuickSearch: state.isQuickSearch },
        '',
        url
      )
  },
  replaceUrl({ state }, url: string) {
    process.client &&
      window.history.replaceState(
        { isInSearchPage: true, isQuickSearch: state.isQuickSearch },
        '',
        url
      )
  },
  updateRoutes({ state, commit }) {
    const path = window.location.pathname
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    commit(SET_PREVIOUS_ROUTE, state.currentRoute)
    commit(SET_CURRENT_ROUTE, {
      path,
      query
    })
  },
  async updateUrlAndRoutes({ dispatch }, url: string) {
    await dispatch('updateUrl', url)
    await dispatch('updateRoutes')
  },
  toggleMobileFacets({ state, commit, dispatch, rootGetters }) {
    const isMobile = rootGetters['userAgent/isMobile']
    if (state.showMobileFacets) {
      commit(SET_SHOW_MOBILE_FACETS, false)
      if (isMobile) {
        dispatch(`${PAGE_NS}/removeBodyClass`, 'modal-open', { root: true })
      }
    } else {
      commit(SET_SHOW_MOBILE_FACETS, true)
      if (isMobile) {
        dispatch(`${PAGE_NS}/addBodyClass`, 'modal-open', { root: true })
      }
    }
  },
  setLoadingMap({ commit }, status: boolean) {
    commit(SET_LOADING_MAP, status)
  },
  async loadSeoUrlSearch(
    { dispatch, commit },
    { seoUrl, updateUrl = true }: { seoUrl: string; updateUrl: boolean }
  ) {
    const seoUrlService = this.$dep(SeoUrlService)
    const { args } = await seoUrlService.parseUrl(seoUrl)
    commit(SET_PARAMS, args)
    dispatch('search', {
      updateUrl,
      pageView: true
    })
    if (process.client && typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  async loadPaidSummary({ commit }) {
    const paidClassifiedService = this.$dep(PaidClassifiedService)
    const summary = await paidClassifiedService.getSummary()

    if (!summary) {
      this.$logger.captureError(new Error('Unable to load paid summary'))
      return
    }
    commit(SET_PAID_SUMMARY, summary)
  },
  async [mapChanged](
    { dispatch, commit, getters, state, rootGetters },
    {
      geolocation,
      country,
      fetchClassifieds = true
    }: {
      geolocation: string
      country: string
      fetchClassifieds: boolean
    }
  ) {
    const { origin, pathname } = window.location

    const locationFacet = getters.getFacetByName('location')
    const postcodeFacet = getters.getFacetByName('postcode')
    const isMobile = rootGetters['userAgent/isMobile']

    const queryParams: any = {
      ...qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      }),
      geolocation,
      country
    }
    if (state.params?.pg) {
      commit(SET_PARAM, { name: 'pg', value: 1 })
    }
    if (locationFacet?.urlArgNames[0]) {
      const urlArgName = locationFacet.urlArgNames[0]
      const locationParam = compatibilityParamsMappings[urlArgName]
      commit(SET_PARAM, { name: locationParam, value: null })
      delete queryParams[urlArgName]
    }
    if (postcodeFacet && isMobile) {
      // postCodeParam posible args [distance, postcode, country_code]
      postcodeFacet.urlArgNames
        .concat('country_code')
        .forEach((arg: string) => {
          commit(SET_PARAM, { name: arg, value: null })
          delete queryParams[arg]
        })
    }
    commit(SET_PARAM, { name: 'geolocation', value: geolocation })
    delete queryParams.pg
    if (country) {
      commit(SET_PARAM, { name: 'country', value: country })
    } else {
      delete queryParams.country
    }
    if (fetchClassifieds) {
      commit(SET_LOADING_RESULTS, true)
    }
    await dispatch(
      'updateUrlAndRoutes',
      `${origin}${pathname}?${qs.stringify(queryParams, {
        indices: false,
        encode: false
      })}`
    )

    if (fetchClassifieds) {
      dispatch('search', {
        updateUrl: false,
        pageView: true
      })
    } else {
      dispatch('searchForMap')
    }
  },
  [viewTypeChanged](
    { dispatch, commit, state },
    viewType: { viewType: ViewType }
  ) {
    commit(SET_VIEW_TYPE, viewType)
    const { modifier, searchName } = state.config.settings
    const data = {
      search: {
        [searchName || 'no-search-name']: {
          [modifier as string]: { view_type: viewType }
        }
      }
    }
    dispatch(`${STORAGE_NS}/set`, data, { root: true })
  },
  async massAction(
    { commit, state: { searchId }, getters },
    {
      jobType,
      actionType,
      _options
    }: { jobType: string; actionType: string; _options: any }
  ) {
    if (!searchId) {
      this.$logger.captureError(new Error('No search id found'))
      return
    }
    const massActionsService = this.$dep(MassActionsService)

    let ids = []
    if (actionType === MassActionsTypes.PAGE) {
      ids = getters.getAllRowsIds
    }

    let jobResponse = null

    try {
      if (jobType === BackgroundJobActionTypes.MASS_TOUCH) {
        jobResponse = await massActionsService.massTouch(searchId, ids)
      } else if (
        jobType === BackgroundJobActionTypes.PRICE_CHANGE &&
        _options
      ) {
        jobResponse = await massActionsService.massPriceChange(
          searchId,
          _options,
          ids
        )
      } else if (jobType === BackgroundJobActionTypes.SHOW) {
        jobResponse = await massActionsService.massShow(searchId, ids)
      } else if (jobType === BackgroundJobActionTypes.HIDE) {
        jobResponse = await massActionsService.massHide(searchId, ids)
      } else if (jobType === BackgroundJobActionTypes.CUSTOM_PAGE) {
        jobResponse = await massActionsService.massCustomPage(
          searchId,
          _options,
          ids
        )
      }
    } catch (error) {
      if (error.response?.data?.error) {
        this.$snackbar.error(error.response.data.error)
      }
      this.$logger.captureError(error)
    }

    if (jobResponse) {
      const jobResult = jobResponse.data

      if (jobResult) {
        commit(`${BACKGROUND_JOBS_NS}/${ADD_BACKGROUND_JOB}`, jobResult.job, {
          root: true
        })
        commit(SET_MASS_ACTIONS_REMAINING_ACTIONS, jobResult.remainingActions)
        commit(SET_MASS_ACTIONS_TOTAL_ACTIONS, jobResult.totalActions)
        commit(SET_MASS_ACTIONS_RESET_TIMESTAMP, jobResult.resetTimestamp)
      }

      return jobResult
    }
    return null
  }
} as ActionTreeWithRootState<SearchState>
