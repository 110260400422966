import { Route } from 'vue-router'
import { getTimestamp, parseDate } from '~/utils/date'
import { SIMPLE_DATE_FORMAT_2 } from '~/constants/date'

export function getAdDashboardQueryFilters(
  currentRoute: Route,
  backendFriendly: boolean = false
) {
  const queries: any = {}

  if (currentRoute.query.from) {
    queries.from = backendFriendly
      ? getTimestamp(
          parseDate(currentRoute.query.from.toString(), SIMPLE_DATE_FORMAT_2)
        )
      : currentRoute.query.from
  }
  if (currentRoute.query.to) {
    queries.to = backendFriendly
      ? getTimestamp(
          parseDate(currentRoute.query.to.toString(), SIMPLE_DATE_FORMAT_2)
        )
      : currentRoute.query.to
  }
  if (currentRoute.query['q-campaign']) {
    queries['q-campaign'] = currentRoute.query['q-campaign']
  }
  if (currentRoute.query['q-ad']) {
    queries['q-ad'] = currentRoute.query['q-ad']
  }
  if (currentRoute.query['q-item']) {
    queries['q-item'] = currentRoute.query['q-item']
  }
  return queries
}
