import {
  ClientValidator,
  FieldErrors,
  Fieldset,
  FieldSuggestions,
  FieldValues,
  FormConfig,
  HistoryEntry,
  SchemaExtras,
  TopAlert
} from '~/models/classified/form'
import { v4 as uuid } from 'uuid'
import { CompactPage } from '~/models/common/types'
export const CLASSIFIED_FORM_NS = 'classifieds/form'

export interface FormState {
  config: FormConfig
  categoryId: number | null
  allCategories: any[]
  categoryIds: number[]
  rootCategoryId: number | null
  classifiedId?: number
  fieldsets: Fieldset[]
  title: string
  fieldErrors: FieldErrors
  fieldValues: FieldValues
  fieldSuggestions: FieldSuggestions
  genericFormFields: any
  loading: boolean
  schemaLoading: boolean
  editMode: boolean
  uniqueFormId: string
  pageKey: string
  page: CompactPage
  extras?: SchemaExtras
  showFloatingSaveButton: boolean
  topAlert: TopAlert
  isDispatched: boolean
  history: HistoryEntry[]
  historyActiveIndex: number
  clientValidators: ClientValidator[]
  invalidTriggered: boolean
  telephones: { telephone: string }[]
  requiresPayment: boolean
  termsAccepted: boolean
  warningConfirmationMessage: {
    title: string
    text: string
  }
  isWarningConfirmationVisible: boolean
}

function getDefaultState(): FormState {
  return {
    categoryId: null,
    categoryIds: [],
    allCategories: [],
    rootCategoryId: null,
    classifiedId: undefined,
    config: {
      settings: {
        fieldColumns: {}
      }
    },

    fieldsets: [],
    title: '',
    fieldErrors: {},
    fieldValues: {},
    fieldSuggestions: {},
    genericFormFields: [],
    loading: false,
    schemaLoading: false,
    editMode: false,
    uniqueFormId: uuid(),
    pageKey: '',
    page: {
      breadcrumbs: [],
      metadata: [],
      seoUrl: '',
      title: '',
      externalUrl: '',
      targetSeoUrl: ''
    },
    extras: undefined,
    showFloatingSaveButton: false,
    topAlert: {
      variant: 'info',
      message: ''
    },
    isDispatched: false,
    history: [],
    historyActiveIndex: -1,
    clientValidators: [],
    invalidTriggered: false,
    telephones: [],
    requiresPayment: false,
    termsAccepted: false,
    warningConfirmationMessage: {
      title: '',
      text: ''
    },
    isWarningConfirmationVisible: false
  }
}

export default getDefaultState
