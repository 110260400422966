





import { ErrorPageError } from '~/models/error'
import {
  defineComponent,
  PropType,
  toRefs,
  useMeta
} from '~/utils/nuxt3-migration'
import { useErrorPage } from '~/compositions/error-page'

export default defineComponent({
  props: {
    // NOTE: should not be used directly, use the processedError computed property instead.
    error: {
      type: Object as PropType<ErrorPageError>,
      default: () => {}
    }
  },
  layout: ({ route }) => {
    if (route.name?.startsWith('__advertising')) {
      return 'advertising-error-layout'
    }
    return 'error-layout'
  },
  setup(props) {
    const { error: layoutErrorProp } = toRefs(props)
    const { processedError, errorComponent } = useErrorPage(layoutErrorProp)

    useMeta({
      title: `${processedError.value.statusCode} - ${processedError.value.message}`
    })

    return { processedError, errorComponent }
  },
  head: {}
})
