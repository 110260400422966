import { Pagination } from '~/models/search/types'
import { Ad, AdItem, CampaignsRow } from '~/models/advertising/types'

export const ADVERTISE_DASHBOARD_NS = 'advertise/dashboard'
export const ADVERTISE_TERMS_ACCEPTED = 'inhterms'
export interface AdvertiseDashboardState {
  loading: boolean
  campaignRows: CampaignsRow[]
  campaignPagination: Pagination | null
  adRows: Ad[]
  adPagination: Pagination | null
  adItemRows: AdItem[]
  adItemPagination: Pagination | null
  balance?: number
}
function getDefaultState(): AdvertiseDashboardState {
  return {
    loading: false,
    campaignRows: [],
    campaignPagination: null,
    adRows: [],
    adPagination: null,
    adItemRows: [],
    adItemPagination: null,
    balance: undefined
  }
}

export default getDefaultState
