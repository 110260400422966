export const SET_LOADING = 'SET_LOADING'
export const UPDATE_AD = 'UPDATE_AD'
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const DELETE_AD = 'DELETE_AD'
export const DELETE_AD_ITEM = 'DELETE_AD_ITEM'
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN'
export const SET_CAMPAIGN_ROWS = 'SET_CAMPAIGN_ROWS'
export const SET_CAMPAIGN_PAGINATION = 'SET_CAMPAIGN_PAGINATION'
export const SET_AD_ROWS = 'SET_AD_ROWS'
export const SET_AD_PAGINATION = 'SET_AD_PAGINATION'

export const SET_AD_ITEM_ROWS = 'SET_AD_ITEM_ROWS'
export const SET_AD_ITEM_PAGINATION = 'SET_AD_ITEM_PAGINATION'
export const SET_BALANCE = 'SET_BALANCE'
