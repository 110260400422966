import { containerScoped } from '~/decorators/dependency-container'
import {
  INSTALLMENTS_DOWNPAYMENT_PARAM_NAME,
  INSTALLMENTS_NUM_OF_INSTALLMENTS_PARAM_NAME
} from '~/constants/finance/installments'
import {
  SET_INSTALLMENTS_DOWNPAYMENT,
  SET_INSTALLMENTS_NUMBER_OF_INSTALLMENTS
} from '~/store/modules/shared/classifieds/view/mutation-types'
import { inject } from 'tsyringe'
import { StoreWithRootState } from '~/store/types'
import { CLASSIFIED_VIEW_NS } from '~/store/modules/shared/classifieds/view/state'
import { ClassifiedViewFinance } from '~/models/classified/view/finance'
import { storeToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class InstallmentsService {
  constructor(@inject(storeToken) private store: StoreWithRootState) {}

  public setClassifiedViewInstallmentsDefaults(
    finance: ClassifiedViewFinance,
    queryParams: Record<string, any>
  ) {
    if (!finance?.options) {
      return
    }
    const {
      [INSTALLMENTS_DOWNPAYMENT_PARAM_NAME]: downpayment,
      [INSTALLMENTS_NUM_OF_INSTALLMENTS_PARAM_NAME]: numberOfInstallments
    } = queryParams

    const { options, price } = finance
    const { minDownpayment, maxInstallments } = options

    const getDefaultDownpayment = () => {
      const parsedDownpaymentParam = this.parseDownpayment(downpayment)
      if (
        parsedDownpaymentParam &&
        this.downpaymentIsValid(parsedDownpaymentParam, minDownpayment, price)
      ) {
        return parsedDownpaymentParam
      }
      return minDownpayment
    }

    const getDefaultNumberOfInstallments = () => {
      const parsedNumberOfInstallments = this.parseNumberOfInstallments(
        numberOfInstallments
      )
      if (
        parsedNumberOfInstallments &&
        this.numberOfInstallmentsIsValid(numberOfInstallments, maxInstallments)
      ) {
        return parsedNumberOfInstallments
      }
      return maxInstallments
    }

    const defaultDownpayment = getDefaultDownpayment()

    this.store.commit(
      `${CLASSIFIED_VIEW_NS}/${SET_INSTALLMENTS_DOWNPAYMENT}`,
      defaultDownpayment
    )
    this.store.commit(
      `${CLASSIFIED_VIEW_NS}/${SET_INSTALLMENTS_NUMBER_OF_INSTALLMENTS}`,
      getDefaultNumberOfInstallments()
    )
  }

  public downpaymentIsValid(
    downpayment: number | null,
    minDownpayment: number,
    price: number
  ) {
    if (downpayment === undefined || downpayment === null) {
      return
    }
    return downpayment >= minDownpayment && downpayment <= price
  }

  public numberOfInstallmentsIsValid(
    numberOfInstallments: number,
    maxNumberOfInstallments: number
  ) {
    return numberOfInstallments <= maxNumberOfInstallments
  }

  public calculateMonthlyInstallmentAmount(
    price: any,
    downpayment: any,
    numberOfInstallments: any,
    interestPercentage: any
  ) {
    const amountAfterDownpayment = price - downpayment
    if (interestPercentage) {
      const interest = interestPercentage / 100 / 12
      const appliedInterest = (1 + interest) ** numberOfInstallments
      const monthlyAmount =
        amountAfterDownpayment * (interest / (appliedInterest - 1) + interest)
      return monthlyAmount.toFixed(2)
    } else {
      return (amountAfterDownpayment / numberOfInstallments).toFixed(2)
    }
  }

  public parseNumberOfInstallments(numberOfInstallments: string) {
    if (!numberOfInstallments) {
      return
    }
    return parseInt(numberOfInstallments, 10)
  }

  public parseDownpayment(downpayment: string) {
    if (!downpayment) {
      return
    }
    return parseFloat(downpayment)
  }
}
