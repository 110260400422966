import { useNamespacedStore } from '~/compositions/store'
import {
  USER_AGENT_NS,
  UserAgentState
} from '~/store/modules/shared/userAgent/state'
import { computed } from '~/utils/nuxt3-migration'

export function useUserAgent() {
  const { getters: userAgentGetters } = useNamespacedStore<UserAgentState>(
    USER_AGENT_NS
  )

  const isPc = computed(() => userAgentGetters('isPc'))
  const isMobile = computed(() => userAgentGetters('isMobile'))
  const isTablet = computed(() => userAgentGetters('isTablet'))

  return { isPc, isMobile, isTablet }
}
