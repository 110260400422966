import { Option } from '~/models/shared/types'
import { FormConfig } from '~/models/classified/form'
import { CategoryId } from '~/models/category/types'
import {
  AIRSPORTS_FORM_CONFIG_EXTENSION,
  BICYCLES_FORM_CONFIG_EXTENSION,
  BIKES_FORM_CONFIG_EXTENSION,
  BOATS_FORM_CONFIG_EXTENSION,
  BUILDERS_FORM_CONFIG_EXTENSION,
  BUSES_FORM_CONFIG_EXTENSION,
  CARAVANS_FORM_CONFIG_EXTENSION,
  CARS_FORM_CONFIG_EXTENSION,
  FORKLIFTS_FORM_CONFIG_EXTENSION,
  GOKARTS_FORM_CONFIG_EXTENSION,
  JOBS_FORM_CONFIG,
  MOTORHOMES_FORM_CONFIG_EXTENSION,
  PARTS_FORM_CONFIG,
  PLOT_FORM_CONFIG,
  RADIOCONTROLS_FORM_CONFIG_EXTENSION,
  RENTALS_FORM_CONFIG,
  SEMITRAILERS_FORM_CONFIG_EXTENSION,
  SEMITRUCKS_FORM_CONFIG_EXTENSION,
  SNOWSPORTS_FORM_CONFIG_EXTENSION,
  TAXIS_FORM_CONFIG_EXTENSION,
  TRACTORS_FORM_CONFIG_EXTENSION,
  TRAILERS_FORM_CONFIG_EXTENSION,
  TRUCKS_FORM_CONFIG_EXTENSION,
  VANS_FORM_CONFIG_EXTENSION,
  VEHICLES_FORM_CONFIG,
  WANTED_PARTS_FORM_CONFIG,
  WATERSPORTS_FORM_CONFIG_EXTENSION,
  XYMA_FORM_CONFIG
} from '~/constants/form/config'
import { deepMerge } from '~/utils/object'
import { Schema, SchemaField } from '~/models/form/types'

export function computeFieldState(error: string | null) {
  if (error) {
    return false
  }
  return null
}

export function mapBackendOptionToOption(backendOption: any): Option {
  return {
    text: backendOption.name?.toString() || '',
    value: backendOption.value
  }
}

export function arraySchemaToObj(schema: SchemaField[]): Schema {
  return schema.reduce((acc: Record<any, any>, field) => {
    acc[field.name] = field
    return acc
  }, {})
}

function mergeConfigs(config1: FormConfig, config2: FormConfig): FormConfig {
  return deepMerge(config1, config2)
}

export function getFormConfig(
  rootCategoryId: CategoryId,
  categories?: CategoryId[]
): FormConfig {
  switch (rootCategoryId) {
    case CategoryId.JOBS: {
      return JOBS_FORM_CONFIG
    }
    case CategoryId.VEHICLES: {
      if (categories) {
        const vehicleCategories: CategoryId[] = Array.isArray(categories)
          ? categories
          : [categories]
        if (vehicleCategories?.length) {
          if (vehicleCategories.includes(CategoryId.CARS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              CARS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.BIKES)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              BIKES_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.BOATS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              BOATS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.BICYCLES)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              BICYCLES_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.VANS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              VANS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.TRUCKS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              TRUCKS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.BUSES)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              BUSES_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.BUILDERS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              BUILDERS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.SEMITRAILERS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              SEMITRAILERS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.SEMITRUCKS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              SEMITRUCKS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.FORKLIFTS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              FORKLIFTS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.TRAILERS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              TRAILERS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.TAXIS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              TAXIS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.MOTORHOMES)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              MOTORHOMES_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.CARAVANS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              CARAVANS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.RADIOCONTROLS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              RADIOCONTROLS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.GOKARTS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              GOKARTS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.WATERSPORTS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              WATERSPORTS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.SNOWSPORTS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              SNOWSPORTS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.AIRSPORTS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              AIRSPORTS_FORM_CONFIG_EXTENSION
            )
          } else if (vehicleCategories.includes(CategoryId.TRACTORS)) {
            return mergeConfigs(
              VEHICLES_FORM_CONFIG,
              TRACTORS_FORM_CONFIG_EXTENSION
            )
          } else {
            return VEHICLES_FORM_CONFIG
          }
        }
      }
      return VEHICLES_FORM_CONFIG
    }
    case CategoryId.XYMA: {
      return XYMA_FORM_CONFIG
    }
    case CategoryId.PARTS: {
      return PARTS_FORM_CONFIG
    }
    case CategoryId.RENTALS: {
      return RENTALS_FORM_CONFIG
    }
    case CategoryId.PLOT: {
      return PLOT_FORM_CONFIG
    }
    case CategoryId.WANTED_PARTS: {
      return WANTED_PARTS_FORM_CONFIG
    }
    default: {
      return {}
    }
  }
}
