import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { VueI18n } from '~/utils/nuxt3-migration'
import {
  httpToken,
  storeToken,
  vueI18nToken
} from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import NotificationService from '~/services/notification/NotificationService'
import SearchSubscriptionFormatter from '~/services/notification/search-subscription/SearchSubscriptionFormatter'
import { StoreWithRootState } from '~/store/types'
import {
  Subscription,
  PushType,
  SubscriptionInfo
} from '~/models/search/subscriptions/types'
import { StringMap } from '~/models/shared/types'
import { invalidBodyError } from '../../errors'
import { TranslateResult } from 'vue-i18n'

type Title = string | TranslateResult

@containerScoped()
export default class SearchSubscriptionService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(storeToken) private store: StoreWithRootState,
    @inject(NotificationService)
    private notificationService: NotificationService,
    @inject(SearchSubscriptionFormatter)
    private searchSubscriptionFormatter: SearchSubscriptionFormatter,
    @inject(vueI18nToken) private i18n: VueI18n,
    @inject(DealerSiteService) private dealerSiteService: DealerSiteService
  ) {}

  async getSubscription(searchId: string): Promise<Subscription | null> {
    const response: AxiosResponse = await this.http.get(
      '/api/searches/subscriptions/',
      {
        params: { search: searchId }
      }
    )

    const emptyStatuses = [204]
    if (emptyStatuses.includes(response.status)) {
      return null
    }

    const { data: body } = response
    if (!body?.data?.subscription) {
      throw invalidBodyError(body)
    }

    return this.formatSubscription(body.data.subscription)
  }

  async getSubscriptions(): Promise<StringMap<Subscription>> {
    const { data: body } = await this.http.get('/api/searches/subscriptions/')

    if (!body?.data?.subscriptions) {
      throw invalidBodyError(body)
    }

    const {
      data: { subscriptions }
    } = body
    return new Map(
      subscriptions.map((s: Subscription) => [
        s.id?.toString(),
        this.formatSubscription(s)
      ])
    )
  }

  async subscribe(
    searchId: string,
    pushType?: PushType
  ): Promise<Subscription> {
    // eslint-disable-next-line camelcase
    const payload: { search: string; send_options?: { type: PushType } } = {
      search: searchId
    }

    if (pushType) {
      payload.send_options = {
        type: pushType
      }
    }

    const { data: body } = await this.http.put(
      '/api/searches/subscriptions/',
      payload
    )

    if (!body || !body.data) {
      throw invalidBodyError(body)
    }

    return this.formatSubscription(body.data.subscription)
  }

  async unsubscribe(searchId: string): Promise<void> {
    await this.http.delete('/api/searches/subscriptions/', {
      data: { search: searchId }
    })
  }

  private formatSubscription(subscription: any): Subscription {
    return this.searchSubscriptionFormatter.formatSubscription(subscription)
  }

  async getSubscriptionInfo(searchId: string): Promise<SubscriptionInfo> {
    const { data: body }: AxiosResponse = await this.http.get(
      '/api/searches/info/',
      {
        params: { search: searchId }
      }
    )

    if (!body?.data?.search) {
      throw invalidBodyError(body)
    }

    return this.searchSubscriptionFormatter.formatSubscriptionInfo(body.data)
  }

  showSearchNotificationUi(searchId: string, favorite: boolean): boolean {
    const { store, notificationService } = this
    return (
      !this.dealerSiteService.routeIsOfDealerSite() &&
      favorite &&
      store.state.parking.searchSubscriptions.subscribableSearchIds.has(
        searchId
      ) &&
      notificationService.userIsEligibleForNotifications()
    )
  }

  pushTypeIsValid(info: SubscriptionInfo, type: PushType): boolean {
    const notifications = info?.notifications
    switch (type) {
      case PushType.BULK:
        return notifications?.bulkValid
      case PushType.INSTANT:
        return notifications?.instantValid
    }
  }

  pushTypeIsActive(subscription: Subscription, type: PushType): boolean {
    return subscription.sendOptions?.type === type
  }

  getInstantPushTitle(info?: SubscriptionInfo): Title | null {
    return info?.notifications?.instantValid
      ? this.i18n.t('instantly_notification')
      : this.i18n.t(
          "you can't receive notifications for searches with less than {count} daily new classifieds on average",
          { count: info?.notifications?.instantAverageLimit }
        )
  }

  getBulkPushTitle(info?: SubscriptionInfo): Title {
    return info?.notifications?.bulkValid
      ? this.i18n.t('receive a single bulk notification per day')
      : this.i18n.t(
          "you can't be notified for searches with that many new daily classifieds"
        )
  }
}
