





















import { defineComponent, computed, toRefs } from '~/utils/nuxt3-migration'
import { useCol } from '~/compositions/grid/useCol'
export default defineComponent({
  props: {
    tag: { type: String, default: 'div' },
    cols: { type: [String, Number], default: null },
    lg: { type: [String, Number], default: null },
    md: { type: [String, Number], default: null },
    sm: { type: [String, Number], default: null },
    xl: { type: [String, Number], default: null },
    offset: { type: [String, Number], default: null },
    offsetLg: { type: [String, Number], default: null },
    offsetMd: { type: [String, Number], default: null },
    offsetSm: { type: [String, Number], default: null },
    offsetXl: { type: [String, Number], default: null }
  },

  setup(props) {
    const {
      cols,
      lg,
      md,
      sm,
      xl,
      offset,
      offsetLg,
      offsetMd,
      offsetSm,
      offsetXl
    } = toRefs(props)

    const {
      getOffset,
      getXlColSpanClass,
      getLgColSpanClass,
      getSmColSpanClass,
      getColSpanClass,
      getSmOffset,
      getMdColSpanClass,
      getMdOffset,
      getXlOffset,
      getLgOffset
    } = useCol()

    const colsClass = computed(() => {
      return getColSpanClass(cols.value?.toString())
    })

    const smClass = computed(() => [
      !cols.value ? colsClass.value : null,
      getSmColSpanClass(sm.value?.toString())
    ])
    const mdClass = computed(() => getMdColSpanClass(md.value?.toString()))
    const lgClass = computed(() => getLgColSpanClass(lg.value?.toString()))
    const xlClass = computed(() => getXlColSpanClass(xl.value?.toString()))

    const offsetClass = computed(() => {
      return getOffset(offset.value?.toString())
    })

    const getSmOffsetClass = computed(() => [
      !offset.value ? offsetClass.value : null,
      getSmOffset(offsetSm.value?.toString())
    ])
    const getMdOffsetClass = computed(() =>
      getMdOffset(offsetMd.value?.toString())
    )
    const getLgOffsetClass = computed(() =>
      getLgOffset(offsetLg.value?.toString())
    )
    const getXlOffsetClass = computed(() =>
      getXlOffset(offsetXl.value?.toString())
    )

    return {
      colsClass,
      mdClass,
      lgClass,
      smClass,
      xlClass,
      offsetClass,
      getSmOffsetClass,
      getMdOffsetClass,
      getLgOffsetClass,
      getXlOffsetClass
    }
  }
})
