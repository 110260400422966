









import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    colspan: {
      type: [String, Number],
      default: null
    }
  }
})
