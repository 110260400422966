import { Context } from '@nuxt/types'
import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { Route, Location } from 'vue-router'
import { VueRouter } from '~/utils/nuxt3-migration'
import {
  httpToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { PageSnippet } from '~/models/dealer/site/page-snippet/types'
import {
  Page,
  Outlet,
  ContactRecord,
  WebsiteMeta
} from '~/models/dealer/site/types'
import { DealerTelephone, SocialMediaLinks } from '~/models/dealer/types'
import { StringMap } from '~/models/shared/types'
import { formatGeolocation } from '~/services/formatters'
import UrlFormatter from '~/services/url/UrlFormatter'
import { toBase64 } from '~/utils/string'
import { invalidBodyError } from '../../errors'
import { toCamelCase } from '~/utils/object'
import { getRouteMetaItem } from '~/utils/router'

@containerScoped()
export default class DealerSiteService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(vueRouterToken) private router: VueRouter,
    @inject(UrlFormatter) private urlFormatter: UrlFormatter
  ) {}

  async getPage(pageId: string, websiteId: string): Promise<Page> {
    const { data: body } = await this.http.get(
      `/api/dsite/${websiteId}/pages/${pageId}/`
    )

    if (!body || !body.data || !body.data.page) {
      throw invalidBodyError(body)
    }

    return {
      ...this.formatPageSnippet(body.data.page),
      body: body.data.page.body
    }
  }

  async getOutlets(websiteId: string): Promise<StringMap<Outlet>> {
    const { data: body } = await this.http.get(
      `/api/dsite/${websiteId}/pages/contact/`
    )

    if (!body || !body.data || !Array.isArray(body.data.outlets)) {
      throw invalidBodyError(body)
    }

    return new Map(
      body.data.outlets.map((cr: any) => {
        const record = this.formatContactRecord(cr)
        return [this.hashContactRecord(record), record]
      })
    )
  }

  getWebsiteMetaFromDomain(
    route: Route,
    serverSideContext?: Context
  ): Promise<WebsiteMeta> {
    const domain: string = this.getDomain(route, serverSideContext)
    return this.getWebsiteMeta(domain)
  }

  getDomain(
    { params: { domain: domainRouteParam } }: Route,
    serverSideContext?: Context
  ): string {
    const requestDomainHeader =
      serverSideContext &&
      serverSideContext.req &&
      // TODO: Get http header key from models, also provide to factory in /services/dealers/site/index.ts
      serverSideContext.req.headers['dealer-site-request-domain']

    if (requestDomainHeader) {
      return requestDomainHeader.toString()
    }

    if (!domainRouteParam) {
      throw new Error(
        "can't get domain from neither url param nor server-side request header"
      )
    }

    return domainRouteParam
  }

  async getWebsiteMeta(url: string): Promise<WebsiteMeta> {
    const { data: body } = await this.http.get('/api/dsite/domains/', {
      params: { domain: url }
    })

    if (!body || !body.data || !body.data.website) {
      throw invalidBodyError(body)
    }

    const userId = body.data.website.user_id
    const websiteId = body.data.website.id
    return {
      userId: userId && userId.toString(),
      websiteId: websiteId && websiteId.toString(),
      indexPage: body.data.website.index_page
    }
  }

  resolvePageRoute(
    page: PageSnippet
  ): {
    location: Location
    route: Route
    href: string
    normalizedTo: Location
    resolved: Route
  } {
    if (!page) {
      throw new TypeError(`Falsy page: ${page}`)
    }

    const { pagePath, type } = page
    const validTypes = [
      'contact',
      'custom_page',
      'search',
      'search_custom_page'
    ]
    if (!validTypes.includes(type)) {
      throw new Error(`Page type ${type} is not supported by this method.`)
    }
    return this.router.resolve({
      name: '__dealers_site_page',
      params: {
        pagePath
      }
    })
  }

  resolveClassifiedRoute(
    slugOrId: string,
    searchPath: string
  ): {
    location: Location
    route: Route
    href: string
    normalizedTo: Location
    resolved: Route
  } {
    return this.router.resolve({
      name: '__dealers_site_classified',
      params: {
        slugOrId,
        searchPath
      }
    })
  }

  private hashContactRecord({
    displayName,
    mobile,
    telephone1,
    telephone2,
    geolocation
  }: ContactRecord): string {
    return toBase64(
      String(
        displayName +
          (mobile && mobile.number) +
          (geolocation && geolocation.lon) +
          (telephone1 && telephone1.number) +
          (geolocation && geolocation.lat) +
          (telephone2 && telephone2.number)
      )
    )
  }

  formatPageSnippet(p: any): PageSnippet {
    const pagePath = p.page_path
    const index = p.is_index
    const searchArgs = p.search_args
    delete p.page_path
    delete p.is_index
    delete p.search_args
    return {
      ...p,
      id: p.id && p.id.toString(),
      pagePath,
      index,
      searchArgs
    }
  }

  private formatContactRecord(c: any): ContactRecord {
    const displayName = c.display_name
    const workingHours = c.working_hours
    const address = c.pretty_address
    let socialMediaLinks: SocialMediaLinks = c.social_media
    const googleRating = c.google_rating
    delete c.display_name
    delete c.working_hours
    delete c.pretty_address
    delete c.social_media
    delete c.google_rating

    if (socialMediaLinks) {
      socialMediaLinks = this.urlFormatter.formatSocialMediaLinks(
        socialMediaLinks
      )
    }
    return {
      ...c,
      displayName,
      workingHours,
      address,
      socialMediaLinks,
      geolocation: formatGeolocation(c.geolocation),
      telephone1: this.formatOutletPhone(c.telephone1),
      telephone2: this.formatOutletPhone(c.telephone2),
      mobile: this.formatOutletPhone(c.mobile),
      fax: this.formatOutletPhone(c.fax),
      googleRating:
        googleRating && googleRating.rating_normalized !== -1
          ? {
              value: googleRating.rating,
              count: googleRating.user_ratings_total,
              url: googleRating.url
            }
          : undefined
    }
  }

  private formatOutletPhone(ph: any): DealerTelephone | undefined {
    if (!ph) {
      return undefined
    }

    return toCamelCase(ph)
  }

  routeIsOfDealerSite(route?: Route): boolean {
    // NOTE: Caller should pass route object from context if this is being called from plugin code.
    const r: Route = route || this.router.currentRoute
    return !!getRouteMetaItem(r, 'isDealerSiteRoute')
  }
}
