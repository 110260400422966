import { TranslateResult } from 'vue-i18n'
import { Icon } from '~/models/icons/types'

export interface AppConfirmationModalState {
  isVisible: boolean
  options: ConfirmationModalOptions
}

export interface ConfirmationModalOptions {
  centered?: boolean
  showIcon?: boolean
  icon?: Icon
  title?: TranslateResult
  variant?: 'primary' | 'danger' | 'success'
  hideDismissButton?: boolean
  size?: 'sm' | 'md'
  text?: string | TranslateResult
  confirmButtonText?: string | TranslateResult
  cancelButtonText?: string | TranslateResult
  params?: any
  onConfirm?: (params: any) => void
  onCancel?: (params: any) => void
}

export const APP_CONFIRMATION_MODAL_NS = 'appConfirmationModal'
function getDefaultState(): AppConfirmationModalState {
  return {
    isVisible: false,
    options: {}
  }
}
export default getDefaultState
