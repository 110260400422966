






































import { ErrorPageError } from '~/models/error'
import { HttpStatus } from '~/constants/http'
import error404Image from '~/assets/img/errors/404.png'
import RecentFeed from '~/components/car/classifieds/index/feeds/RecentFeed.vue'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { getRouteMetaItem } from '~/utils/router'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { RecentFeed },
  props: {
    error: {
      type: Object as PropType<ErrorPageError>,
      required: true
    }
  },
  computed: {
    errorImage() {
      const { statusCode } = this.error
      switch (statusCode) {
        case HttpStatus.NOT_FOUND: {
          return error404Image
        }
        default: {
          return null
        }
      }
    },
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    // TODO: move this to the store
    isIframe() {
      return getRouteMetaItem(this.$route, 'isIframe')
    },
    isSubdomain() {
      return getRouteMetaItem(this.$route, 'isSubdomain')
    },
    showRecentFeed() {
      return (
        !this.isIframe &&
        ![
          HttpStatus.TOO_MANY_REQUESTS,
          HttpStatus.INTERNAL_SERVER_ERROR,
          HttpStatus.BAD_GATEWAY,
          HttpStatus.UNAUTHORIZED,
          HttpStatus.FORBIDDEN
        ].includes(this.error.statusCode)
      )
    },
    showGoBack() {
      return !this.isIframe && !this.isSubdomain
    }
  }
})
