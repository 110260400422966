import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import { StoreWithRootState } from '~/store/types'
import { USER_NS } from '~/store/modules/shared/user/state'
import { storeToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class NotificationService {
  constructor(@inject(storeToken) private store: StoreWithRootState) {}

  userIsEligibleForNotifications(): boolean {
    return !this.store.getters[`${USER_NS}/isAnon`]
  }
}
