import RouteGuardService from '~/services/RouteGuardService'
import { Context } from '@nuxt/types'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'

// used per-page when login is required to view the page
export default function({ $dep, route, redirect, app }: Context) {
  const routeGuardService = $dep(RouteGuardService)
  const legacyUrlService = $dep(LegacyUrlService)
  const { router } = app

  // parking is public to everyone (fix for plot account parking
  const isParkingRoute = Boolean(route.name?.startsWith('__account_parking'))

  // routes that are also available to logged in single users
  const singleAllowedRoutes = [
    '__classified_one_click_touch',
    '__classifieds_edit'
  ]

  let userIsLoggedIn = routeGuardService.userIsLoggedIn()

  if (
    route.name &&
    singleAllowedRoutes.some(r => route.name?.toString().startsWith(r))
  ) {
    userIsLoggedIn =
      routeGuardService.userIsLoggedIn() || routeGuardService.userIsSingle()
  }

  if (!userIsLoggedIn && !isParkingRoute) {
    const resolvedRoute = router?.resolve({
      name: '__login',
      query: { gotonext: route.path }
    })

    // if login route name exists in the app's nuxt router (ie plot) then use that else go to the legacy urls
    const redirectUrl =
      resolvedRoute?.resolved?.matched?.length && resolvedRoute?.href
        ? resolvedRoute.href
        : `${legacyUrlService.getUserLoginUrl()}?gotonext=${route.path}`

    return redirect(redirectUrl)
  }
}
