































import { ciInfoCircle } from '~/icons/source/solid/info-circle'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    placement: {
      type: String,
      default: 'bottom'
    },
    variant: {
      type: String,
      default: 'info'
    },
    forceBlue: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: Number,
      required: false,
      default: 18
    }
  },
  computed: {
    icons() {
      return {
        question: ciInfoCircle
      }
    }
  }
})
