import CDropdown from '~/components/shared/configurable/dropdown/CDropdown.vue'
import { v4 as uuidV4 } from 'uuid'
import {
  vue3DirectiveBind,
  vue3DirectiveUpdate,
  Vue
} from '~/utils/nuxt3-migration'

const id = `s${uuidV4()}`

const allTriggers = ['hover', 'focus', 'touch', 'click', 'blur']

const allPositions = [
  'auto',
  'top',
  'top-start',
  'top-left',
  'top-right',
  'left',
  'right',
  'left-start',
  'right-start',
  'bottom',
  'bottom-start',
  'bottom-right',
  'bottom-left',
  'bottom-end'
]

interface Value {
  content: string
  html: string
  delay: {
    show: number
    hide: number
  }
  trigger: string
  placement: string
  title: string
  variant: string
  customClass: string | object
  disabled: boolean
}
export default Vue.directive('c-popover', {
  [vue3DirectiveUpdate]: (el, { modifiers, value }) => {
    clearAllPopoversElements(el)
    createInstance(el, modifiers, value)
  },
  [vue3DirectiveBind]: (el, { modifiers, value }) => {
    createInstance(el, modifiers, value)
  }
})

const clearAllPopoversElements = (el: HTMLElement) => {
  const cPopoverElements = el.querySelectorAll('.c-popover')
  cPopoverElements.forEach((cp, i) => {
    if (cPopoverElements.length - 2 > i) {
      cp.remove()
    }
  })
}

const createInstance = async (
  el: HTMLElement,
  modifiers: { [key: string]: boolean | string },
  value: Value
) => {
  let instance = null as any
  const elementId = el.getAttribute('id') || `e${uuidV4()}`
  if (!el.getAttribute('id')) {
    el.setAttribute('id', elementId)
  }
  let isPopoverVisible = false
  const propsData = await getPropsData(el, modifiers, value)
  // TODO Nuxt3: Remove or refactor this
  const CDropdownClass = Vue.extend(CDropdown as any)
  instance = new CDropdownClass({ propsData })

  // TODO Nuxt3: Adapt these
  instance.$on('show', () => (isPopoverVisible = true))
  instance.$on('hidden', () => (isPopoverVisible = false))

  if (propsData.title && propsData.html) {
    instance.$slots.title = [
      instance.$createElement('span', {
        domProps: { innerHTML: propsData.title }
      })
    ]
  } else if (propsData.title) {
    instance.$slots.title = [instance.$createElement('div', propsData.title)]
  }

  instance.$slots.default = [
    instance.$createElement('div', { class: 'tw-hidden' })
  ]

  setContent(instance, propsData.html as string, propsData.content)
  if (propsData.triggers.includes('hover')) {
    el.onmouseover = () => {
      instance.show()
    }
    el.onmouseleave = () => {
      instance.hide()
    }
  } else if (propsData.triggers.includes('click')) {
    el.onclick = () => {
      if (isPopoverVisible) {
        instance.hide()
      } else {
        instance.show()
      }
    }
  }
  const mountElement = document.createElement('div')

  mountElement.setAttribute('id', id)
  el.appendChild(mountElement)

  instance.$mount(document.getElementById(id))
}

const getPropsData = async (
  el: HTMLElement,
  modifiers: { [key: string]: boolean | string },
  value: Value
) => {
  let content = null
  let wrapperClass = null
  let disabled = null

  const {
    content: contentVal,
    html: htmlVal,
    delay,
    trigger,
    placement: placementVal,
    title,
    variant,
    customClass,
    disabled: disabledVal
  } = value

  if (typeof value === 'string') {
    content = value
  } else {
    content = contentVal
  }
  if (typeof disabledVal === 'boolean') {
    disabled = disabledVal
  } else {
    disabled = false
  }
  const defaultTriggers = ['click']
  const defaultPlacement = 'top'
  const html = htmlVal || modifiers?.html
  let triggers = allTriggers.filter(t => modifiers[t]) as Array<string>

  const placement = placementVal || allPositions.find(p => modifiers[p])

  if (trigger) {
    triggers = trigger.split(' ')
  }
  if (!el.style.position) {
    await Vue.nextTick()
    el.classList.add('tw-relative')
    wrapperClass =
      'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full c-popover negative-z-index'
  }

  return {
    triggers: triggers.length ? triggers : defaultTriggers,
    placement: placement || defaultPlacement,
    wrapperClass,
    disabled,
    contentClass: customClass,
    delay,
    container: 'body',
    variant,
    maxWidth: 325,
    title,
    html,
    content
  }
}

const setContent = (instance: any, html: string, content: string) => {
  if (html) {
    instance.$slots.content = [
      instance.$createElement('span', {
        domProps: { innerHTML: content }
      })
    ]
  } else if (content) {
    instance.$slots.content = [instance.$createElement('div', content)]
  }
}
