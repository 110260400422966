var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-overlay tw-h-full",attrs:{"id":_vm.id}},[(_vm.show)?_c('div',{staticClass:"tw-absolute tw-h-full tw-w-full layer",class:[
      _vm.bgColorToUse,
      {
        'fade-content': !_vm.noFade,
        'tw-rounded': _vm.rounded,
        'tw-rounded-t': _vm.rounded === 'top',
        'tw-rounded-b': _vm.rounded === 'bottom',
        'tw-rounded-l ': _vm.rounded === 'left',
        'tw-rounded-r': _vm.rounded === 'right',
        'rounded-circle ': _vm.rounded === 'cirlce',
        'tw-rounded-md': _vm.rounded === 'lg',
        'tw-rounded-[0.2rem]': _vm.rounded === 'sm'
      }
    ],style:({ opacity: _vm.opacity })}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show && !_vm.hideForegroundContent),expression:"show && !hideForegroundContent"}],staticClass:"foreground-container",class:{
      foregroundContainerClass: _vm.foregroundContainerClass,
      'with-spinner': !_vm.$slots.foregroundContent
    }},[(_vm.$slots.foregroundContent)?_vm._t("foregroundContent"):(!_vm.$slots.foregroundContent && _vm.show && !_vm.progress)?_c('CSpinner',{staticClass:"spinner",class:{
        lg: _vm.spinnerSize === 'lg'
      },attrs:{"small":_vm.spinnerSize === 'sm',"variant":_vm.spinnerVariant}}):(!_vm.$slots.foregroundContent && _vm.show && _vm.progress)?_c('CCircleProgressBar',{staticClass:"tw-text-white",attrs:{"value":_vm.progress,"decimals":0,"custom-stroke-width":5,"without-transition":""}}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"tw-h-full tw-w-full overlay-content-container",class:{ 'pointer-events-none': _vm.show }},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }