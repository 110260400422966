import { interopDefault } from '~/router/utils'
import { RouteConfig } from 'vue-router'

export function getParkingRoutes(namePrefix?: string): RouteConfig[] {
  const prefix = namePrefix ? '_' + namePrefix : ''
  return [
    {
      path: 'favorite/',
      name: `_${prefix}_parking_classifieds_favorite`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/parking/favorite.vue' /* webpackChunkName: "pages-shared-account-classifieds-favorite.vue" */
          )
        ),
      meta: {
        ignoreScrollBehavior: true,
        showOneTap: true,
        tabName: 'classifieds'
      }
    },
    {
      path: 'lists/',
      name: `_${prefix}_parking_classifieds_lists`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/parking/lists/index.vue' /* webpackChunkName: "pages-shared-account-classifieds-index.vue" */
          )
        ),
      meta: {
        ignoreScrollBehavior: true,
        tabName: 'lists'
      }
    },
    {
      path: 'lists/:id',
      name: `_${prefix}_parking_classifieds_list`,
      component: () =>
        interopDefault(
          import(
            '~/pages/shared/account/parking/lists/list.vue' /* webpackChunkName: "pages-shared-account-classifieds-list.vue" */
          )
        ),
      meta: {
        ignoreScrollBehavior: true,
        showOneTap: true,
        tabName: 'lists'
      }
    }
  ]
}
