




import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    jsonld: {
      type: Object,
      required: true
    }
  },
  computed: {
    processedJsonld() {
      return JSON.stringify(this.jsonld)
    }
  }
})
