// import { Search } from '~/router/routes/car/classifieds/search/components'
import { Route } from 'vue-router/types/router'

export const blackFridayRoutes = [
  {
    name: '__classifieds_search_black_friday',
    path: '/black-friday/*',
    redirect: (to: Route) => {
      if (to.query.blackfriday) {
        // transform to states
        delete to.query.blackfriday
      }

      return {
        name: '__classifieds_search_deals_seo',
        query: { ...to.query }
      }
    }
  }
]
