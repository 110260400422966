




































import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'
import { useGoogleAutocomplete } from '~/compositions/google/autocomplete'
import { useI18n } from '~/compositions/i18n'
import { GeolocationService } from '~/services/navigator/GeolocationService'
import { useDeps } from '~/compositions/dependency-container'
import SnackbarService from '~/services/snackbar/SnackbarService'
import GoogleMapsService from '~/services/GoogleMapsService'
import { FullLocation, GoogleAutocompleteInputMode } from '~/models/google'
import { preventNonNumericKeyboardEvent } from '~/utils/dom'
import { InputSize } from '~/models/app/input'

export default defineComponent({
  model: vue3Model,
  props: {
    modelValue: {
      type: String,
      default: null
    },
    gps: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<InputSize>,
      default: 'md'
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    countryCodes: {
      type: Array as PropType<string[]>,
      default() {
        return ['gr', 'cy', 'bg', 'al', 'tr', 'mk']
      }
    },
    // Shows an error message if the user does not specifically select a location from the map
    isValid: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String as PropType<GoogleAutocompleteInputMode>,
      default: GoogleAutocompleteInputMode.ADDRESS
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { countryCodes, gps, isValid, mode, disabled } = toRefs(props)

    const loading = ref<boolean>(false)
    const googleAutocompleteTemplateRef = ref<HTMLInputElement | null>(null)
    const inputKeydownListener = ref<any>(null)
    const internalValue = ref(props.modelValue)
    const inputFocused = ref(false)

    const { createAutocompleteInput, createListeners } = useGoogleAutocomplete()
    const { t } = useI18n()
    const [geolocationService, snackbarService, googleMapsService] = useDeps(
      GeolocationService,
      SnackbarService,
      GoogleMapsService
    )

    const inputClasses = computed(() => {
      const classes = []
      if (gps.value || internalValue.value) {
        classes.push(['tw-rounded-tr-none', 'tw-rounded-br-none'])
      }
      return classes
    })

    const autoCompleteInput = computed(() => {
      if (googleAutocompleteTemplateRef.value?.inputTemplateRef) {
        return googleAutocompleteTemplateRef.value.inputTemplateRef
      }
      return null
    })

    onMounted(async () => {
      if (!autoCompleteInput.value) {
        return
      }
      await createAutocompleteInput(
        autoCompleteInput.value,
        countryCodes.value as string[]
      )
      await createListeners(
        (data: FullLocation) => {
          switch (mode.value) {
            case GoogleAutocompleteInputMode.POSTCODE: {
              internalValue.value = data.postcode
              break
            }
            case GoogleAutocompleteInputMode.CITY: {
              internalValue.value = data.city
              break
            }
            case GoogleAutocompleteInputMode.COUNTRY: {
              internalValue.value = data.country
              break
            }
            case GoogleAutocompleteInputMode.ADDRESS:
            default: {
              internalValue.value = data.address
              break
            }
          }
          emit('change', data)
        },
        () => snackbarService.error(t('location not found'))
      )
      inputKeydownListener.value = autoCompleteInput.value.addEventListener(
        'keydown',
        (e: any) => {
          switch (mode.value) {
            case GoogleAutocompleteInputMode.POSTCODE: {
              // prevent non-numeric input text when the mode is postcode
              preventNonNumericKeyboardEvent(e)
            }
          }
        }
      )
    })

    watch(
      () => props.modelValue,
      newValue => {
        internalValue.value = newValue
      }
    )

    onBeforeUnmount(() => {
      autoCompleteInput.value &&
        autoCompleteInput.value.removeEventListener(
          'keydown',
          inputKeydownListener.value
        )
    })

    async function getCurrentGpsLocation() {
      if (disabled.value) {
        return
      }
      const gpsGeolocation = await geolocationService.getCurrentGpsLocationWithSnackbarOnError()
      if (!gpsGeolocation) {
        return
      }

      const {
        city,
        address,
        postcode
      } = await googleMapsService.getGeocodingProperties(gpsGeolocation)
      internalValue.value = address
      emit('change', { city, address, postcode, geolocation: gpsGeolocation })
    }

    const icons = {
      mapMarkerAlt: faMapMarkerAlt,
      clear: faTimes
    }

    function clear() {
      if (disabled.value) {
        return
      }
      internalValue.value = null
      emit('change', {
        city: null,
        address: null,
        postcode: null,
        geolocation: null
      })
    }

    function onInputFocusIn() {
      inputFocused.value = true
    }

    function onInputFocusOut() {
      inputFocused.value = false
    }

    const clearButtonClasses = computed(() => [
      ...(disabled.value
        ? ['tw-bg-grey-200', 'tw-cursor-not-allowed']
        : ['tw-bg-white', 'tw-cursor-pointer', 'hover:tw-text-red']),
      ...(inputFocused.value
        ? ['tw-border-t-[#00aeef]', 'tw-border-b-[#00aeef]']
        : []),
      ...(isValid.value ? [] : ['tw-border-[#d9534f]'])
    ])

    const gpsButtonClasses = computed(() => [
      ...(disabled.value
        ? ['tw-cursor-not-allowed']
        : ['tw-cursor-pointer', 'hover:tw-text-blue'])
    ])

    function handleInputInput(value: string) {
      emit('update:modelValue', value)
    }

    return {
      icons,
      loading,
      inputClasses,
      googleAutocompleteTemplateRef,
      internalValue,
      getCurrentGpsLocation,
      clear,
      inputFocused,
      onInputFocusIn,
      onInputFocusOut,
      clearButtonClasses,
      gpsButtonClasses,
      handleInputInput
    }
  }
})
