import { ActionTreeWithRootState } from '~/store/types'
import {
  CLEAR_NOTIFICATION_CENTER_DATA,
  DECREASE_UNREAD_NOTIFICATIONS,
  READ_ALL_CURRENT_NOTIFICATION_CENTER_ROWS,
  SET_USER_DATA,
  SET_USER_EXTRA_DATA,
  SET_USER_NOTIFICATION_CENTER_DATA,
  SET_USER_NOTIFICATION_CENTER_LOADING_STATE
} from './mutation-types'
import { UserState } from './state'
import { SET_ADMIN_EXTRA_DATA } from '~/store/modules/shared/admin/user/mutation-types'
import { ADMINUSER_NS } from '~/store/modules/shared/admin/user/state'
import { UserType } from '~/models/user/types'
import NotificationCenterService from '~/services/notification/NotificationCenterService'
import { AxiosError } from 'axios'
import {
  NotificationEvent,
  NotificationTypeEnum
} from '~/models/notification-center/types'
import AuthUserService from '~/services/user/AuthUserService'
import UserExtrasService from '~/services/user/UserExtrasService'
import { HttpStatus } from '~/constants/http'
import { changeFaviconNotification } from '~/utils/dom'

export default {
  async loadUser({ dispatch }) {
    try {
      await Promise.all([
        dispatch('loadAuthUserData'),
        dispatch('loadUserExtras')
      ])
    } catch (error) {
      const { response } = error
      if (response) {
        return this.$error({ statusCode: error.response.status })
      }

      this.$logger.captureError(error as Error)
      return this.$error({ statusCode: HttpStatus.INTERNAL_SERVER_ERROR })
    }
  },
  async loadUserSubscribable({ dispatch }) {
    // used in cases where we want to run something after loadUser
    // like refreshing data without a window refresh
    // subscribe to this action instead of the simple loadUser (to avoid mishaps/bugs)
    await dispatch('loadUser')
  },
  async loadAuthUserData({ commit }) {
    const authUserService = this.$dep(AuthUserService)

    const userData = await authUserService.getUser()

    commit(SET_USER_DATA, userData)
    this.$logger.setUser(userData)
  },
  async loadUserExtras({ state, commit }) {
    const userExtrasService = this.$dep(UserExtrasService)

    const {
      userExtras,
      adminExtrasData
    } = await userExtrasService.getUserExtras()
    commit(SET_USER_EXTRA_DATA, userExtras)

    if (
      adminExtrasData &&
      (state.type === UserType.ADMIN || adminExtrasData.adminExtras.suas)
    ) {
      commit(
        `${ADMINUSER_NS}/${SET_ADMIN_EXTRA_DATA}`,
        adminExtrasData.adminExtras,
        {
          root: true
        }
      )
    }
  },
  async reloadUserExtras({ dispatch, getters }) {
    if (getters.isAdmin) {
      return
    }

    await dispatch('loadUserExtras')
  },
  async fetchNotificationCenterData(
    { commit },
    { page, types }: { page: number; types: Array<NotificationTypeEnum> }
  ) {
    const notificationCenterService = this.$dep(NotificationCenterService)
    commit(SET_USER_NOTIFICATION_CENTER_LOADING_STATE, true)
    if ((page === 1 || !page) && !types) {
      commit(CLEAR_NOTIFICATION_CENTER_DATA)
    }
    try {
      const {
        notifications,
        notificationTypes
      } = await notificationCenterService.fetchNotification(page, types)
      commit(SET_USER_NOTIFICATION_CENTER_DATA, {
        notifications,
        notificationTypes
      })

      if (process.client) {
        // although here they return as unread, in backend they have been read because fetchNotifications
        // sends read-notifications=1 in url props
        const unreadCount = [...notifications.rows].filter(
          r => r.isRead === false
        ).length

        commit(DECREASE_UNREAD_NOTIFICATIONS, unreadCount)
      }
    } catch (e) {
      notificationCenterService.handleError(e as AxiosError)
    } finally {
      commit(SET_USER_NOTIFICATION_CENTER_LOADING_STATE, false)
    }
  },
  async readNotification({ state, commit }, notification: NotificationEvent) {
    const notificationCenterService = this.$dep(NotificationCenterService)
    try {
      await notificationCenterService.readNotifications(notification.id)

      if (state.unreadNotifications) {
        commit(DECREASE_UNREAD_NOTIFICATIONS)
      }

      if (state.unreadNotifications === 0) {
        changeFaviconNotification(false)
      }
    } catch (e) {
      notificationCenterService.handleError(e as AxiosError)
    }
  },
  async readAllNotifications({ state, commit }) {
    const notificationCenterService = this.$dep(NotificationCenterService)
    try {
      commit(SET_USER_NOTIFICATION_CENTER_LOADING_STATE, true)
      await notificationCenterService.readAllNotifications()
      await new Promise(resolve => setTimeout(resolve, 1000))

      if (state.unreadNotifications) {
        commit(DECREASE_UNREAD_NOTIFICATIONS, state.unreadNotifications)
      }

      commit(READ_ALL_CURRENT_NOTIFICATION_CENTER_ROWS)

      changeFaviconNotification(false)
      this.$snackbar.success(
        // @ts-ignore
        this.app.i18n.t('success_clear_notifications')
      )
      return true
    } catch (e) {
      notificationCenterService.handleError(e as AxiosError)
      return false
    } finally {
      commit(SET_USER_NOTIFICATION_CENTER_LOADING_STATE, false)
    }
  }
} as ActionTreeWithRootState<UserState>
