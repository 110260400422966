import SearchSubscriptionService from '~/services/notification/search-subscription/SearchSubscriptionService'
import { SearchSubscriptionsState } from '~/store/modules/shared/parking/searchSubscriptions/state'
import { ActionTreeWithRootState } from '~/store/types'
import { USER_NS } from '~/store/modules/shared/user/state'
import {
  PushType,
  Subscription,
  SubscriptionInfo
} from '~/models/search/subscriptions/types'
import { StringMap } from '~/models/shared/types'
import {
  ADD_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  ADD_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS_LOADED,
  INCREMENT_TOTAL_COUNT,
  DECREMENT_TOTAL_COUNT,
  SET_COUNTS,
  SET_LOADING_STATE,
  ADD_SUBSCRIPTION_INFO_SET
} from './mutation-types'

export default {
  async loadSubscription({ commit }, searchId: string) {
    commit(SET_LOADING_STATE, true)
    try {
      const subscription = await this.$dep(
        SearchSubscriptionService
      ).getSubscription(searchId)
      subscription && commit(ADD_SUBSCRIPTION, subscription)
    } catch (error) {
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  },
  async loadSubscriptionInfo({ commit }, searchId: string) {
    commit(SET_LOADING_STATE, true)
    try {
      const info = await this.$dep(
        SearchSubscriptionService
      ).getSubscriptionInfo(searchId)
      commit(ADD_SUBSCRIPTION_INFO_SET, info)
      return info
    } catch (error) {
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  },
  async loadSubscriptions({ commit }) {
    commit(SET_LOADING_STATE, true)
    try {
      const subscriptions: StringMap<Subscription> = await this.$dep(
        SearchSubscriptionService
      ).getSubscriptions()
      commit(ADD_SUBSCRIPTIONS, subscriptions)
    } catch (error) {
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  },
  async loadSubscriptionsForSearches({ commit, rootState, dispatch }) {
    if (!rootState.parking.searches) {
      this.$logger.captureError(
        new Error(
          "Can't load subscriptions without having loaded searches first"
        )
      )
      return
    }

    try {
      await dispatch('loadSubscriptions')
      commit(SET_SUBSCRIPTIONS_LOADED)
    } catch (error) {
      this.$logger.captureError(error)
    }
  },
  async subscribe(
    { commit },
    {
      searchId,
      pushType = PushType.INSTANT,
      update = false
    }: { searchId: string; pushType: PushType; update: boolean }
  ) {
    commit(SET_LOADING_STATE, true)
    try {
      const subscription: Subscription = await this.$dep(
        SearchSubscriptionService
      ).subscribe(searchId, pushType)
      commit(ADD_SUBSCRIPTION, subscription)
      !update && commit(INCREMENT_TOTAL_COUNT)
    } catch (error) {
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  },
  async unsubscribe({ commit }, searchId: string) {
    commit(SET_LOADING_STATE, true)
    try {
      await this.$dep(SearchSubscriptionService).unsubscribe(searchId)
      commit(DELETE_SUBSCRIPTION, searchId)
      commit(DECREMENT_TOTAL_COUNT)
    } catch (error) {
      this.$logger.captureError(error)
      throw error
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  },
  deleteStateSubscription({ commit, getters }, searchId: string) {
    if (!getters.subscriptionExists(searchId)) {
      return
    }

    commit(DELETE_SUBSCRIPTION, searchId)
    commit(DECREMENT_TOTAL_COUNT)
  },
  async loadCount({ commit, rootGetters }) {
    if (rootGetters[`${USER_NS}/isAnon`]) {
      return
    }
    commit(SET_LOADING_STATE, true)
    try {
      const {
        maxSubscriptions,
        totalSubscriptions
      }: SubscriptionInfo = await this.$dep(
        SearchSubscriptionService
      ).getSubscriptionInfo('')

      commit(SET_COUNTS, {
        total: totalSubscriptions,
        max: maxSubscriptions
      })
    } catch (error) {
      this.$logger.captureError(error)
    } finally {
      commit(SET_LOADING_STATE, false)
    }
  }
} as ActionTreeWithRootState<SearchSubscriptionsState>
