import { Pagination } from '~/models/search/types'
import { AxiosError } from 'axios'
import { Description } from '~/models/classified/description/types'
import { HttpStatus } from '~/constants/http'
export interface TradesRecommendations {
  classified: TradeClassified | null
  pagination: Pagination
  rows: Array<TradesRowClassified>
  tradableClassifiedCount: number
}

export interface TradeClassified {
  notifications: TradesNotifications | null
  thumb: string
  seoUrl: string
  title: string
  id?: number
  price: string
}

export interface TradeClassifiedsResult {
  allowedTradesMax: number
  classifieds: TradeClassified[]
}
export interface TradesCategory {
  children: []
  hasChildren: boolean
  isUnselectable: true
  name: string
  original: true
  parentIds: Array<number>
  selected: boolean
  value: number
}

export interface TradesEditResult {
  onlyTrades: boolean
  isCategoryTradeable: boolean
}

export interface TradeDeleteResult {
  message: string
  status: HttpStatus
}

export interface TradesClassifiedsSettings {
  max: number
  currentCountOfUser: number
}

export interface RelatedTrade {
  c: number
  ids: number[]
  names: string[]
}

export interface SuggestedTradesSetting {
  related: RelatedTrade[]
}

export interface TradesSettings {
  categories: Array<number>
  maxCategories: number
  onlyTrades?: boolean
  suggestedTrades?: SuggestedTradesSetting
  classifieds?: TradesClassifiedsSettings
  showTradesLink?: boolean
  showAnonMessage?: boolean
  tradesCategories?: { categories: TradesCategory[] }
}

export interface keyFeatures {
  extra: {
    categoryTags: Array<string>
    categoryIds: Array<number>
  }
  value: string
  key: string
  label: string
}

export interface TradesCompactClassified {
  keyFeatures: Array<keyFeatures>
  title: string
  description: Description
  price: any
  extras: [
    {
      value: string
      key: number
      name: string
    }
  ]
  id: number
  thumbsPatterns: ThumbsPatterns
  telephoneVerified: boolean
  'location.longPrettyName': string
  creditCardVerified: boolean
}

export interface TradesClassifiedDetailsResult {
  compactClassified: TradesCompactClassified
  match?: boolean
}
export interface TradesClassifiedDetails extends TradesCompactClassified {
  id: number
  match?: boolean
  error?: AxiosError
}

interface ThumbsPatterns {
  count: number
  urls: Array<string>
  stretch: {
    gallery: boolean
    list: boolean
  }
}

interface KeyFeature {
  key: string
  value: string
  label: string
  extra?: string
}

export interface TradesRow {
  category: string
  keyFeatures: Array<KeyFeature>
  thumb: string
  title: string
  id: number
  thumbs: {
    count: number
    urls: Array<string>
  }
  modified: string
  addressLong: string
  telephoneVerified: false
  creditCardVerified: false
}

export interface TradesRowClassified {
  category: string
  keyFeatures: Array<KeyFeature>
  title: string
  modified: string
  thumbsPatterns: ThumbsPatterns
  addressLong: string
  telephoneVerified: boolean
  price: string | { value: string }
  priceDiff: number
  id: number
  seoUrl?: string
  creditCardVerified: boolean
}

export interface TradesMatchRow {
  message: TradeMessage
  classified: TradesRowClassified
  matched?: string
}

export interface TradeMessage {
  read?: boolean
  threadId: number
  preview: string
  threadLastMessageTs?: number
}

export interface TradesLikedRow {
  classified: TradesRowClassified
  created?: string
  updated?: string
}

export interface TradesMatches {
  pagination: Pagination
  rows: Array<TradesMatchRow>
}
export interface TradesLiked {
  pagination: Pagination
  rows: Array<TradesLikedRow>
}

export enum TradesNotificationType {
  MATCHED = 'matched',
  MESSAGE = 'message',
  LIKED_BY = 'liked-by'
}
export interface TradesNotifications {
  tradesLikedBy: number
  tradesMessage: number
  tradesMatched: number
}
