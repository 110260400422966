import { VueRouter } from '~/utils/nuxt3-migration'
import { StoreWithRootState } from '~/store/types'
import { Classified } from '~/models/classified/types'
import DealerSiteService from '../dealers/site/DealerSiteService'
import ClassifiedSearchPageUrlService from './ClassifiedSearchPageUrlService'
import { containerScoped } from '~/decorators/dependency-container'
import { inject } from 'tsyringe'
import {
  storeToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class DealerSiteClassifiedSearchPageUrlService extends ClassifiedSearchPageUrlService {
  constructor(
    @inject(DealerSiteService) private dealerSiteService: DealerSiteService,
    @inject(storeToken) private store: StoreWithRootState,
    @inject(vueRouterToken) private router: VueRouter
  ) {
    super()
  }

  getUrl(path: string): string {
    const parts = path.split('?')
    const query = parts.length === 2 ? parts[1] : ''
    // Insert current route between base url and query
    return location.origin + this.router.currentRoute.path + '?' + query
  }

  getClassifiedUrl(classified: Classified): string {
    const activePage = this.store.getters['dealers/site/activePage']
    return this.dealerSiteService.resolveClassifiedRoute(
      classified.id.toString(),
      activePage && activePage.pagePath
    ).href
  }
}
