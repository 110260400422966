















































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs
} from '~/utils/nuxt3-migration'
import { ciExclamationTriangle } from '~/icons/source/solid/exclamation-triangle'
import { ciExclamationTriangle as ciExclamationTriangleRegular } from '~/icons/source/regular/exclamation-triangle'
import { ciTimesCircle } from '~/icons/source/solid/times-circle'
import { ciTimesCircle as ciTimesCircleRegular } from '~/icons/source/regular/times-circle'
import { ciCheckCircle } from '~/icons/source/solid/check-circle'
import { ciCheckCircle as ciCheckCircleRegular } from '~/icons/source/regular/check-circle'
import { ciInfoCircle } from '~/icons/source/solid/info-circle'
import { ciInfoCircle as ciInfoCircleRegular } from '~/icons/source/regular/info-circle'
import { ciExclamationCircle } from '~/icons/source/solid/exclamation-circle'
import { ciExclamationCircle as ciExclamationCircleRegular } from '~/icons/source/regular/exclamation-circle'
import { ciTimes } from '~/icons/source/regular/times'

export default defineComponent({
  props: {
    dismissible: {
      type: Boolean,
      default: false
    },
    fade: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String as PropType<
        | 'primary'
        | 'success'
        | 'warning'
        | 'danger'
        | 'secondary'
        | 'light'
        | 'neutral'
        | 'info'
      >,
      default: 'info'
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    description: {
      type: String,
      default: '',
      required: false
    },
    outline: {
      type: Boolean,
      default: false,
      required: false
    },
    icon: {
      type: [Object, Boolean],
      default: false
    },
    contentClass: {
      type: [String, Array, Object],
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { variant, fade, icon, outline } = toRefs(props)
    const isVisible = ref(true)

    function handleDismissed() {
      emit('dismissed')
      isVisible.value = false
    }

    const alertClasses = computed(() => {
      function fadeClasses(): string[] {
        if (fade.value) {
          return ['fade-in']
        }
        return []
      }
      function borderClasses(): string[] {
        if (outline.value) {
          return ['tw-border', 'tw-border-solid']
        }
        return []
      }
      function variantClasses(): string[] {
        switch (variant.value) {
          case 'primary': {
            if (outline.value) {
              return ['tw-text-primary-500', 'tw-border-primary-200']
            }
            return ['tw-text-primary-500', 'tw-bg-primary-100']
          }
          case 'success': {
            if (outline.value) {
              return ['tw-text-green-500', 'tw-border-green-200']
            }
            return ['tw-text-green-500', 'tw-bg-green-100']
          }
          case 'warning': {
            if (outline.value) {
              return ['tw-text-amber-500', 'tw-border-amber-200']
            }
            return ['tw-text-amber-500', 'tw-bg-yellow-100']
          }
          case 'danger': {
            if (outline.value) {
              return ['tw-text-red-400', 'tw-border-red-200']
            }
            return ['tw-text-red-400', 'tw-bg-red-100']
          }
          case 'secondary':
          case 'light':
          case 'neutral': {
            if (outline.value) {
              return ['tw-text-grey-600', 'tw-border-grey-400']
            }
            return ['tw-text-grey-600', 'tw-bg-grey-200']
          }
          case 'info':
          default: {
            if (outline.value) {
              return ['tw-text-blue-500', 'tw-border-blue-200']
            }
            return ['tw-text-blue-500', 'tw-bg-blue-100']
          }
        }
      }

      return [...fadeClasses(), ...variantClasses(), ...borderClasses()]
    })

    const closeButtonHoverClasses = computed(() => {
      function variantClasses(): string[] {
        switch (variant.value) {
          case 'primary': {
            return ['hover:tw-text-primary-900']
          }
          case 'success': {
            return ['hover:tw-text-green-900']
          }
          case 'warning': {
            return ['hover:tw-text-amber-900']
          }
          case 'danger': {
            return ['hover:tw-text-red-800']
          }
          case 'neutral': {
            return ['hover:tw-text-grey-900']
          }
          case 'info':
          default: {
            return ['hover:tw-text-blue-900']
          }
        }
      }
      return [...variantClasses()]
    })

    const defaultIcon = computed(() => {
      switch (variant.value) {
        case 'warning': {
          return outline.value
            ? ciExclamationTriangle
            : ciExclamationTriangleRegular
        }
        case 'success': {
          return outline.value ? ciCheckCircle : ciCheckCircleRegular
        }
        case 'danger': {
          return outline.value ? ciTimesCircle : ciTimesCircleRegular
        }
        case 'neutral': {
          return outline.value
            ? ciExclamationCircle
            : ciExclamationCircleRegular
        }
        case 'info':
        case 'primary':
        default: {
          return outline.value ? ciInfoCircle : ciInfoCircleRegular
        }
      }
    })

    const internalIcon = computed(() => {
      if (icon.value === false) {
        return null
      } else if (icon.value === true) {
        return defaultIcon.value
      }
      return icon.value
    })

    return {
      ciTimes,
      handleDismissed,
      isVisible,
      alertClasses,
      closeButtonHoverClasses,
      internalIcon
    }
  }
})
