






import { ErrorPageError } from '~/models/error'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    error: {
      type: Object as PropType<ErrorPageError>,
      required: true
    }
  }
})
