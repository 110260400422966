import { onCLS, onFID, onLCP, onINP, Metric } from 'web-vitals'
import { ONE_MINUTE_MS } from '~/constants/duration'
import { getRandomNumberBetween } from '~/utils/number'

const baseUrl = process.env.BACKEND_API_BASE_URL_FROM_CLIENT || '/'
const cwvReportingInterval = ONE_MINUTE_MS

type PageName = 'index' | 'quick-search' | 'search' | 'view' | ''

export default ({ store, route }: any) => {
  // Do not report CWV for 40% of the users
  const dice = getRandomNumberBetween(1, 100)
  if (dice > 60) {
    return
  }

  const queue = new Set<Metric>()

  function getCurrentPageName(): PageName {
    if (!route.name) {
      return ''
    }

    if (route.path === '/') {
      return 'index'
    }

    if (
      route.name.startsWith('__quick_search') ||
      route.name.startsWith('__classifieds_quick_search')
    ) {
      return 'quick-search'
    }

    if (route.name.startsWith('__classifieds_search')) {
      return 'search'
    }

    if (route.name.startsWith('__classifieds_view')) {
      return 'view'
    }

    return ''
  }

  function sendData() {
    if (!queue.size) {
      return
    }

    const body = JSON.stringify(
      [...queue].map(m => ({
        name: m.name,
        value: m.value,
        rating: m.rating,
        id: m.id,
        delta: m.delta,
        navigation_type: m.navigationType,
        user_type: store.state.user.type,
        path: route.path,
        route: route.name,
        page: getCurrentPageName(),
        site: 'car'
      }))
    )

    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    if (navigator.sendBeacon) {
      navigator.sendBeacon(`${baseUrl}api/cwv/`, body)
    } else {
      fetch(`${baseUrl}api/cwv/`, {
        body,
        method: 'POST',
        keepalive: true
      })
    }
  }

  function addToQueue(metric: Metric) {
    queue.add(metric)
  }

  function flushQueue() {
    sendData()
    queue.clear()
  }

  // Report all available metrics whenever the page is backgrounded or unloaded.
  addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushQueue()
    }
  })

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded.
  addEventListener('pagehide', flushQueue)

  // Besides page unloading, we also flush the queue based on an interval to also
  // make sure it does not grow too large
  setInterval(flushQueue, cwvReportingInterval)

  onCLS(addToQueue)
  onFID(addToQueue)
  onLCP(addToQueue)
  onINP(addToQueue)
}
