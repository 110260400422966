import {
  FIELD_COLUMNS_FOURTH_DESKTOP_FULL_TABLET,
  FIELD_COLUMNS_THIRD_DESKTOP_HALF_MOBILE,
  FIELD_FOURTH_DESKTOP_FULL_TABLET,
  FIELD_FOURTH_DESKTOP_HALF_TABLET,
  FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
  FIELD_FOURTH_WIDTH_COLUMNS,
  FIELD_FULL_WIDTH_COLUMNS,
  FIELD_HALF_WIDTH_COLUMNS,
  FIELD_THIRD_DESKTOP_FULL_TABLET,
  FIELD_THIRD_DESKTOP_HALF_TABLET_FULL_MOBILE,
  FIELD_THIRD_WIDTH_COLUMNS,
  FIELD_TWO_THIRDS_WIDTH_COLUMNS,
  FIELD_TWO_THIRDS_DESKTOP_FULL_TABLET
} from '~/constants/form/columns'
import { FormConfig } from '~/models/classified/form'

export const JOBS_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      title: FIELD_FULL_WIDTH_COLUMNS,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_FULL_WIDTH_COLUMNS,
      salary: FIELD_HALF_WIDTH_COLUMNS,
      payment: FIELD_HALF_WIDTH_COLUMNS,
      job_type: FIELD_HALF_WIDTH_COLUMNS,
      tags: FIELD_HALF_WIDTH_COLUMNS,
      external_link: FIELD_HALF_WIDTH_COLUMNS,
      years_of_experience: FIELD_HALF_WIDTH_COLUMNS,
      photos2: FIELD_FULL_WIDTH_COLUMNS
    }
  }
})

export const VEHICLES_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      price_debatable: FIELD_HALF_WIDTH_COLUMNS,
      fuel_type: FIELD_THIRD_WIDTH_COLUMNS,
      fueltype: FIELD_THIRD_WIDTH_COLUMNS,
      console: FIELD_HALF_WIDTH_COLUMNS,
      type: FIELD_HALF_WIDTH_COLUMNS,
      engine_size: FIELD_THIRD_WIDTH_COLUMNS,
      engine_power: FIELD_THIRD_WIDTH_COLUMNS,
      gearbox_type: FIELD_THIRD_DESKTOP_HALF_TABLET_FULL_MOBILE,
      drive_type: FIELD_HALF_WIDTH_COLUMNS,
      emissions_co2: FIELD_THIRD_WIDTH_COLUMNS,
      euroclass: FIELD_HALF_WIDTH_COLUMNS,
      circulation_tax: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      fuel_efficiency_city: FIELD_THIRD_WIDTH_COLUMNS,
      fuel_efficiency_motorway: FIELD_THIRD_WIDTH_COLUMNS,
      fuel_efficiency_mixed: FIELD_THIRD_WIDTH_COLUMNS,
      battery_capacity: FIELD_HALF_WIDTH_COLUMNS,
      battery_range: FIELD_HALF_WIDTH_COLUMNS,
      battery_quick_charge_time: FIELD_HALF_WIDTH_COLUMNS,
      battery_charge_time: FIELD_HALF_WIDTH_COLUMNS,
      maximum_capacity: FIELD_HALF_WIDTH_COLUMNS,
      maximum_lift_height: FIELD_HALF_WIDTH_COLUMNS,
      height: FIELD_THIRD_WIDTH_COLUMNS,
      size: FIELD_THIRD_WIDTH_COLUMNS,
      registration: FIELD_THIRD_WIDTH_COLUMNS,
      mileage: FIELD_THIRD_WIDTH_COLUMNS,
      crashed: FIELD_THIRD_WIDTH_COLUMNS,
      offertype: FIELD_HALF_WIDTH_COLUMNS,
      condition: FIELD_HALF_WIDTH_COLUMNS,
      previous_owners: FIELD_FOURTH_DESKTOP_HALF_TABLET,
      kteo: FIELD_HALF_WIDTH_COLUMNS,
      price: FIELD_HALF_WIDTH_COLUMNS,
      price_parked: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      offers_enabled: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      home_delivery: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      visibility: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      multichild_price: FIELD_HALF_WIDTH_COLUMNS,
      doors: FIELD_COLUMNS_THIRD_DESKTOP_HALF_MOBILE,
      weight: FIELD_HALF_WIDTH_COLUMNS,
      payload: FIELD_HALF_WIDTH_COLUMNS,
      operating_weight: FIELD_THIRD_WIDTH_COLUMNS,
      exterior_color: FIELD_FULL_WIDTH_COLUMNS,
      interior_color: FIELD_FULL_WIDTH_COLUMNS,
      interior_type: FIELD_THIRD_WIDTH_COLUMNS,
      rim_size: FIELD_COLUMNS_THIRD_DESKTOP_HALF_MOBILE,
      axles: FIELD_THIRD_WIDTH_COLUMNS,
      airbags: FIELD_COLUMNS_THIRD_DESKTOP_HALF_MOBILE,
      body_material: FIELD_THIRD_WIDTH_COLUMNS,
      engine_type: FIELD_THIRD_WIDTH_COLUMNS,
      fuel_consumption: FIELD_THIRD_WIDTH_COLUMNS,
      seats: FIELD_COLUMNS_THIRD_DESKTOP_HALF_MOBILE,
      length: FIELD_THIRD_WIDTH_COLUMNS,
      cabins: FIELD_THIRD_WIDTH_COLUMNS,
      number_plate_ending: FIELD_FOURTH_DESKTOP_HALF_TABLET,
      toilets: FIELD_THIRD_WIDTH_COLUMNS,
      beds: FIELD_THIRD_WIDTH_COLUMNS,
      frame_size: FIELD_HALF_WIDTH_COLUMNS,
      brakes_type: FIELD_THIRD_WIDTH_COLUMNS,
      gears: FIELD_THIRD_WIDTH_COLUMNS,
      metallic: FIELD_THIRD_DESKTOP_FULL_TABLET,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_THIRD_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_TWO_THIRDS_WIDTH_COLUMNS,
      variant: FIELD_HALF_WIDTH_COLUMNS,
      model_variant: FIELD_HALF_WIDTH_COLUMNS,
      wholesale_price: FIELD_FOURTH_WIDTH_COLUMNS,
      finance: FIELD_FOURTH_WIDTH_COLUMNS
    }
  }
})

export const CARS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      wholesale_price: FIELD_HALF_WIDTH_COLUMNS,
      finance: FIELD_HALF_WIDTH_COLUMNS,
      metallic: FIELD_THIRD_WIDTH_COLUMNS,
      previous_owners: FIELD_FOURTH_DESKTOP_HALF_TABLET,
      kteo: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      drive_type: FIELD_THIRD_DESKTOP_HALF_TABLET_FULL_MOBILE,
      euroclass: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})

export const BIKES_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      engine_size: FIELD_FOURTH_DESKTOP_HALF_TABLET,
      fuel_type: FIELD_COLUMNS_FOURTH_DESKTOP_FULL_TABLET,
      fueltype: FIELD_COLUMNS_FOURTH_DESKTOP_FULL_TABLET,
      engine_power: FIELD_FOURTH_DESKTOP_HALF_TABLET,
      gearbox_type: FIELD_FOURTH_DESKTOP_HALF_TABLET,
      drive_type: FIELD_THIRD_DESKTOP_HALF_TABLET_FULL_MOBILE,
      euroclass: FIELD_FOURTH_DESKTOP_FULL_TABLET,
      previous_owners: FIELD_THIRD_DESKTOP_FULL_TABLET,
      kteo: FIELD_THIRD_DESKTOP_FULL_TABLET,
      circulation_tax: FIELD_THIRD_DESKTOP_FULL_TABLET
    }
  }
})

export const BOATS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      variant: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})
export const BICYCLES_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      crashed: FIELD_THIRD_WIDTH_COLUMNS,
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      rim_size: FIELD_HALF_WIDTH_COLUMNS,
      variant: FIELD_HALF_WIDTH_COLUMNS,
      makemodel: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const VANS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      fuel_type: FIELD_HALF_WIDTH_COLUMNS,
      engine_size: FIELD_HALF_WIDTH_COLUMNS,
      engine_power: FIELD_HALF_WIDTH_COLUMNS,
      seats: FIELD_HALF_WIDTH_COLUMNS,
      airbags: FIELD_HALF_WIDTH_COLUMNS,
      battery_range: FIELD_HALF_WIDTH_COLUMNS,
      previous_owners: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const TRUCKS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      metallic: FIELD_THIRD_WIDTH_COLUMNS,
      axles: FIELD_THIRD_WIDTH_COLUMNS,
      airbags: FIELD_THIRD_WIDTH_COLUMNS,
      previous_owners: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const BUSES_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      gearbox_type: FIELD_HALF_WIDTH_COLUMNS,
      seats: FIELD_THIRD_WIDTH_COLUMNS,
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      kteo: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})
export const BUILDERS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      variant: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})
export const SEMITRAILERS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      registration: FIELD_HALF_WIDTH_COLUMNS,
      crashed: FIELD_HALF_WIDTH_COLUMNS,
      axles: FIELD_HALF_WIDTH_COLUMNS,
      length: FIELD_HALF_WIDTH_COLUMNS,
      previous_owners: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})
export const SEMITRUCKS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      weight: FIELD_THIRD_WIDTH_COLUMNS,
      previous_owners: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})
export const TRACTORS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})
export const FORKLIFTS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      variant: FIELD_THIRD_WIDTH_COLUMNS,
      maximum_capacity: FIELD_THIRD_WIDTH_COLUMNS,
      maximum_lift_height: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})

export const TRAILERS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      registration: FIELD_HALF_WIDTH_COLUMNS,
      crashed: FIELD_HALF_WIDTH_COLUMNS,
      axles: FIELD_HALF_WIDTH_COLUMNS,
      length: FIELD_HALF_WIDTH_COLUMNS,
      previous_owners: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})
export const TAXIS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      wholesale_price: FIELD_HALF_WIDTH_COLUMNS,
      finance: FIELD_HALF_WIDTH_COLUMNS,
      metallic: FIELD_THIRD_WIDTH_COLUMNS,
      drive_type: FIELD_THIRD_DESKTOP_HALF_TABLET_FULL_MOBILE,
      euroclass: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})

export const MOTORHOMES_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      metallic: FIELD_THIRD_WIDTH_COLUMNS,
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      kteo: FIELD_THIRD_WIDTH_COLUMNS,
      number_plate_ending: FIELD_THIRD_WIDTH_COLUMNS,
      weight: FIELD_THIRD_WIDTH_COLUMNS,
      axles: FIELD_COLUMNS_THIRD_DESKTOP_HALF_MOBILE,
      beds: FIELD_COLUMNS_THIRD_DESKTOP_HALF_MOBILE
    }
  }
})

export const CARAVANS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      registration: FIELD_HALF_WIDTH_COLUMNS,
      crashed: FIELD_HALF_WIDTH_COLUMNS,
      metallic: FIELD_THIRD_WIDTH_COLUMNS,
      axles: FIELD_THIRD_WIDTH_COLUMNS,
      beds: FIELD_THIRD_WIDTH_COLUMNS,
      weight: FIELD_THIRD_WIDTH_COLUMNS,
      previous_owners: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})
export const RADIOCONTROLS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      makemodel: FIELD_HALF_WIDTH_COLUMNS,
      variant: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})
export const GOKARTS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      variant: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})

export const WATERSPORTS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      variant: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})

export const SNOWSPORTS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      variant: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})

export const AIRSPORTS_FORM_CONFIG_EXTENSION: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      previous_owners: FIELD_THIRD_WIDTH_COLUMNS,
      variant: FIELD_THIRD_WIDTH_COLUMNS
    }
  }
})

export const PARTS_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      makemodel: FIELD_FULL_WIDTH_COLUMNS,
      part_number: FIELD_THIRD_WIDTH_COLUMNS,
      after_market_number: FIELD_THIRD_WIDTH_COLUMNS,
      external_id: FIELD_THIRD_WIDTH_COLUMNS,
      condition: FIELD_HALF_WIDTH_COLUMNS,
      condition_type: FIELD_HALF_WIDTH_COLUMNS,
      offertype: FIELD_HALF_WIDTH_COLUMNS,
      damaged: FIELD_HALF_WIDTH_COLUMNS,
      wholesale_price: FIELD_HALF_WIDTH_COLUMNS,
      visibility: FIELD_HALF_WIDTH_COLUMNS,
      price: FIELD_HALF_WIDTH_COLUMNS,
      price_debatable: FIELD_HALF_WIDTH_COLUMNS,
      price_parked: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      offers_enabled: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      external_link: FIELD_HALF_WIDTH_COLUMNS,
      tags: FIELD_HALF_WIDTH_COLUMNS,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_HALF_WIDTH_COLUMNS,
      sex: FIELD_HALF_WIDTH_COLUMNS,
      microchip: FIELD_HALF_WIDTH_COLUMNS,
      rim_material: FIELD_THIRD_WIDTH_COLUMNS,
      rim_diameter: FIELD_THIRD_WIDTH_COLUMNS,
      rim_width: FIELD_THIRD_WIDTH_COLUMNS,
      bolts: FIELD_THIRD_WIDTH_COLUMNS,
      wholes_distance: FIELD_THIRD_WIDTH_COLUMNS,
      et_offset: FIELD_THIRD_WIDTH_COLUMNS,
      dealer_licence_number: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const XYMA_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      condition: FIELD_HALF_WIDTH_COLUMNS,
      condition_type: FIELD_HALF_WIDTH_COLUMNS,
      offertype: FIELD_HALF_WIDTH_COLUMNS,
      title: FIELD_TWO_THIRDS_DESKTOP_FULL_TABLET,
      damaged: FIELD_THIRD_DESKTOP_FULL_TABLET,
      makemodel: FIELD_TWO_THIRDS_DESKTOP_FULL_TABLET,
      external_id: FIELD_THIRD_DESKTOP_FULL_TABLET,
      external_link: FIELD_HALF_WIDTH_COLUMNS,
      tags: FIELD_HALF_WIDTH_COLUMNS,
      wholesale_price: FIELD_HALF_WIDTH_COLUMNS,
      visibility: FIELD_HALF_WIDTH_COLUMNS,
      price_parked: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      offers_enabled: FIELD_FOURTH_DESKTOP_HALF_TABLET_FULL_MOBILE,
      price: FIELD_HALF_WIDTH_COLUMNS,
      price_debatable: FIELD_HALF_WIDTH_COLUMNS,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_HALF_WIDTH_COLUMNS,
      part_number: FIELD_HALF_WIDTH_COLUMNS,
      sex: FIELD_HALF_WIDTH_COLUMNS,
      microchip: FIELD_HALF_WIDTH_COLUMNS,
      dealer_licence_number: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const RENTALS_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      registration: FIELD_THIRD_WIDTH_COLUMNS,
      engine_size: FIELD_THIRD_WIDTH_COLUMNS,
      engine_power: FIELD_THIRD_WIDTH_COLUMNS,
      fuel_type: FIELD_THIRD_WIDTH_COLUMNS,
      gearbox_type: FIELD_THIRD_WIDTH_COLUMNS,
      doors: FIELD_THIRD_WIDTH_COLUMNS,
      boot_capacity: FIELD_HALF_WIDTH_COLUMNS,
      passengers: FIELD_THIRD_WIDTH_COLUMNS,
      length: FIELD_THIRD_WIDTH_COLUMNS,
      max_mileage: FIELD_THIRD_WIDTH_COLUMNS,
      minimum_drivers_licence_years: FIELD_THIRD_WIDTH_COLUMNS,
      minimum_age_of_driver: FIELD_THIRD_WIDTH_COLUMNS,
      visibility: FIELD_THIRD_WIDTH_COLUMNS,
      minimum_rental_days: FIELD_THIRD_WIDTH_COLUMNS,
      deposit_guarantee: FIELD_THIRD_WIDTH_COLUMNS,
      battery_range: FIELD_HALF_WIDTH_COLUMNS,
      battery_charge_time: FIELD_THIRD_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      location2: FIELD_FULL_WIDTH_COLUMNS,
      owners_name: FIELD_HALF_WIDTH_COLUMNS,
      beds: FIELD_THIRD_WIDTH_COLUMNS,
      makemodel: FIELD_FULL_WIDTH_COLUMNS,
      variant: FIELD_FULL_WIDTH_COLUMNS,
      offers_enabled: FIELD_HALF_WIDTH_COLUMNS,
      model_variant: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const PLOT_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      address: FIELD_HALF_WIDTH_COLUMNS,
      postcode: FIELD_HALF_WIDTH_COLUMNS,
      location: FIELD_FULL_WIDTH_COLUMNS,
      price: FIELD_HALF_WIDTH_COLUMNS,
      area: FIELD_HALF_WIDTH_COLUMNS,
      residential_zone: FIELD_HALF_WIDTH_COLUMNS,
      bedrooms: FIELD_HALF_WIDTH_COLUMNS,
      year_of_construction: FIELD_HALF_WIDTH_COLUMNS,
      available_from: FIELD_HALF_WIDTH_COLUMNS,
      heat_medium: FIELD_HALF_WIDTH_COLUMNS,
      type_of_heat: FIELD_HALF_WIDTH_COLUMNS,
      energy_class: FIELD_HALF_WIDTH_COLUMNS,
      bathrooms: FIELD_HALF_WIDTH_COLUMNS,
      livingrooms: FIELD_HALF_WIDTH_COLUMNS,
      kitchens: FIELD_HALF_WIDTH_COLUMNS,
      agent_classified_code: FIELD_HALF_WIDTH_COLUMNS,
      code: FIELD_HALF_WIDTH_COLUMNS,
      max_distance_from_location: FIELD_HALF_WIDTH_COLUMNS,
      facade_length: FIELD_HALF_WIDTH_COLUMNS,
      spaces: FIELD_HALF_WIDTH_COLUMNS,
      floor: FIELD_HALF_WIDTH_COLUMNS,
      guests: FIELD_HALF_WIDTH_COLUMNS,
      extra_telephone: FIELD_HALF_WIDTH_COLUMNS,
      extra_name: FIELD_HALF_WIDTH_COLUMNS,
      real_owners_name: FIELD_HALF_WIDTH_COLUMNS,
      owners_telephone: FIELD_HALF_WIDTH_COLUMNS,
      telephone1: FIELD_HALF_WIDTH_COLUMNS,
      telephone2: FIELD_HALF_WIDTH_COLUMNS,
      email: FIELD_HALF_WIDTH_COLUMNS,
      password: FIELD_HALF_WIDTH_COLUMNS,
      minimum_rental_days: FIELD_HALF_WIDTH_COLUMNS,
      offers_enabled: FIELD_HALF_WIDTH_COLUMNS
    }
  }
})

export const WANTED_PARTS_FORM_CONFIG: FormConfig = Object.freeze({
  settings: {
    fieldColumns: {
      title: FIELD_FULL_WIDTH_COLUMNS,
      vin_number: FIELD_HALF_WIDTH_COLUMNS,
      part_number: FIELD_HALF_WIDTH_COLUMNS,
      after_market_number: FIELD_HALF_WIDTH_COLUMNS,
      user: FIELD_FULL_WIDTH_COLUMNS,
      category: FIELD_FULL_WIDTH_COLUMNS,
      description: FIELD_FULL_WIDTH_COLUMNS,
      location2: FIELD_FULL_WIDTH_COLUMNS,
      for_makemodels: FIELD_FULL_WIDTH_COLUMNS,
      condition: FIELD_HALF_WIDTH_COLUMNS,
      condition_type: FIELD_HALF_WIDTH_COLUMNS,
      photos: FIELD_FULL_WIDTH_COLUMNS,
      shorts: FIELD_FULL_WIDTH_COLUMNS,
      notes: FIELD_FULL_WIDTH_COLUMNS
    }
  }
})
