import { containerScoped } from '~/decorators/dependency-container'
import { inject } from 'tsyringe'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { toCamelCase } from '~/utils/object'
import {
  TradeClassifiedsResult,
  TradeDeleteResult,
  TradesCategory,
  TradesClassifiedDetailsResult,
  TradesLikedRow,
  TradesMatches,
  TradesNotifications,
  TradesRecommendations,
  TradesRowClassified,
  TradesSettings,
  TradesMatchRow
} from '~/models/trades/types'
import SnackbarService from '~/services/snackbar/SnackbarService'
import { HttpStatus } from '~/constants/http'
import { Pagination } from '~/models/search/types'
import { VueI18n } from '~/utils/nuxt3-migration'
import { vueI18nToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class TradesService {
  constructor(
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(vueI18nToken) private i18n: VueI18n,
    @inject(SnackbarService) private snackbar: SnackbarService
  ) {}

  fetchRecommendations(classified: string): Promise<TradesRecommendations> {
    return this.requestBuilder
      .request('get', `/api/trades/${classified}/recs/`)
      .map(body => {
        return toCamelCase({
          classified: body.data.classified,
          tradable_classified_count: body.data.tradable_classified_count,
          ...body.data.results
        })
      })
      .send()
  }

  fetchClassifiedDetails(
    targetClassifiedId: string,
    myClassifiedId?: string
  ): Promise<TradesClassifiedDetailsResult> {
    return this.requestBuilder
      .request('get', `/api/trades/${targetClassifiedId}/info/`)
      .params({ classified_id: myClassifiedId })
      .map(body => {
        return toCamelCase(body.data)
      })
      .send()
  }

  fetchCategories(queryParams: string) {
    return this.requestBuilder
      .request('get', `/api/trades/categories/${queryParams || ''}`)
      .map(body => {
        return toCamelCase(body.data.categories)
      })
      .send()
  }

  fetchTradableClassifieds(): Promise<TradeClassifiedsResult> {
    return this.requestBuilder
      .request('get', '/api/trades/')
      .map(body => {
        return toCamelCase(body.data)
      })
      .send()
  }

  fetchMatches(classified: string, page: number): Promise<TradesMatches> {
    return this.requestBuilder
      .request('get', `/api/trades/${classified}/matches/`)
      .params({ pg: page })
      .map(body => {
        const results = toCamelCase(body.data.results)
        const rows = (results.rows as Array<TradesMatchRow>).map(r => ({
          message: r.message,
          classified: this.setThumbSize(r.classified, 'k')
        }))

        return {
          pagination: results.pagination,
          rows
        }
      })
      .send()
  }

  fetchThread(threadId: number | string): Promise<any> {
    // TODO: move this function to messages service when refactor happens
    return this.requestBuilder
      .request('get', `/api/account/messages/${threadId}/`)
      .params({ pg: 1 })
      .validate(body => body?.data?.thread)
      .map(body => body?.data?.thread)
      .send()
  }

  readThread(threadId: number | string): Promise<any> {
    // TODO: move this function to messages service when refactor happens
    return this.requestBuilder
      .request('post', `/api/account/messages/${threadId}/read/`)
      .validate(body => body?.status && body.status === HttpStatus.OK)
      .map(body => body?.data?.message_ids)
      .send()
  }

  fetchLiked(
    classified: string,
    page: number
  ): Promise<{ pagination: Pagination; rows: Array<TradesLikedRow> }> {
    return this.requestBuilder
      .request('get', `/api/trades/${classified}/liked/`)
      .params({ pg: page })
      .map(body => {
        const results = toCamelCase(body.data.results)
        const rows = (results.rows as Array<TradesLikedRow>).map(r => ({
          classified: this.setThumbSize(r.classified, 'k')
        }))

        return {
          pagination: results.pagination,
          rows
        }
      })
      .send()
  }

  fetchLikedBy(
    classified: string,
    page: number
  ): Promise<{ pagination: Pagination; rows: Array<TradesLikedRow> }> {
    return this.requestBuilder
      .request('get', `/api/trades/${classified}/liked-by/`)
      .params({ pg: page })
      .map(body => {
        const results = toCamelCase(body.data.results)
        const rows = (results.rows as Array<TradesLikedRow>).map(r => ({
          classified: this.setThumbSize(r.classified, 'k')
        }))

        return {
          pagination: results.pagination,
          rows
        }
      })
      .send()
  }

  fetchSettings(classified: string): Promise<TradesSettings> {
    return this.requestBuilder
      .request('get', `/api/trades/${classified}/settings/`)
      .map(body => {
        const camelCaseData = toCamelCase(body.data)
        return {
          ...camelCaseData.tradesSettings,
          maxCategories: camelCaseData.maxCategories,
          onlyTrades: camelCaseData.onlyTrades,
          suggestedTrades: camelCaseData.suggestedTrades,
          classifieds: camelCaseData.classifieds,
          showTradesLink: camelCaseData.showTradesLink,
          tradesCategories: camelCaseData.tradesCategories
        }
      })
      .previousRequestCancelable()
      .send()
  }

  fetchNotifications(
    classified: string,
    params: string = ''
  ): Promise<TradesNotifications> {
    return this.requestBuilder
      .request('get', `/api/trades/${classified}/notifications/` + params)
      .map(body => {
        return toCamelCase(body.data.notifications)
      })
      .send()
  }

  likeClassified(classifiedId: string, likedClassified: number) {
    return this.requestBuilder
      .request('post', `/api/trades/${classifiedId}/like/${likedClassified}/`)
      .send()
  }

  passClassified(classifiedId: string, passedClassified: number) {
    return this.requestBuilder
      .request('post', `/api/trades/${classifiedId}/pass/${passedClassified}/`)
      .validate(body => body.message)
      .map(body => body.data)
      .send()
  }

  seveSettings(
    classifiedId: string,
    categories: Array<TradesCategory | number>,
    onlyTrades?: boolean
  ) {
    return this.requestBuilder
      .request('post', `/api/trades/${classifiedId}/settings/`)
      .validate(body => body.message)
      .data({
        categories,
        only_trades: onlyTrades
      })
      .map(body => {
        if (body.status === HttpStatus.OK) {
          this.snackbar.success(body.message)
        }
        return toCamelCase(body.data)
      })
      .send()
  }

  unmatched(classifiedA: number, classifiedB: number) {
    return this.requestBuilder
      .request('post', `/api/trades/${classifiedA}/unmatch/${classifiedB}/`)
      .validate(body => body.message)
      .map(body => {
        if (body.status === HttpStatus.OK) {
          this.snackbar.success(this.i18n.t('the classified was removed'))
        }
        return body.data
      })
      .send()
  }

  removeLikedBy(classifiedA: string, classifiedB: number) {
    return this.requestBuilder
      .request(
        'post',
        `/api/trades/${classifiedA}/remove-liked-by/${classifiedB}/`
      )
      .validate(body => body.message)
      .map(body => {
        if (body.status === HttpStatus.OK) {
          this.snackbar.success(this.i18n.t('the classified was removed'))
        }
        return body.data
      })
      .send()
  }

  removeTradableClassified(
    classified: number | string
  ): Promise<TradeDeleteResult> {
    return this.requestBuilder
      .request('delete', `/api/trades/${classified}/settings/`)
      .validate(body => body.status === HttpStatus.OK)
      .map(body => ({ message: body.message, status: body.status }))
      .send()
  }

  setThumbSize(
    classified: TradesRowClassified,
    size: string
  ): TradesRowClassified {
    const urls = classified?.thumbsPatterns?.urls || []
    return {
      ...classified,
      thumbsPatterns: {
        ...classified.thumbsPatterns,
        urls: urls.map((u: string) => u.replace('{size}', size))
      }
    }
  }
}
