import { Classified } from '~/models/classified/types'
import {
  NotificationEvent,
  NotificationType
} from '~/models/notification-center/types'
import { AccessToggle } from '~/models/user/access-toggles'
import {
  AuthUserSubscriptionInfo,
  AuthUserStripeSubscriptionInfo
} from '~/models/user/subscription'
import { Category, Pagination } from '~/models/search/types'
import { SubscriptionPlanName } from '~/services/logistics/StripeService'
import { QuickSearch } from '~/models/quick-search/types'
import { Postcode, Telephone } from '~/models/shared/types'
import { Geolocation } from '~/models/common/types'
import { SellerOnboardingStatus } from '~/models/marketplace/seller'
import { SchemaField } from '~/models/form/types'

export interface AuthUserResult {
  username: string
  name: string
  id: number
  avatar: string | null
  type: UserType
  credits?: UserCredits
  properties: string[]
  permissions: string[]
  location: object
  featureToggles: FeatureToggle[]
  referenceCode?: string
  isPayingUser: boolean
  accessToggles: AccessToggle[]
  subscription?: AuthUserSubscriptionInfo
  stripeSubscription?: AuthUserStripeSubscriptionInfo
  sdmu: boolean
  hasCompleteProfile?: boolean
  dealerIds?: number[]
  hasPublicDealers?: boolean
  externalIds?: object
  inHouseConfig?: any[]
  notificationRelayUrl: string
  firstName: string | null
  lastName: string | null
  email: string
  telephone: Telephone
  marketplaceIds?: AuthUserMarketplaceUserIds
}

export interface AuditRequestsCount {
  failed: number
  all: number
  completed: number
  pending: number
}

export interface RoqueRequirements {
  hasTelehone: string
  hasAlias: string
  hasPostcode: string
}
export interface UserExtrasResult {
  unreadNotifications: number
  unreadMessages: number
  unreadParkingNotifications: number
  unreadTradesNotifications: number
  unreadAnnouncements: number
  myClassifieds?: MyClassifiedsLink[]
  xmlIssues?: boolean | null
  hasTickets?: boolean
  invoicesCount?: number
  outletsCount?: number
  rogueClassifiedsCount?: number
  rogueRequirements?: RoqueRequirements
  auditRequestsCount?: AuditRequestsCount
  hasParked: boolean
  saleRequests?: AuthUserSaleRequestInfo
  marketplace?: UserMarketplaceInfo
  bulletins: UserBulletin[]
}

export interface AdminPageGroup {
  name: string
  pages: AdminPage[]
}

export interface AdminPage {
  name: string
  url: string
}

export interface AdminExtras {
  ticketsCount?: number
  reportCount?: number
  agentApplicationsCount?: number
  performance?: Object | null
  pages?: AdminPageGroup[]
  suas?: {
    goBack: {
      url: string
      label: string
    }
    realUser: {
      displayName: string
      id: number
    }
  }
  pendingAds?: number
}

export interface UserAndAdminExtrasResult {
  userExtras: UserExtrasResult
  adminExtrasData?: {
    adminExtras: AdminExtras
  }
}

export interface MyClassifiedsLink {
  count: number
  categoryName: string
  categoryIds: number[]
  seoUrl: string
  legacyVtype?: string | null
  selected: boolean
  type: string
  label: string
}

export interface StorageStateResult {
  state: object
}

export interface StorageStatePatchResult extends StorageStateResult {
  patched: boolean
}

export interface User {
  username?: string
  name?: string
  id?: number
  type?: UserType
  credits?: UserCredits
  properties: any
  permissions: string[]
  avatar: string | null
  unreadNotifications: number
  unreadMessages: number
  unreadParkingNotifications: number
  unreadTradesNotifications: number
  unreadAnnouncements: number
  auditRequestsCount?: AuditRequestsCount
  myClassifieds?: MyClassifiedsLink[]
  xmlIssues?: boolean | null
  hasTickets?: boolean
  invoicesCount?: number
  outletsCount?: number
  rogueClassifiedsCount?: number
  rogueRequirements?: RoqueRequirements
  hasPublicDealers?: boolean
  referenceCode?: string
  externalIds?: object
  isPayingUser?: boolean
  notificationsCenter: UserNotificationsCenter
  sdmu: boolean
  hasCompleteProfile?: boolean
  dealerIds?: number[]
  inHouseConfig?: any[]
  firstName: string | null
  lastName: string | null
  telephone: Telephone | null
  email: string | null
}

export interface UserNotificationsCenter {
  loading: boolean
  notifications: {
    pagination: Pagination
    rows: Array<NotificationEvent>
  }
  notificationTypes: Array<NotificationType>
}
export interface UserCredits {
  free: number
  paid: number
  freeDaily?: number
}

export enum UserType {
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  DEALER = 'Dealer',
  GUEST = 'Guest',
  SINGLE = 'SingleClassifiedUser',
  AGENT = 'Agent',
  ANON = 'anon',
  AUDIT_PROVIDER = 'AuditProvider',
  AUDIT_SHOP = 'AuditShop',
  AUDITOR = 'Auditor'
}

export enum Type {
  TOP_PICKS = 'top_picks'
}

export enum FeedTypes {
  RECENT = 'recent',
  RECENT_VIEWED = 'recent_viewed',
  LIST = 'classified_list',
  SEARCH = 'search',
  FAVORITES = 'favorites',
  MODELS = 'models_feed',
  TOP_PICKS = 'top_picks',
  CATEGORIES = 'categories_feed',
  YOUTUBE = 'youtube',
  ELECTRIC_VEHICLES = 'electric_vehicles_feed',
  USER_CLASSIFIEDS = 'user_classifieds',
  FAVORITE_USER_SEARCH = 'favorite_user_search',
  FAVORITE_CLASSIFIEDS = 'favorite_classifieds',
  RECENT_USER_SEARCH = 'recent_user_search',
  RECENT_VIEWED_CLASSIFIEDS = 'recent_viewed_classifieds',
  DEALS = 'deals',
  PLOT = 'plot',
  AGENTS_FEED = 'agents_feed',
  CUSTOM_LINK = 'custom_link'
}
export interface LandingResult {
  fetchableFeeds: FetchableFeed[]
  unreadMessages?: number
  parkingNotifications?: number
  quickSearches: QuickSearches
}

export interface QuickSearchesLocations {
  rent: QuickSearch[]
  sale: QuickSearch[]
}

export interface QuickSearches {
  locations: QuickSearchesLocations
}

export interface LandingClassifiedsCount {
  count: number
}

export interface FetchableFeed {
  fetchUrl: string
  type: FeedTypes
  refreshInterval: number | null
  feed?: Feed
  id?: number
  searchType?: string
}

export interface Feed {
  classifieds: Classified[]
  label: object
  type: FeedTypes
  seoUrl?: string
  count?: number
  categoryIds?: number[]
  hasMore?: boolean
}

export interface FeedCategory {
  targetUrl: string
  classifiedsCount: Number | null
  title: string
  image: string
  categoryIds: number[]
  id: number
  targetSearchArgs: TargetSearchArg[]
}

export interface FeedUser {
  classifiedsCount: number
  pageUrl: string
  managerId?: number | null
  address: string
  subscriptionTier: SubscriptionPlanName
  type: UserType
  id: number
  avatar: string | null
  name: string
}

export interface TargetSearchArg {
  name: string
  value: any
}

export interface FeedModel {
  targetUrl: string
  classifiedsCount: Number | null
  title: string
  image: string
  id: number
}

export interface FeedVideo {
  image: string
  targetUrl: string
  title: string
  description?: string
}
export interface CustomLinkEntry extends FeedVideo {}

export interface CategoryFeed {
  categories: FeedCategory[]
  label: object
  type: FeedTypes
  seoUrl: string
  count: number
  icon: string
}
export interface AgentsFeed {
  agents: FeedUser[]
  label: object
  type: FeedTypes
  seoUrl: string
}
export interface ModelsFeed {
  models: FeedModel[]
  label: object
  type: FeedTypes
  seoUrl: string
  categoryIds: number[]
}

export interface YoutubeFeed {
  videos: FeedVideo[]
  label: object
  type: FeedTypes
  seoUrl: string
}
export interface CustomLinkFeed {
  linkData: CustomLinkEntry[]
  type: FeedTypes
  label: object
  seoUrl: string
  icon?: string
}

export interface RecentButton {
  catId: number
  label: string
  active: boolean
}
export interface Verify {
  isVerified: Boolean
  telephone: {
    code: number
    dontShowToPublic: Boolean
    social: { whatsapp: string; viber: string }
    telephone: string
  }
}

export enum VerificationStateEnum {
  ENTER_PIN = 'ENTER_PIN',
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED'
}

export enum FeatureToggle {
  JOBS = 'jobs',
  SEARCHBAR = 'searchbar'
}

export type UserId = number | string

export interface UserBaseData {
  username: string
  type: UserType
  displayName: string
  id: number
  name: string
  classifiedsDeleted: number
  classifiedsNonDeleted: number
  created: string
  lastLogin: string
  activated: boolean
  deleted: boolean
  subscriptionTier: string | null
  subscriptionExpiration: string | null
  inchargeName: string | null
  dealersCount: number | null
  isBlacklisted: boolean
  blacklist: {
    byUser: string
    byUserId: number
    created: string
    groupId: number
    idents: string[]
    reason: {
      body: string
      id: number
      title: string
    }
    reasonId: number
    blacklistCategories: Category[]
  }
  badges: string[]
  classifiedsOverview: ClassifiedsOverview
  hasPublicDealers: boolean
  siteRegion: 'car' | 'plot'
  lastInvoiceInfo: {
    date: string
    amount: number
  } | null
  lastPaymentInfo: {
    date: string
    amount: number
  } | null
}

export interface ClassifiedsOverview {
  level0: {
    [key: string]: ClassifiedOverview
  }
  level1: {
    [key: string]: ClassifiedOverview
  }
}

export interface ClassifiedOverview {
  humanName: string
  url: string
  states: object
  inactive: number
  total: number
  active: number
}

export interface GuestUserData extends UserBaseData {
  activated: boolean
  alias: string
  allowEmailForParked: boolean
  bikesClassifiedLimit: number
  carsClassifiedLimit: number
  created: string
  dailyFreeCredits: number
  deleted: boolean
  firstName: string
  freeCredits: number
  inHouseAdsBalance: number
  lastLogin: string
  lastName: string
  notes: string
  paidCredits: number
  partsXymaClassifiedLimit: number
  postcode: Postcode
  realEstateClassifiedLimit: number
  rentalsClassifiedLimit: number
  telephone1: Telephone
  telephone2: Telephone
  username: string
  vehicleClassifiedLimit: number
}
export interface AgentUserData extends UserBaseData {
  activated: boolean
  address: string | null
  agentCode: string | null
  agentLoginExpiration: string
  classifiedsDeleted: number
  classifiedsMessage: string | null
  classifiedsNonDeleted: number
  created: string
  dailyFreeCredits: number
  dailyPaidCredits: number
  deleted: boolean
  description: string | null
  email: string
  facebook: string | null
  fax: Telephone | null
  freeCredits: number
  geolocation: Geolocation
  inchargeName: string | null
  instagram: string | null
  lastLogin: string
  name: string
  notes: string | null
  pagePath: string | null
  paidCredits: number
  postcode: Postcode
  realEstateClassifiedLimit: number
  slogan: string | null
  subscriptionExpiration: string | null
  subscriptionTier: string | null
  technicalEmail: string | null
  telephone1: Telephone
  telephone2: Telephone
  twitter: string | null
  username: string
  website: string | null
  workingHours: string | null
  youtube: string | null
  twoFactorAuthActive: boolean
}
export interface AuditProviderUserData extends UserBaseData {
  username: string
  notes?: string
  telephone2?: Telephone
  name: string
  telephone1?: Telephone
  deleted: boolean
  lastLogin: string
  created: string
}
export interface ManagerUserData extends UserBaseData {
  avatar: string
  username: string
  lastPayment: string
  daysPayed: number
  vehicleClassifiedLimit: number
  carsClassifiedLimit: number
  bikesClassifiedLimit: number
  partsXymaClassifiedLimit: number
  rentalsClassifiedLimit: number
  inHouseAdsBalance: number
  applyLimitsXml: boolean
  dailyLimitEnabled: boolean
  canImportUnclassifieds: boolean
  extraServiceRate: number
  notes: string
  paidCredits: number
  freeCredits: number
  dailyFreeCredits: number
  company: string
  name: string
  postcode: Postcode
  telephone1: Telephone
  address: string | null
  country: string | null
  telephone2: Telephone | null
  email: string | null
  website: string | null
  managerSubscriptionPackages: {
    price: {
      withVat: number | null
      withoutVat: number | null
    }
    vatPercentage: number
    months: number
  }[]
  twoFactorAuthActive: boolean
  managerExpiresDate: string
  managerExpiresDays: number
  managerInvoicesCount: number
  managerExtraServiceRateHistory: ExtraServiceRateHistory[]
  managerIdentHistory: IdentHistory[]
  managerOwnedDealers: OwnedDealer[]
  maximumMakesInParts: number
  maximumModelsInParts: number
  managerSubscriptionReport: {
    extraServiceCost: number
    basePrice: number
    changes: {
      reason: string
      oldPrice: number
      priceChange: number
      newPrice: number
    }[]
    description: string
    subscription: number
  }
}

export interface AdminUserData extends UserBaseData {
  username: string
  name: string
  email: string
  notes: string | null
  telephone2: Telephone | null
  telephone1: Telephone
  freeCredits: number
  inHouseAdsBalance: number
  isRetailUser: boolean
  dailyFreeCredits: number
  paidCredits: number
}

interface ExtraServiceRateHistory {
  count: number
  date: string
  labels: {
    user: string
  }
  oldValue: string | null
  value: string
}
export interface IdentHistory {
  active: boolean
  classifieds: {
    count: number
  }
  firstSeen: string
  ident: string
  lastSeen: string
  classifiedTelephone: boolean
}

export interface OwnedDealer {
  id: number
  username: string | null
  partsCount: number
  displayName: string
  name: string
  xymaCount: number
  webpage: string | null
  vehiclesCount: number
  displayPhoto: string
  type: string
  internalWebsites: string[]
}

export interface AdminUserFormDataRequest {
  values:
    | GuestUserData
    | AgentUserData
    | AuditProviderUserData
    | ManagerUserData
  schema: SchemaField[]
}

export interface AuthUserSaleRequestInfo {
  cost: number
  fetched: boolean
  hasItems: boolean
}

export interface AuthUserMarketplaceUserIds {
  sellerId: number
  buyerId: number
}

export interface UserMarketplaceBuyerInfo {
  ordersCount: number
  cartItemsCount: number
}

export interface UserMarketplaceSellerInfo {
  newOrdersCount: number
  balance: number
  id: number
  onboardingStatus: SellerOnboardingStatus
}

export interface UserMarketplaceInfo {
  buyer?: UserMarketplaceBuyerInfo
  seller?: UserMarketplaceSellerInfo
}

export interface UserBulletin {
  title: string
  extras: Record<string, any>
  tags: string[]
}
