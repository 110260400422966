import { PropOptions } from '~/utils/nuxt3-migration'
import { Merge } from 'type-fest'

export const mappingProp: PropOptions<CategoryMapping> = {
  type: Object,
  required: true
}

export const externalIdProp: PropOptions<CategoryMapping['externalId']> = {
  type: String,
  required: true
}

export const externalNameProp: PropOptions<CategoryMapping['externalName']> = {
  type: String,
  required: true
}

export const idProp: PropOptions<string> = {
  type: String,
  required: true
}

export const transitProp: PropOptions<boolean> = {
  type: Boolean,
  required: true
}

export interface CategoryMappingPageSettingPayload {
  pageNumber: number
  updateUrl: boolean
  scrollToTop: boolean
}

export interface CategoryMappingFilterTextSettingPayload {
  text: string
  resetPage: boolean
  updateUrl: boolean
}

export interface CategoryMapping {
  id: CategoryMappingId
  externalId: string
  externalName: string
  naReason: number | null
  naReasonMessage: string | null
  internalIdentifiers: InternalCategoryIdentifier[]
  classifiedTitles: string[]
}

export interface InternalCategoryIdentifier {
  id: number
  name: string
  options?: Partial<{
    fillAutocomplete: boolean
  }>
}

export type CategoryMappingId = string

export enum NaReason {
  CANNOT_BE_MAPPED,
  NOT_MAPPED_YET
}

export enum NaReasonNodeId {
  CANNOT_BE_MAPPED = -1000000
}

export type MappingSettingPayload = Merge<CategoryMapping, { index?: number }>

export interface TransitInternalCategoryCreationPayload {
  mappingId: string
  categoryIdentifier: InternalCategoryIdentifier
}

export interface TransitInternalCategoryDeletionPayload {
  mappingId: string
  categoryId: number
}

export type TransitInternalCategoryIdentifier = Merge<
  InternalCategoryIdentifier,
  Partial<{
    /**
     * Qualifier denoting non-persistence in db, meaning that this was created as a client js object but not saved.
     */
    transit: boolean
  }>
>

export interface CategoryUpsertEndpointInput {
  /* eslint-disable camelcase */
  car_id?: number
  car_id2?: number
  na_reason?: NaReason
}

export interface UnmappedCategoryScanError {
  [dealerName: string]: string
}
