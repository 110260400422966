
















































import { defineComponent } from '~/utils/nuxt3-migration'
import { PropType } from 'vue'
import { ciInfoCircle } from '~/icons/source/regular/info-circle'
import { Icon } from '~/models/icons/types'

export default defineComponent({
  props: {
    showFooter: {
      type: Boolean,
      default: false
    },
    modalSize: {
      type: String,
      default: 'md'
    },
    forceBlue: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 18
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: Object as PropType<Icon>,
      required: false,
      default: null
    },
    okText: {
      type: String,
      default() {
        return 'OK'
      }
    },
    bodyClass: {
      type: String,
      default: 'tw-pt-0'
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    defaultIcon() {
      return ciInfoCircle
    },
    iconToShow() {
      return this.icon || this.defaultIcon
    }
  }
})
