





import { timeDistanceFromNow } from '~/utils/date'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    originalDate: {
      type: [String, Number],
      required: true
    },
    addSuffix: {
      type: Boolean,
      required: false,
      default: false
    },
    addPrefix: {
      type: Boolean,
      required: false,
      default: false
    },
    strict: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      date: this.getTime(),
      interval: null
    }
  },
  created() {
    this.interval = setInterval(() => {
      this.date = this.getTime()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    getTime() {
      const settings = {
        locale: this.$i18n.locale || 'el',
        addSuffix: this.addSuffix,
        addPrefix: this.addPrefix,
        includeSeconds: true,
        strict: this.strict
      }

      const oneMinute = 60 * 1000 // ms

      if (new Date() - this.originalDate < oneMinute) {
        settings.strict = true
        settings.addSuffix = false
        settings.addPrefix = false
        settings.unit = 'second'
      }

      return timeDistanceFromNow(this.originalDate, settings)
    }
  }
})
