import { containerScoped } from '~/decorators/dependency-container'
import { Geolocation } from '~/models/common/types'
import { inject } from 'tsyringe'
import SnackbarService from '~/services/snackbar/SnackbarService'
import { VueI18n } from '~/utils/nuxt3-migration'
import { vueI18nToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
export class GeolocationService {
  constructor(
    @inject(SnackbarService) private snackbar: SnackbarService,
    @inject(vueI18nToken) private i18n: VueI18n
  ) {}

  public getCurrentGpsLocation(): Promise<Geolocation> {
    return new Promise((resolve, reject) => {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          })
        }, reject)
      } else reject(new Error('Can not find current gps lat and lng'))
    })
  }

  async getCurrentGpsLocationWithSnackbarOnError(): Promise<
    Geolocation | undefined
  > {
    try {
      return await this.getCurrentGpsLocation()
    } catch (error) {
      this.snackbar.error(this.i18n.t('failed to get gps location'))
    }
  }
}
