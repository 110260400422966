













































import { ciExternalLink } from '~/icons/source/regular/external-link'
import { defineComponent, vue3Model } from '~/utils/nuxt3-migration'

export default defineComponent({
  model: vue3Model,
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    displayUrl: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  computed: {
    icons: () => ({ externalLink: ciExternalLink }),
    urlIsPlot() {
      const { hostname } = new URL(this.url)
      return hostname === 'plot.gr' || hostname === 'www.plot.gr'
    },
    visible: {
      get() {
        return this.modelValue
      },
      set(value: boolean) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    close() {
      this.visible = false
    }
  }
})
