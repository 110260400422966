import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import {
  AccountOverview,
  AccountPasswordChangeForm,
  AgentSettingsForm,
  AgentSettingsPostResult,
  AgentSettingsSchemaResult,
  ApiTokenResult,
  GuestSettingsForm,
  GuestSettingsPostResult,
  GuestSettingsSchemaResult,
  GuestChangeEmailSchemaResult,
  GuestChangeEmailForm,
  AccountInvoices,
  AccountDealer,
  AccountSubscription,
  AccountCredits,
  ManagerUserSettingsForm,
  ManagerSettingsFetchResult
} from '~/models/account'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { VueI18n, VueRouter } from '~/utils/nuxt3-migration'
import { toSnakeCase } from '~/utils/snake-case'
import { HttpStatus } from '~/constants/http'
import { Pagination } from '~/models/search/types'
import { toCamelCase } from '~/utils/object'
import { format } from '~/utils/date'
import { SIMPLE_DATETIME_FORMAT } from '~/constants/date'
import UserAgentService from '~/services/UserAgentService'
import { Telephone } from '~/models/shared/types'
import { Schema } from '~/models/form/types'
import {
  vueI18nToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class AccountService {
  constructor(
    @inject(RequestBuilder) private requestBuilder: RequestBuilder,
    @inject(vueRouterToken) private router: VueRouter,
    @inject(vueI18nToken) private i18n: VueI18n,
    @inject(UserAgentService) private userAgentService: UserAgentService
  ) {}

  getAgentOrDealerOverview(): Promise<AccountOverview> {
    return this.requestBuilder
      .request('get', '/api/managers/overview')
      .validate(body => body && body.status)
      .send()
  }

  getGuestOverview(): Promise<AccountOverview> {
    return this.requestBuilder
      .request('get', '/api/guests/overview/')
      .validate(body => body && body.status)
      .send()
  }

  getAuditsUserOverview(): Promise<AccountOverview> {
    return this.requestBuilder
      .request('get', '/api/audits/panel/overview/')
      .validate(body => body && body.status)
      .send()
  }

  getStateTextForColor(color: string): string {
    switch (color) {
      case 'green':
        return this.i18n.t('the classified is ok').toString()
      case 'yellow':
        return this.i18n
          .t('the classified is getting old, you should renew it')
          .toString()
      case 'red':
        return `${this.i18n.t(
          "it's been a long time since you renewed the classified"
        )}. ${this.i18n.t(
          'renew now for a better position in search results'
        )}`.toString()
      default:
        return ''
    }
  }

  public getAgentsSettingsSchema(): Promise<AgentSettingsSchemaResult> {
    return this.requestBuilder
      .request('get', '/api/agents/settings/')
      .validate(body => body && body.data && body.data.schema)
      .send()
  }

  public submitAgentSettings(
    form: AgentSettingsForm
  ): Promise<AgentSettingsPostResult> {
    return this.requestBuilder
      .request('post', '/api/agents/settings/')
      .data(toSnakeCase(form))
      .send()
  }

  public getUserPasswordResetSchema(code: string): Promise<{ schema: Schema }> {
    return this.requestBuilder
      .request('get', '/api/users/password-change/')
      .params({ code })
      .send()
  }

  public submitUserPasswordReset(
    form: AccountPasswordChangeForm,
    code?: string
  ): Promise<{
    values: AccountPasswordChangeForm
  }> {
    return this.requestBuilder
      .request('post', '/api/users/password-change/')
      .data(toSnakeCase(form))
      .params({ code })
      .send()
  }

  public getManagerPasswordResetSchema(): Promise<{ schema: Schema }> {
    return this.requestBuilder
      .request('get', '/api/managers/password-change/')
      .send()
  }

  public submitManagerPasswordReset(
    form: AccountPasswordChangeForm,
    code?: string
  ): Promise<{
    values: AccountPasswordChangeForm
  }> {
    return this.requestBuilder
      .request('post', '/api/managers/password-change/')
      .data(toSnakeCase(form))
      .params({ code })
      .send()
  }

  public totalLogout() {
    return this.requestBuilder.request('post', '/api/total-logout/').send()
  }

  public adminTotalLogout(userId: string) {
    return this.requestBuilder
      .request('post', `/api/admin/users/${userId}/total-logout/`)
      .action()
      .send()
  }

  public getCurrentApiToken(): Promise<ApiTokenResult> {
    return this.requestBuilder
      .request('get', '/api/agents/public-api-info/')
      .validate(body => body && body.data)
      .send()
  }

  public getNewApiToken(): Promise<ApiTokenResult> {
    return this.requestBuilder
      .request('post', '/api/agents/public-api-info/')
      .send()
  }

  public getGuestSettingsSchema(): Promise<GuestSettingsSchemaResult> {
    return this.requestBuilder
      .request('get', '/api/guests/settings/')
      .validate(body => body && body.data && body.data.schema)
      .send()
  }

  public submitGuestSettings(
    form: GuestSettingsForm
  ): Promise<GuestSettingsPostResult> {
    return this.requestBuilder
      .request('post', '/api/guests/settings/')
      .data(toSnakeCase(form))
      .send()
  }

  public deleteGuestAccount(): Promise<GuestSettingsPostResult> {
    return this.requestBuilder
      .request('delete', '/api/guests/delete-account/')
      .validate(body => body && body.status === HttpStatus.OK)
      .send()
  }

  public fetchEmailData(): Promise<GuestChangeEmailSchemaResult> {
    return this.requestBuilder
      .request('get', '/api/users/email-change/')
      .validate(body => body && body.status === HttpStatus.OK)
      .send()
  }

  public submitNewEmail(
    form: GuestChangeEmailForm
  ): Promise<GuestChangeEmailSchemaResult> {
    return this.requestBuilder
      .request('post', '/api/users/email-change/')
      .data(toSnakeCase(form))
      .send()
  }

  public activateNewEmail(token: string): Promise<{ message: string }> {
    return this.requestBuilder
      .request('get', `/api/users/email-activation/${token}`)
      .send()
  }

  public fetchManagerSettings(): Promise<ManagerSettingsFetchResult> {
    return this.requestBuilder
      .request('get', '/api/managers/settings/')
      .validate(body => body && body?.data?.user && body?.data?.schema)
      .send()
  }

  public fetchTwoFA(): Promise<{ qr: string | null; uri: string | null }> {
    return this.requestBuilder.request('get', '/api/users/2fa/').send()
  }

  public deactivateTwoFA(oneTimePassword?: string): Promise<void> {
    return this.requestBuilder
      .request('delete', '/api/users/2fa/')
      .validate(body => body)
      .data({ one_time_password: oneTimePassword })
      .map(body => body)
      .send()
  }

  public adminDeactivateTwoFA(userId: string): Promise<void> {
    return this.requestBuilder
      .request('delete', '/api/users/2fa/' + userId)
      .send()
  }

  public activateTwoFA({
    securityTelephone
  }: {
    securityTelephone: Telephone
  }): Promise<{ qr: string | null; uri: string | null }> {
    return this.requestBuilder
      .request('post', `/api/users/2fa/`)
      .data({ security_phone: securityTelephone, new: true })
      .send()
  }

  public getTwoFaQrCode(
    oneTimePassword: string
  ): Promise<{ qr: string | null; uri: string | null }> {
    return this.requestBuilder
      .request('post', `/api/users/2fa/`)
      .data({ one_time_password: oneTimePassword, new: false })
      .send()
  }

  public submitManagerSettings(
    form: ManagerUserSettingsForm
  ): Promise<GuestSettingsPostResult> {
    return this.requestBuilder
      .request('post', '/api/managers/settings/')
      .data(toSnakeCase(form))
      .validate(body => body?.data?.values)
      .send()
  }

  public fetchManagerInvoices(
    pg: number
  ): Promise<{
    pagination: Pagination
    rows: Array<AccountInvoices>
  }> {
    const { query } = this.router.currentRoute
    let mock = null
    if (query.mock) {
      mock = 1
    }
    return this.requestBuilder
      .request('get', '/api/managers/invoices')
      .validate(body => {
        return body && body?.data?.invoices
      })
      .map(body => {
        const data = toCamelCase(body.data.invoices)

        return {
          pagination: data.pagination,
          rows: data.rows.map((r: AccountInvoices) => ({
            ...r,
            created: format(r.created, SIMPLE_DATETIME_FORMAT)
          }))
        }
      })
      .params({ mock, pg })
      .send()
  }

  fetchManagerShops(): Promise<AccountDealer[]> {
    const { query } = this.router.currentRoute
    let mock = null
    if (query.mock) {
      mock = 1
    }
    return this.requestBuilder
      .request('get', '/api/managers/dealers/')
      .validate(body => {
        return body?.data?.dealers
      })
      .params({ mock })
      .map(body => toCamelCase(body.data.dealers))
      .send()
  }

  fetchManagerSubscriptions(): Promise<AccountSubscription> {
    const { query } = this.router.currentRoute
    let mock = null
    if (query.mock) {
      mock = 1
    }
    return this.requestBuilder
      .request('get', '/api/managers/subscription')
      .validate(body => {
        return (
          body?.data?.plans &&
          body?.data?.active_subscription &&
          body?.data?.invoicing_info &&
          body?.data?.car_bank_accounts
        )
      })
      .params({ mock })
      .map(body => {
        return toCamelCase({
          ...body.data,
          plans: body.data.plans.map((p: { months: string; name: string }) => ({
            ...p,
            months: p.name.split(' ')[0]
          })),
          activeSubscription: {
            expires: format(
              body.data.active_subscription.expires,
              SIMPLE_DATETIME_FORMAT
            )
          }
        })
      })
      .send()
  }

  fetchManagerCreditsOptions(): Promise<AccountCredits> {
    const { query } = this.router.currentRoute
    let mock = null
    if (query.mock) {
      mock = 1
    }
    return this.requestBuilder
      .request('get', '/api/managers/credits')
      .validate(body => {
        return (
          body?.data?.credit_costs &&
          body?.data?.packages &&
          body?.data?.invoicing_info &&
          body?.data?.car_bank_accounts
        )
      })
      .params({ mock })
      .map(body => toCamelCase(body.data))
      .send()
  }

  getAuthenticatorLink() {
    if (this.userAgentService.isIos() || this.userAgentService.isMacOs()) {
      return 'https://apps.apple.com/us/app/google-authenticator/id388497605'
    } else if (this.userAgentService.isFirefox()) {
      return 'https://addons.mozilla.org/el/firefox/addon/auth-helper/'
    } else if (this.userAgentService.isEdge()) {
      return 'https://microsoftedge.microsoft.com/addons/detail/authenticator-2fa-client/ocglkepbibnalbgmbachknglpdipeoio'
    } else if (this.userAgentService.isChrome()) {
      return 'https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai'
    }
    return 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
  }
}
