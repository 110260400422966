















import { RecentButton } from '~/models/user/types'
import { mapGetters } from 'vuex'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { CategoryId } from '~/models/category/types'
import { defineComponent } from '~/utils/nuxt3-migration'

interface Data {
  recentButtons: RecentButton[]
}
export default defineComponent({
  props: {
    gridOnMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(): Data {
    return {
      recentButtons: [
        {
          catId: CategoryId.CARS,
          label: this.$t('cars::vehicles'),
          active: true
        },
        {
          catId: CategoryId.BIKES,
          label: this.$t('bikes::vehicles'),
          active: true
        },
        {
          catId: CategoryId.BOATS,
          label: this.$t('boats::vehicles'),
          active: true
        },
        {
          catId: CategoryId.COMMERCIAL,
          label: this.$t('commercials::vehicles'),
          active: true
        },
        {
          catId: CategoryId.HOBBY,
          label: this.$t('hobby::vehicles'),
          active: true
        },
        {
          catId: CategoryId.CAMPERS,
          label: this.$t('campers::vehicles'),
          active: true
        },
        {
          catId: CategoryId.PARTS,
          label: this.$t('parts::vehicles'),
          active: true
        },
        {
          catId: CategoryId.PLOT,
          label: this.$t('real estate'),
          active: true
        },
        {
          catId: CategoryId.JOBS,
          label: this.$t('jobs'),
          active: true
        },
        {
          catId: CategoryId.XYMA,
          label: this.$t('classifieds::xyma'),
          active: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters(STORAGE_NS, {
      getItem: 'getItem'
    }),
    activeRecents() {
      const r = []
      this.recentButtons.map(btn => {
        if (btn.active) {
          r.push(btn.catId)
        }
      })

      return r
    }
  },
  created() {
    const landing = this.getItem('landing')
    if (landing && landing.recents) {
      this.recentButtons.map(btn => {
        btn.active = landing.recents.includes(btn.catId)
      })
    }
  },
  methods: {
    toggleRecent(e, catId) {
      if (this.activeRecents.length === 1 && this.activeRecents[0] === catId) {
        // there is only one active and we are trying to deactivate it
        return
      }

      this.recentButtons.map(btn => {
        if (btn.catId === catId) {
          if (e.ctrlKey || e.metaKey) {
            btn.active = true
          } else {
            btn.active = !btn.active
          }
        } else if (e.ctrlKey || e.metaKey) {
          btn.active = false
        }
      })

      if (this.activeRecents.length) {
        this.$emit('recents-changed', this.activeRecents)

        this.$store.dispatch('storage/set', {
          landing: {
            recents: this.activeRecents
          }
        })
      }
    }
  }
})
