import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__advertising',
    path: '/advertising',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/advertising/index.vue' /* webpackChunkName: "pages-car-advertising-index.vue" */
        )
      ),
    meta: { showOneTap: true }
  },
  {
    name: '__advertising_dashboard',
    path: '/advertising/dashboard',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/advertising/dashboard/index.vue' /* webpackChunkName: "pages-car-advertising-dashboard-index.vue" */
        )
      ),
    redirect: { name: '__advertising_dashboard_campaigns' },
    children: [
      {
        name: '__advertising_dashboard_campaigns',
        path: '/advertising/dashboard/campaigns',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/dashboard/campaigns.vue' /* webpackChunkName: "pages-car-advertising-dashboard-campaigns.vue" */
            )
          )
      },
      {
        name: '__advertising_dashboard_ads',
        path: '/advertising/dashboard/ads',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/dashboard/ads.vue' /* webpackChunkName: "pages-car-advertising-dashboard-ads.vue" */
            )
          )
      },
      {
        name: '__advertising_dashboard_ad_items',
        path: '/advertising/dashboard/ad-items',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/dashboard/ad-items.vue' /* webpackChunkName: "pages-car-advertising-dashboard-ad-items.vue" */
            )
          )
      }
    ]
  },
  {
    name: '__advertising_wallet',
    path: '/advertising/wallet',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/advertising/wallet.vue' /* webpackChunkName: "pages-car-advertising-wallet.vue" */
        )
      )
  },
  {
    name: '__advertising_campaign',
    path: '/advertising/campaign',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/advertising/campaign/index.vue' /* webpackChunkName: "pages-car-new-campaign-index.vue" */
        )
      ),
    meta: {
      noSideHeader: true
    },
    redirect: { name: '__advertising_campaign_new' },
    children: [
      {
        name: '__advertising_campaign_new',
        path: '/advertising/campaign/new',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/campaign/new.vue' /* webpackChunkName: "pages-car-new-campaign-new.vue" */
            )
          ),
        meta: {
          noSideHeader: true
        }
      },
      {
        name: '__advertising_campaign_edit',
        path: '/advertising/campaign/:campaign/edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/campaign/edit.vue' /* webpackChunkName: "pages-car-new-campaign-edit.vue" */
            )
          ),
        meta: {
          noSideHeader: true
        }
      },
      {
        name: '__advertising_campaign_ad_new',
        path: '/advertising/campaign/:campaign/ads/new',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/campaign/edit.vue' /* webpackChunkName: "pages-car-new-campaign-edit.vue" */
            )
          ),
        meta: {
          noSideHeader: true
        }
      },
      {
        name: '__advertising_campaign_ad_edit',
        path: '/advertising/campaign/:campaign/ads/:ad/edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/campaign/edit.vue' /* webpackChunkName: "pages-car-new-campaign-edit.vue" */
            )
          ),
        meta: {
          noSideHeader: true
        }
      },
      {
        name: '__advertising_campaign_ad_item_new',
        path: '/advertising/campaign/:campaign/ads/:ad/item/new',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/campaign/edit.vue' /* webpackChunkName: "pages-car-new-campaign-edit.vue" */
            )
          ),
        meta: {
          noSideHeader: true
        }
      },
      {
        name: '__advertising_campaign_ad_item_edit',
        path: '/advertising/campaign/:campaign/ads/:ad/item/:item/edit',
        component: () =>
          interopDefault(
            import(
              '~/pages/car/advertising/campaign/edit.vue' /* webpackChunkName: "pages-car-new-campaign-edit.vue" */
            )
          ),
        meta: {
          noSideHeader: true
        }
      }
    ]
  },
  {
    name: '__advertising_terms',
    path: '/advertising/terms',
    component: () =>
      interopDefault(
        import(
          '~/pages/car/advertising/terms.vue' /* webpackChunkName: "pages-car-advertising-terms.vue" */
        )
      )
  }
]
